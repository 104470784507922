import React from "react";
import { Grid, Box, Grow, Paper as MuiPaper, Typography, TableContainer } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import { spacing } from "assets/styles/spacing";
import { colors } from "assets/styles/colors";
import { ImportDataType } from "enums/type.enums";
import GamutChip from "components/chips/GamutChip";

const Paper = muiStyled(MuiPaper)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  margin: 6,
  flex: 1,
});

const Spacer = styled.div`
  height: 10px;
`;

const TypographyValue = muiStyled(Typography)({
  alignSelf: "center",
});

const ErrorsContainer = styled.div`
  color: ${colors.red};
`;

type ColumnId = "account" | "firstname" | "name" | "type" | "allQuestsCompleted" | "adjudication" | "gamut" | "brand" | "category";

interface Column {
  id: ColumnId;
  label: string;
  minWidth?: number;
  align?: "right";
}

interface Props {
  previewResult?: any;
}

const ImportsUploadAnalysisSide: React.FC<Props> = ({ previewResult }) => {
  const intl = useIntl();

  const columnsPerType: Partial<Record<ImportDataType, Column[]>> = {
    [ImportDataType.CONTACT]: [
      {
        id: "account",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.company" }),
        minWidth: 30,
      },
      {
        id: "firstname",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.firstname" }),
        minWidth: 30,
      },
      {
        id: "name",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.lastname" }),
        minWidth: 30,
      },
      {
        id: "type",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.type" }),
        minWidth: 30,
      },
    ],
    [ImportDataType.RESULTS]: [
      {
        id: "account",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.company" }),
        minWidth: 30,
      },
      {
        id: "firstname",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.firstname" }),
        minWidth: 30,
      },
      {
        id: "name",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.lastname" }),
        minWidth: 30,
      },
      {
        id: "allQuestsCompleted",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.result" }),
        minWidth: 30,
      },
      {
        id: "adjudication",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.adjudication" }),
        minWidth: 30,
      },
    ],
    [ImportDataType.STORE_ITEMS]: [
      {
        id: "gamut",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.gamut" }),
        minWidth: 30,
      },
      {
        id: "name",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.productName" }),
        minWidth: 30,
      },
      {
        id: "brand",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.brand" }),
        minWidth: 30,
      },
      {
        id: "category",
        label: intl.formatMessage({ id: "importation.upload.table.column.header.category" }),
        minWidth: 30,
      },
    ],
  };

  return (
    <Grid item container xs={6}>
      <Grid item container xs={6}>
        <Grow in>
          <Paper>
            <Typography variant="body2">
              <FormattedMessage id="importation.upload.analysisSide.title.dataType" />
            </Typography>
            <TypographyValue variant="h6">
              {previewResult?.type && <FormattedMessage id={`form.imports.select.dataType.${previewResult.type}`} />}
            </TypographyValue>
            <Spacer />
          </Paper>
        </Grow>
      </Grid>
      <Grid item container xs={6}>
        <Grow in>
          <Paper>
            <Typography variant="body2">
              <FormattedMessage id="importation.upload.analysisSide.title.lineCount" />
            </Typography>
            <TypographyValue variant="h6">{previewResult?.nbLines}</TypographyValue>
            <Spacer />
          </Paper>
        </Grow>
      </Grid>
      <Grid item container xs={12}>
        <Grow in>
          <Paper>
            <Typography variant="body2" gutterBottom>
              <FormattedMessage id="importation.upload.analysisSide.title.preview" />
            </Typography>
            <Box display="flex" flex={1} maxHeight={440}>
              {previewResult?.errors?.length > 0 && (
                <ErrorsContainer>
                  <Typography>
                    <FormattedMessage id="importation.preview.errors" />
                  </Typography>
                  {previewResult.errors.slice(0, 10).map((error: string) => (
                    <Typography key={error}>{`- ${error}`}</Typography>
                  ))}
                  {previewResult.errors.length > 10 && <Typography>...</Typography>}
                </ErrorsContainer>
              )}
              {previewResult?.preview?.length > 0 && (
                <>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {(columnsPerType[previewResult.type as ImportDataType] as Column[]).map((column) => (
                            <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {previewResult.preview.map((row: Partial<Record<ColumnId, any>>) => (
                          <TableRow tabIndex={-1} key={`${row.account}${row.firstname}${row.name}`}>
                            {(columnsPerType[previewResult.type as ImportDataType] as Column[]).map((column) => (
                              <TableCell key={column.id} align={column.align}>
                                {(() => {
                                  if (column.id === "gamut") {
                                    return <GamutChip gamut={row.gamut} />;
                                  }
                                  if (column.id === "allQuestsCompleted") {
                                    return (
                                      <FormattedMessage
                                        id={`importation.upload.table.row.result.${row.allQuestsCompleted ? "won" : "lost"}`}
                                      />
                                    );
                                  }
                                  if (column.id === "adjudication") {
                                    return (
                                      <FormattedMessage id={`importation.upload.table.row.result.${row.adjudication ? "won" : "lost"}`} />
                                    );
                                  }
                                  return row[column.id];
                                })()}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Box>
          </Paper>
        </Grow>
      </Grid>
    </Grid>
  );
};

export default ImportsUploadAnalysisSide;
