import { ResultsFilterFormType, ResultsFilterResponseType } from "types/form.types";
import api from "api";

import { ACTION_TYPES } from "./results.types";

export const getChallengeResults = (id: string, form: ResultsFilterFormType | {}) => async (dispatch: any) => {
  const { data: payload }: { data: ResultsFilterResponseType } = await api.results.filter(id, form);

  dispatch({
    type: ACTION_TYPES.GET_RESULTS,
    payload,
  });
};

export const updateContactRegistration = (id: string, registered: boolean) => async (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_REGISTRATION,
    payload: {
      id,
      registered,
    },
  });
};

export const updateContactAdjudication = (id: string, adjudication: boolean) => async (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_ADJUDICATION,
    payload: {
      id,
      adjudication,
    },
  });
};

export const deleteResultsAccount = (challengeId: string, accountId: string, contactId: string) => async (dispatch: any) => {
  await api.challenge.deleteAccountContact(challengeId, accountId, contactId);
  const payload = contactId;

  dispatch({
    type: ACTION_TYPES.DELETE_RESULT_ACCOUNT,
    payload,
  });
};

export const updateContactType = (id: string, challengeId: string, type: string) => async (dispatch: any) => {
  await api.results.updateType(challengeId, id, type);

  dispatch({
    type: ACTION_TYPES.UPDATE_ADJUDICATION,
    payload: {
      id,
      type,
    },
  });
};

export const updateContactComment = (id: string, comment: string) => async (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_COMMENT,
    payload: {
      id,
      comment,
    },
  });
};

export const deleteContactAccount = (id: string, account: string) => async (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.DELETE_CONTACT_ACCOUNT,
    payload: {
      id,
      account,
    },
  });
};

export const deleteContact = (id: string) => async (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.DELETE_CONTACT,
    payload: {
      id,
    },
  });
};
