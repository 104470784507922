import { DashboardValues } from "enums/select.enums";

const dashboard = {
  [`dashboard.${DashboardValues.WINNERS}.title`]: "Gagnants - {challengeName}",
  [`dashboard.${DashboardValues.REGISTERED}.title`]: "Inscrits - {challengeName}",
  [`dashboard.${DashboardValues.WINNERS}.total.pieChart.caption`]: "% total de gagnants",
  [`dashboard.${DashboardValues.REGISTERED}.total.pieChart.caption`]: "% total d'inscrits",
  [`dashboard.select.value.${DashboardValues.WINNERS}`]: "Gagnants",
  [`dashboard.select.value.${DashboardValues.REGISTERED}`]: "Inscrits",
};

export default dashboard;
