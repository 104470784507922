export enum ServerErrors {
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  DEFAULT_ERROR = "DEFAULT_ERROR",
  QUEST_NAME_ALREADY_EXISTS = "QUEST_NAME_ALREADY_EXISTS",
  CHALLENGE_DATE_ALREADY_EXISTS = "CHALLENGE_DATE_ALREADY_EXISTS",
  OLD_PASSWORD = "OLD_PASSWORD",
  SAME_PASSWORD = "SAME_PASSWORD",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  PASSWORDS_NOT_MATCH_REGEX = "PASSWORDS_NOT_MATCH_REGEX",
  MUST_CHANGE_PASSWORD = "MUST_CHANGE_PASSWORD",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
}

export enum ServerSuccesses {
  DEFAULT_SUCCESS = "DEFAULT_SUCCESS",
  PUBLISH_RESULTS = "PUBLISH_RESULTS",
  OPEN_STORE = "OPEN_STORE",
  CREATE_SUCCESS = "CREATE_SUCCESS",
  EDIT_SUCCESS = "EDIT_SUCCESS",
  DELETE_SUCCESS = "DELETE_SUCCESS",
  UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS",
  EMAIL_SUCCESS = "EMAIL_SUCCESS",
  ADD_CONTACT = "ADD_CONTACT",
  DELETE_CONTACT = "DELETE_CONTACT",
}
