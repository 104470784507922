import React, { useEffect, useState } from "react";
import { Box, Container, FormControl, Grid, Grow, Switch, Typography } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import actions from "store/actions";
import { RootState } from "store/types";
import AbsoluteLoader from "components/loader/AbsoluteLoader";
import SelectYear from "components/selects/SelectYear";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import { ServerSuccesses } from "enums/server.enums";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import { AuthMode } from "enums/api.enums";
import DialogNotifyStoreOpen from "components/dialogs/DialogNotifyStoreOpen";
import api from "api";

import { selectYear } from "store/challenge/challenge.actions";
import StoreManagementTable from "./StoreManagementTable";

const ContainerPage = muiStyled(Container)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingBottom: 32, // to correct grid negative margin c.f. mui-doc limitations
});

const Title = muiStyled(Typography)({
  color: "white",
});

const ContainerNoChallenge = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.2rem;
`;

const ContainerBody = muiStyled(Box)({
  flex: 1,
  display: "flex",
});

const FormControlSwitch = muiStyled(FormControl)({
  marginRight: 18,
});

const Dashboard: React.FC = () => {
  const [isLoadingChallenge, setIsLoadingChallenge] = useState<boolean>();
  const [isOpenModalNotify, setIsOpenModalNotify] = useState<boolean>(false);
  const year = useSelector((state: RootState) => state.challenge.selectedYear);
  const dispatch = useDispatch();
  const isAuthModeCLUB = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE) === AuthMode.CLUB;

  const { challenge, years } = useSelector((state: RootState) => state.challenge);
  const { storeOpen = false, id } = challenge || {};

  const openStore = (event: any) => dispatch(actions.challenge.openStore(id, event.target.checked));
  const getChallenge = (y: number) => dispatch(actions.challenge.getChallenge(y));
  const [isLoadingNotifyOpenStore, notifyOpenStoreWithSnackbar] = useSnackbarPromiseHandler(
    api.storeManagement.notifyOpen,
    `server.success.${ServerSuccesses.DEFAULT_SUCCESS}`
  );
  const [, openStoreWithSnackbar] = useSnackbarPromiseHandler(openStore, `server.success.${ServerSuccesses.OPEN_STORE}`);

  const closeModalNotify = () => setIsOpenModalNotify(false);

  const handleNotifyOpenStore = async () => {
    await notifyOpenStoreWithSnackbar(id);

    closeModalNotify();
  };

  const handleOpenStore = async (event: any) => {
    const isStoreOpenBefore = storeOpen;

    await openStoreWithSnackbar(event);

    // ask for notification
    if (!isStoreOpenBefore) {
      setIsOpenModalNotify(true);
    }
  };

  useEffect(() => {
    (async function auth() {
      try {
        setIsLoadingChallenge(true);
        await getChallenge(year);
      } finally {
        setIsLoadingChallenge(false);
      }
    })();
  }, [year]);

  return isLoadingChallenge ? (
    <AbsoluteLoader />
  ) : (
    <ContainerPage>
      <Grow in>
        <Grid container xs={12}>
          <Grid item container xs={12} justify="space-between" alignItems="center">
            <Title variant="h3">
              <FormattedMessage id="storeManagement.table.page.title" />
            </Title>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <FormControlSwitch variant="outlined">
                <Box display="flex" alignItems="center" direction="column">
                  <Switch
                    color="primary"
                    checked={storeOpen}
                    onClick={(event: React.MouseEvent<any>) => event.stopPropagation()}
                    disabled={isAuthModeCLUB}
                    onChange={handleOpenStore}
                  />
                  <Title>
                    <FormattedMessage id={`storeManagement.switch.${storeOpen}`} />
                  </Title>
                </Box>
              </FormControlSwitch>
              <SelectYear years={years} year={year} setYear={(y) => dispatch(selectYear(y))} paddingRight={6} />
            </Box>
          </Grid>
        </Grid>
      </Grow>
      <Grow in>
        {challenge?.id ? (
          <ContainerBody>
            <StoreManagementTable challenge={challenge} />
          </ContainerBody>
        ) : (
          <ContainerNoChallenge>
            <FormattedMessage id="screen.challenge.no.challenge" values={{ year }} />
          </ContainerNoChallenge>
        )}
      </Grow>
      <DialogNotifyStoreOpen
        isLoading={isLoadingNotifyOpenStore}
        isOpen={isOpenModalNotify}
        onConfirm={handleNotifyOpenStore}
        onCancel={closeModalNotify}
      />
    </ContainerPage>
  );
};

export default Dashboard;
