import { ServerErrors, ServerSuccesses } from "enums/server.enums";

const server = {
  [`server.error.${ServerErrors.DEFAULT_ERROR}`]: "Une erreur est survenue.",
  [`server.error.${ServerErrors.UNKNOWN_ERROR}`]: "Une erreur est survenue.",
  [`server.error.${ServerErrors.QUEST_NAME_ALREADY_EXISTS}`]: "Ce titre est déjà utilisé.",
  [`server.error.${ServerErrors.OLD_PASSWORD}`]: "Ce mot de passe a déjà été utilisé auparavant.",
  [`server.error.${ServerErrors.SAME_PASSWORD}`]: "Ce mot de passe a déjà été utilisé auparavant.",
  [`server.error.${ServerErrors.PASSWORDS_NOT_MATCH_REGEX}`]: "Le mot de passe ne respecte pas la règle de sécurité.",
  [`server.error.${ServerErrors.MUST_CHANGE_PASSWORD}`]: "Le mot de passe doit être changé.",
  [`server.error.${ServerErrors.INVALID_CREDENTIALS}`]: "Email ou mot de passe incorrect.",
  [`server.error.${ServerErrors.NOT_AUTHORIZED}`]: "Vous n'êtes pas autorisé à réaliser cette action.",
  [`server.error.${ServerErrors.CHALLENGE_DATE_ALREADY_EXISTS}`]: "Un challenge existe déjà à cette date",
  [`server.success.${ServerSuccesses.DEFAULT_SUCCESS}`]: "Action réussie.",
  [`server.success.${ServerSuccesses.CREATE_SUCCESS}`]: "Création réussie.",
  [`server.success.${ServerSuccesses.EDIT_SUCCESS}`]: "Modification réussie.",
  [`server.success.${ServerSuccesses.OPEN_STORE}`]: "Modification réussie.",
  [`server.success.${ServerSuccesses.PUBLISH_RESULTS}`]: "Résultats publiés.",
  [`server.success.${ServerSuccesses.DELETE_SUCCESS}`]: "Suppression réussie.",
  [`server.success.${ServerSuccesses.UPLOAD_FILE_SUCCESS}`]: "Fichier envoyé avec succès.",
  [`server.success.${ServerSuccesses.EMAIL_SUCCESS}`]: "Email de réinitialisation de mot de passe envoyé avec succès.",
  [`server.success.${ServerSuccesses.ADD_CONTACT}`]: "Participations ajoutées",
  [`server.success.${ServerSuccesses.DELETE_CONTACT}`]: "Participation supprimée",
};

export default server;
