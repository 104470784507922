const storeManagement = {
  "storeManagement.table.page.title": "Gestion du magasin",
  "storeManagement.table.count.products": "{count} produit{count, plural, one { trouvé} other {s trouvés}}",
  "storeManagement.table.filter.category": "Catégorie",
  "storeManagement.table.filter.gamut": "Gamme",
  "storeManagement.table.filter.price": "Prix",
  "storeManagement.table.column.productId": "ID du produit",
  "storeManagement.table.column.title": "Titre",
  "storeManagement.table.column.category": "Catégorie",
  "storeManagement.table.column.gamut": "Gamme",
  "storeManagement.table.column.price": "Prix",
  "storeManagement.table.column.datePublished": "Date de publication",
  "storeManagement.table.column.brand": "Marque",
  "storeManagement.switch.true": "Ouvert",
  "storeManagement.switch.false": "Fermé",
  "storeManagement.product.page.title": "Gestion du magasin",
  "storeManagement.product.button.delete.label": "Supprimer",
  "storeManagement.product.form.field.title": "Titre",
  "storeManagement.product.form.field.brand": "Marque",
  "storeManagement.product.form.field.datePublished": "Date de publication",
  "storeManagement.product.form.field.category": "Catégorie",
  "storeManagement.product.form.field.gamut": "Gamme",
  "storeManagement.product.form.field.reference": "Référence(s) produit(s)",
  "storeManagement.product.form.field.userChoices": "Choix utilisateur",
  "storeManagement.product.form.field.description": "Description",
  "storeManagement.product.form.field.price": "Prix",
  "storeManagement.product.form.field.datePublished.error.invalid": "Format de date invalide",
  "storeManagement.product.form.button.save": "Sauvegarder",
  "storeManagement.product.form.button.delete": "Supprimer",
  "storeManagement.open.dialog.notify.title": "Magasin ouvert",
  "storeManagement.open.dialog.notify.body": "Souhaitez-vous avertir les participants de l’ouverture ?",
};

export default storeManagement;
