import { ImportDataType } from "enums/type.enums";
import { SelectValues } from "enums/select.enums";

const form = {
  "form.search": "Rechercher",
  "form.button.filter": "Filtrer...",
  "form.button.exports": "Exporter...",
  "form.button.exportResults": "Exporter les résultats",
  "form.button.exportOrders": "Exporter les commandes",
  "form.results.search.placeholder": "Compte, RRF, Nom, Prénom",
  "form.accounts.search.placeholder": "Chercher un groupe national",
  "form.results.filter.title": "Filtrer les défis",
  "form.results.filter.reached.yes": "Atteint",
  "form.results.filter.reached.no": "Non atteint",
  "form.challenge.title": "Challenge {year}",
  "form.challenge.date.error.anterior": "La date ne peut pas être antérieure au 1er janvier courant",
  "form.challenge.date.error.anterior.to.start": "Cette date ne peut pas être antérieure à la date de début",
  "form.challenge.date.error.posterior": "La date ne peut pas être postérieure au 31 décembre courant",
  "form.challenge.date.error.invalid": "Le format de la date est invalide",
  "form.challenge.points.error.positive": "Le nombre de points ne peut pas être négatif",
  "form.challenge.points": "Points de récompense",
  "form.challenge.tabs.participants": "Participants",
  "form.challenge.tabs.accounts": "Concessions",
  "form.quest.title.create": "Créer un défi",
  "form.quest.title.edit": "Modifier un défi",
  "form.quest.delete": "Supprimer le défi ?",
  "form.quest.description.label": "Description",
  "form.quest.account.checkbox": "Lié à la concession ?",
  "form.quest.reverse.checkbox": "Objectifs inversés ? (classement par exemple)",
  "form.quest.aggregation": "Méthode d'aggrégation",
  "form.quest.name.label": "Titre",
  "form.error.required": "Ce champ est obligatoire !",
  "form.cancel": "Annuler",
  "form.submit": "Confirmer",
  "form.error.field.mandatory": "Le champ est obligatoire !",
  "form.error.field.min": "La valeur est trop petite !",
  "form.error.field.mustMatch": "Les champs ne sont pas identiques !",
  "form.error.password.regex": "Le mot de passe ne respecte pas la règle de sécurité !",
  "form.error.email.regex": "Le format de l'email est invalide !",
  "form.imports.select.dataType": "Type de données à importer",
  [`form.imports.select.dataType.${ImportDataType.CONTACT}`]: "Utilisateurs",
  [`form.imports.select.dataType.${ImportDataType.RESULTS}`]: "Résultats",
  [`form.imports.select.dataType.${ImportDataType.STORE_ITEMS}`]: "Catalogue",
  [`form.imports.select.dataType.${ImportDataType.MEDIA}`]: "Média",
  [`form.select.global.${SelectValues.ALL}`]: "Tous",
  [`form.select.global.${SelectValues.NONE}`]: "Aucun",
};

export default form;
