const challenge = {
  "screen.challenge.quest.title": "Défis club des 100",
  "screen.challenge.quest.button.add": "Ajouter un défi",
  "screen.challenge.rules.title": "Règlement",
  "screen.challenge.rules.button.add": "Transférer un nouveau règlement",
  "screen.challenge.rules.link.none": "Aucun règlement sauvegardé",
  "screen.challenge.rules.link.download": "Télécharger le dernier règlement sauvegardé",
  "screen.challenge.no.challenge": "Aucun challenge n'a encore été créé pour l'année {year}",
  "screen.challenge.button.create": "Créer le challenge",
  "screen.challenge.results.published": "Résultats finaux publiés",
  "screen.challenge.results.switch.true": "Oui",
  "screen.challenge.results.switch.false": "Non",
};

export default challenge;
