import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container as MuiContainer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper as MuiPaper,
  Select,
  Table as MuiTable,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { styled as muiStyled } from "@material-ui/core/styles";
import MuiTableContainer from "@material-ui/core/TableContainer";
import Grow from "@material-ui/core/Grow";
import { Link } from "react-router-dom";
import moment from "moment";
import TablePagination from "@material-ui/core/TablePagination";

import { spacing } from "assets/styles/spacing";
import { SelectValues } from "enums/select.enums";
import { ImportDataType } from "enums/type.enums";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import api from "api";
import { HistoryEntryType } from "types/form.types";
import { GETImportsHistory } from "types/api.types";
import AbsoluteLoader from "components/loader/AbsoluteLoader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import actions from "store/actions";

const Container = muiStyled(MuiContainer)({
  flex: 1,
  display: "flex",
});

const TableContainer = muiStyled(MuiTableContainer)({
  position: "relative",
  flex: 1,
});

const Table = muiStyled(MuiTable)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: "auto",
});

const FormControlDataType = muiStyled(FormControl)({
  margin: "16px 12px 16px",
});

const SelectDataType = styled(Select)`
  min-width: 200px;
`;

const Paper = muiStyled(MuiPaper)({
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  margin: 20,
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${spacing(2)}px;
`;

interface Column {
  id: "importDate" | "type" | "nbLines" | "filename";
  label: string;
  minWidth?: number;
  align?: "right";
}

const ImportsHistory: React.FunctionComponent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [dataType, setDataType] = useState<ImportDataType | SelectValues.ALL>(SelectValues.ALL);
  const year = useSelector((state: RootState) => state.challenge.selectedYear);
  const [isLoadingHistory, fetchHistoryWithSnackbar] = useSnackbarPromiseHandler(api.history.imports);
  const [historyEntries, setHistoryEntries] = useState<HistoryEntryType[]>([]);

  const getChallenge = (y: number) => dispatch(actions.challenge.getChallenge(y));

  const columns: Column[] = [
    { id: "importDate", label: intl.formatMessage({ id: "importation.table.column.header.date" }), minWidth: 100 },
    { id: "type", label: intl.formatMessage({ id: "importation.table.column.header.type" }), minWidth: 100 },
    { id: "nbLines", label: intl.formatMessage({ id: "importation.table.column.header.lineCount" }), minWidth: 100 },
    { id: "filename", label: intl.formatMessage({ id: "importation.table.column.header.filename" }), minWidth: 100 },
  ];

  const { challenge } = useSelector((state: RootState) => state.challenge);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleChangeDataType = (event: React.ChangeEvent<{ value: ImportDataType | SelectValues.ALL }>) => {
    setDataType(event.target.value);
  };

  useEffect(() => {
    (async function fetchChallenge() {
      await getChallenge(year);
    })();
  }, []);

  useEffect(() => {
    (async function fetchHistory() {
      const { id } = challenge || {};
      if (id) {
        const response: GETImportsHistory = await fetchHistoryWithSnackbar(
          id,
          page,
          rowsPerPage,
          dataType !== SelectValues.ALL ? dataType : undefined
        );
        setHistoryEntries(response.data.results);
      }
    })();
  }, [challenge, dataType, page, rowsPerPage]);

  return (
    <Container>
      {isLoadingHistory && <AbsoluteLoader />}
      <Grid item container xs={12}>
        <Grow in timeout={520}>
          <Paper elevation={2}>
            <Title>
              <Typography variant="h4">
                <FormattedMessage id="importation.table.title" />
              </Typography>
              <Box display="flex" alignItems="center">
                <FormControlDataType variant="outlined">
                  <InputLabel id="select-data-type-label">
                    <FormattedMessage id="form.imports.select.dataType" />
                  </InputLabel>
                  <SelectDataType
                    labelId="select-data-type-label"
                    value={dataType}
                    onChange={handleChangeDataType}
                    label={<FormattedMessage id="form.imports.select.dataType" />}
                  >
                    <MenuItem value={SelectValues.ALL} key={SelectValues.ALL}>
                      <FormattedMessage id={`form.select.global.${SelectValues.ALL}`} />
                    </MenuItem>
                    {Object.values(ImportDataType).map((importDataType: ImportDataType) => (
                      <MenuItem value={importDataType} key={importDataType}>
                        <FormattedMessage id={`form.imports.select.dataType.${importDataType}`} />
                      </MenuItem>
                    ))}
                  </SelectDataType>
                </FormControlDataType>
                <Link to={intl.formatMessage({ id: "routes.imports.upload" })}>
                  <Button variant="contained" color="primary" target="">
                    <FormattedMessage id="common.button.import.file" />
                  </Button>
                </Link>
              </Box>
            </Title>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyEntries.map((entry) => (
                    <TableRow key={entry.importDate}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          {(() => {
                            switch (column.id) {
                              case "importDate":
                                return moment(entry.importDate).format("DD/MM/yyyy HH:mm");
                              case "type":
                                return <FormattedMessage id={`form.imports.select.dataType.${entry.type}`} />;
                              default:
                                return entry[column.id];
                            }
                          })()}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={historyEntries.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={intl.formatMessage({ id: "common.table.rowsPerPage" })}
              labelDisplayedRows={({ from, to, count }) =>
                intl.formatMessage(
                  { id: "common.table.DisplayedRows" },
                  {
                    from,
                    to,
                    count,
                  }
                )
              }
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grow>
      </Grid>
    </Container>
  );
};

export default ImportsHistory;
