const routes = {
  "routes.account": "/compte",
  "routes.imports.history": "/historique-des-importations",
  "routes.imports.upload": "/imports/transfert",
  "routes.store.management": "/gestion-du-magasin",
  "routes.authentication": "/authentification",
  "routes.challenge": "/challenge",
  "routes.results": "/resultats",
  "routes.reset.password": "/changer-mon-mot-de-passe",
  "routes.login": "/login",
};

export default routes;
