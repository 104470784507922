import React from "react";
import styled from "styled-components";
import SLoader from "react-loader-spinner";

import { colors } from "assets/styles/colors";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

interface Props {
  width?: number;
  height?: number;
  color?: string;
  type?:
    | "Audio"
    | "BallTriangle"
    | "Bars"
    | "Circles"
    | "Grid"
    | "Hearts"
    | "Oval"
    | "Puff"
    | "Rings"
    | "TailSpin"
    | "ThreeDots"
    | "Watch"
    | "RevolvingDot"
    | "Triangle"
    | "Plane"
    | "MutatingDots"
    | "CradleLoader";
}

const Loader: React.FunctionComponent<Props> = ({ width = 100, height = 100, color = colors.lightGrey, type = "Rings" }) => (
  <Container>
    <SLoader type={type} color={color} height={height} width={width} />
  </Container>
);

export default Loader;
