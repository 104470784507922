import React, { useEffect, useState } from "react";
import { styled as muiStyled } from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select as MuiSelect,
  Slider,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer as MuiTableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment, { Moment } from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import styled from "styled-components";

import { spacing } from "assets/styles/spacing";
import { Challenge, StoreItemType } from "types/common.types";
import { SelectValues } from "enums/select.enums";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import api from "api";
import { GETStoreItems } from "types/api.types";
import AbsoluteLoader from "components/loader/AbsoluteLoader";
import { colors } from "assets/styles/colors";
import Rcoin from "components/rcoin";
import { completeWithSpaces } from "../../utils/dev.utils";

const PaperWide = muiStyled(Paper)({
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  margin: 6,
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const Select = muiStyled(MuiSelect)({
  minWidth: 160,
});

const TableContainer = muiStyled(MuiTableContainer)({
  position: "relative",
  flex: 1,
});

const Table = muiStyled(MuiTable)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: "auto",
});

const TableRowSelectable = muiStyled(TableRow)({
  cursor: "pointer",
});

const TypographyBold = muiStyled(Typography)({
  fontWeight: 600,
});

const DateOverline = muiStyled(Typography)({
  color: colors.lightGrey,
});

const SliderContainer = muiStyled(FormControl)({
  minWidth: 100,
  border: "none",
});

const Row = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

interface Column {
  id: "id" | "name" | "category" | "price" | "shippingDate" | "brand";
  label: string | React.ReactElement;
}

const columns: Column[] = [
  {
    id: "id",
    label: <FormattedMessage id="storeManagement.table.column.productId" />,
  },
  {
    id: "name",
    label: <FormattedMessage id="storeManagement.table.column.title" />,
  },
  {
    id: "category",
    label: <FormattedMessage id="storeManagement.table.column.category" />,
  },
  {
    id: "price",
    label: <FormattedMessage id="storeManagement.table.column.price" />,
  },
  {
    id: "shippingDate",
    label: <FormattedMessage id="storeManagement.table.column.datePublished" />,
  },
  {
    id: "brand",
    label: <FormattedMessage id="storeManagement.table.column.brand" />,
  },
];

interface Props {
  challenge: Challenge;
}

const StoreManagementTable: React.FC<Props> = ({ challenge }) => {
  const [categoryFilter, setCategoryFilter] = useState<string>(SelectValues.ALL);
  const [isLoadingGetItems, getItemsWithSnackbar] = useSnackbarPromiseHandler(api.storeManagement.getItems);
  const [storeItems, setStoreItems] = useState<StoreItemType[]>([]);
  const [lastUpdateDate, setLastUpdateDate] = useState<Moment>();
  const history = useHistory();
  const intl = useIntl();

  const maxPrice = storeItems.reduce((prev, cur) => (cur.price > prev ? cur.price : prev), 0);
  const [price, setPrice] = useState<Array<number>>([0, 1000]);

  useEffect(() => {
    if (challenge) {
      (async function getStoreItems() {
        const { data }: GETStoreItems = await getItemsWithSnackbar(challenge.id);

        setStoreItems(data.results);
        setLastUpdateDate(moment(data.lastModificationDate));

        const max = data.results.reduce((prev, cur) => (cur.price > prev ? cur.price : prev), 0);
        setPrice([0, max]);
      })();
    }
  }, [challenge]);

  const handleChangeCategory = (event: React.ChangeEvent<{ value: string }>) => setCategoryFilter(event.target.value);

  const handleChangePrice = (event: Event & { currentTarget: HTMLInputElement }, newRange: Array<number> | null) => {
    setPrice(newRange);
  };

  const filteredItems = _.sortBy(storeItems, (item) => item.name)
    .filter((item) => categoryFilter === SelectValues.ALL || item.category.name === categoryFilter)
    .filter((item) => item.price >= price[0] && item.price <= price[1]);

  return (
    <PaperWide>
      {isLoadingGetItems && <AbsoluteLoader />}
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} mb={2}>
        <Box display="flex" flexDirection="column">
          <Typography variant="h4" gutterBottom>
            <FormattedMessage id="storeManagement.table.count.products" values={{ count: filteredItems.length }} />
          </Typography>
          <DateOverline variant="body2">
            <FormattedMessage id="common.date.lastUpdate" values={{ date: lastUpdateDate?.format("DD/MM/yyyy") }} />
          </DateOverline>
        </Box>
        <Box display="flex" alignItems="center">
          <SliderContainer component="fieldset">
            <FormLabel component="legend">
              <FormattedMessage id="storeManagement.table.filter.price" />
            </FormLabel>
            <Slider
              value={price}
              defaultValue={[0, maxPrice]}
              valueLabelDisplay="auto"
              step={250}
              max={maxPrice}
              onChange={handleChangePrice}
            />
          </SliderContainer>
          <Box ml={2} />
          <FormControl variant="outlined">
            <InputLabel id="label-select-category">
              <FormattedMessage id="storeManagement.table.filter.category" />
            </InputLabel>
            <Select
              defaultValue={SelectValues.ALL}
              variant="outlined"
              label={<FormattedMessage id="storeManagement.table.filter.category" />}
              labelId="label-select-category"
              onChange={handleChangeCategory}
            >
              <MenuItem value={SelectValues.ALL} key={SelectValues.ALL}>
                <TypographyBold>
                  <FormattedMessage id={`form.select.global.${SelectValues.ALL}`} />
                </TypographyBold>
              </MenuItem>
              {_.sortBy(
                _.uniqBy(storeItems, (item) => item.category.name),
                (item) => item.category.name
              ).map((item) => (
                <MenuItem value={item.category.name} key={item.category.name}>
                  {item.category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column: Column) => (
                <TableCell align="left" style={{ width: `calc(100% / ${columns.length}` }} key={column.id}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map((item: StoreItemType) => (
              <TableRowSelectable
                key={item.id}
                hover
                onClick={() => {
                  history.push({
                    pathname: `${intl.formatMessage({ id: "routes.store.management" })}/${item.name}`,
                    state: { product: item, storeItems },
                  });
                }}
              >
                {columns.map((column: Column) => (
                  <TableCell key={column.id}>
                    {(() => {
                      switch (column.id) {
                        case "price": {
                          return (
                            <Row>
                              {completeWithSpaces(item.price, maxPrice)} <Rcoin size={20} />
                            </Row>
                          );
                        }

                        case "shippingDate": {
                          return moment(item.shippingDate).format("DD/MM/yyyy");
                        }

                        case "category": {
                          return item.category.name;
                        }

                        default:
                          return item[column.id];
                      }
                    })()}
                  </TableCell>
                ))}
              </TableRowSelectable>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PaperWide>
  );
};

export default StoreManagementTable;
