import React, { useEffect, useState } from "react";
import { Grid, Grow, Paper } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import styled from "styled-components";

import { spacing } from "assets/styles/spacing";
import { ResultsFilterRowResponseType } from "types/form.types";

import ResultsPanelHead from "./ResultsPanelHead";
import ResultsPanelBody from "./ResultsPanelBody";

const GridContainer = styled(Grid)`
  height: 100%;
`;

const PaperWide = muiStyled(Paper)({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  boxSizing: "border-box",
});

interface Props {
  row: ResultsFilterRowResponseType;
  onClose: () => void;
  onCloseAnimationExited: () => void;
  submitForm: () => void;
}

const ResultsPanel: React.FC<Props> = ({ row, onClose, onCloseAnimationExited, submitForm }) => {
  const [canGrow, setCanGrow] = useState<boolean>();
  const [lastRow, setLastRow] = useState<ResultsFilterRowResponseType>(row); // to keep value defined while animating out

  useEffect(() => {
    if (row) {
      setLastRow(row);
      setTimeout(() => setCanGrow(true), 1000); // to give sibling table time to shrink (animation)
    } else {
      setCanGrow(false);
    }
  }, [row]);

  return (
    <GridContainer
      item
      container
      xs={4}
      style={{ height: row ? "100%" : 0, paddingTop: row ? spacing(4) : 0, paddingBottom: row ? spacing(4) : 0 }}
    >
      <Grow in={canGrow} onExited={() => onCloseAnimationExited()}>
        <PaperWide>
          {lastRow && (
            <>
              <ResultsPanelHead row={lastRow} onClose={onClose} />
              <ResultsPanelBody row={lastRow} onClose={onClose} submitForm={submitForm} />
            </>
          )}
        </PaperWide>
      </Grow>
    </GridContainer>
  );
};

export default ResultsPanel;
