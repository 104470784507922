import React from "react";
import { Chip } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import styled from "styled-components";

import { spacing } from "assets/styles/spacing";

const UnorderedList = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding: ${spacing(2)};
  margin: 0;
`;

const ChipSpaced = muiStyled(Chip)({
  margin: spacing(1),
});

interface Props {
  items: string[];
  handleDeleteIndex: (index: number) => void;
}

const ChipsArray: React.FC<Props> = ({ items, handleDeleteIndex }) => (
  <UnorderedList>
    {items.map((item, index: number) => (
      <li key={item}>
        <ChipSpaced label={item} onDelete={() => handleDeleteIndex(index)} />
      </li>
    ))}
  </UnorderedList>
);

export default ChipsArray;
