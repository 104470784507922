import fr from "i18n/messages";
import { I18nActionTypes, I18nState, ACTION_TYPES } from "./i18n.types";

type Local = {
  locale: string;
  messages: { [key: string]: string };
};

const locales: { [key: string]: Local } = {
  fr,
};

const initialState: I18nState = fr;

const i18nReducer = (state: I18nState = initialState, action: I18nActionTypes): I18nState => {
  switch (action.type) {
    case ACTION_TYPES.CHANGE_LOCALE:
      return locales[action.payload];
    default:
      return state;
  }
};

export default i18nReducer;
