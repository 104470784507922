import React, { useEffect } from "react";
import queryString from "query-string";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@material-ui/core";

import * as ENV from "config/env";
import actions from "store/actions";
import styled from "styled-components/macro";
import { generateLoginUrl, getAccessToken, isInIframe } from "utils/authentication.utils";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import { AuthMode } from "enums/api.enums";
import { ServerErrors } from "enums/server.enums";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
  font-size: calc(10px + 2vmin);
`;

const USBAuthenticator: React.FunctionComponent = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const showError = () => {
    enqueueSnackbar(intl.formatMessage({ id: `server.error.${ServerErrors.DEFAULT_ERROR}` }), { variant: "error" });
  };

  useEffect(() => {
    (async function login() {
      const { code, state, error } = queryString.parse(search);
      const iFrameOpened = isInIframe();

      if (error) {
        if (iFrameOpened) {
          window.parent.postMessage(error, window.origin);
        }
        showError();
        return;
      }

      if (code) {
        if (localStorage.getItem(LOCAL_STORAGE.PKCE) !== state) {
          if (!iFrameOpened) {
            localStorage.removeItem(LOCAL_STORAGE.API_TOKEN);
            localStorage.removeItem(LOCAL_STORAGE.AUTH_MODE);
            window.history.replaceState({}, "", "");
          }

          showError();
        } else if (!Array.isArray(code)) {
          getAccessToken(
            code,
            (request, { access_token }) => {
              if (iFrameOpened) {
                window.parent.postMessage({ access_token }, window.origin);
              } else {
                localStorage.setItem(LOCAL_STORAGE.API_TOKEN, `Bearer ${access_token}`);
                localStorage.setItem(LOCAL_STORAGE.AUTH_MODE, AuthMode.IDP);
                window.history.replaceState({}, "", "");

                dispatch(actions.auth.authenticateUser(`Bearer ${access_token}`, AuthMode.IDP));
                history.push("/");
              }
            },
            () => {
              showError();
            }
          );
          localStorage.removeItem(LOCAL_STORAGE.PKCE);
          localStorage.removeItem(LOCAL_STORAGE.PKCE_VERIFIER);
        }
      } else if (ENV.ENV === "DEV") {
        localStorage.setItem(LOCAL_STORAGE.API_TOKEN, ENV.DEV_TOKEN);
        localStorage.setItem(LOCAL_STORAGE.AUTH_MODE, AuthMode.IDP);
        dispatch(actions.auth.authenticateUser(ENV.DEV_TOKEN, AuthMode.IDP));
      } else {
        window.location.href = await generateLoginUrl(false);
      }
    })();
  }, []);

  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};

export default USBAuthenticator;
