export enum ContactType {
  CG = "CG",
  CVVO = "CVVO",
  CVVOMULTI = "CVVOMULTI",
}

export enum ImportDataType {
  CONTACT = "CONTACT",
  RESULTS = "RESULTS",
  STORE_ITEMS = "STORE_ITEMS",
  MEDIA = "MEDIA",
}

export enum StoreItemGamut {
  BRONZE = "BRONZE",
  SILVER = "SILVER",
  GOLD = "GOLD",
}

export enum QuestGoalType {
  PROGRESSIVE = "PROGRESSIVE",
  UNITARY = "UNITARY",
}

export enum QuestAggregationTypeUnitary {
  AND = "AND",
  OR = "OR",
}

export enum QuestAggregationTypeProgressive {
  MIN = "MIN",
  MAX = "MAX",
  MEAN = "MEAN",
  WEIGHTED_MEAN = "WEIGHTED_MEAN",
  SUM = "SUM",
}

export enum ChallengeTab {
  CONTACTS = "CONTACTS",
  ACCOUNTS = "ACCOUNTS",
}

export enum ChallengeModalTab {
  CONTACT_MODAL = "CONTACT_MODAL",
  ACCOUNT_MODAL = "ACCOUNT_MODAL",
}

export enum QuestsResults {
  DONE = "DONE",
  GOAL = "GOAL",
}
