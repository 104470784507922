import React, { useCallback } from "react";
import { Box, Button, Grid, Grow, Menu, MenuItem, Paper, Popover, Tab, Tabs, Typography } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { useField } from "formik";
import { debounce } from "lodash";

import { spacing } from "assets/styles/spacing";
import SearchBar from "components/header/SearchBar";
import { Challenge } from "types/common.types";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import api from "api";
import ButtonLoading from "components/buttons/ButtonLoading";
import { retrieveFilenameFromHeader } from "utils/link.utils";

import { ChallengeTab } from "enums/type.enums";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { updateChallengeTab } from "store/challenge/challenge.actions";
import ResultsFormFilters from "./ResultsFormFilters";

const PaperWide = muiStyled(Paper)({
  position: "relative",
  flex: 1,
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
});

const SearchBarContainer = styled.div`
  flex: 1;
`;

const FormContainer = muiStyled(Box)({
  margin: spacing(4),
  [`& > *`]: {
    margin: spacing(2),
  },
});

const MenuItemNoPadding = muiStyled(MenuItem)({
  padding: 0,
});

const ButtonWide = muiStyled(Button)({
  width: "100%",
  padding: spacing(3),
});

interface Props {
  challenge: Challenge;
  submitForm: () => void;
}

const ResultsForm: React.FC<Props> = ({ challenge, submitForm }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state: RootState) => state.challenge.selectedTab);

  const [{ value: fieldSearchValue } /* meta */, , { setValue: setFieldSearch }] = useField("search");
  const [{ value: fieldAccountSearchValue } /* meta */, , { setValue: setFieldAccountSearch }] = useField("accountSearch");
  const [popoverFiltersAnchorEl, setPopoverFiltersAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [popoverExportsAnchorEl, setPopoverExportsAnchorEl] = React.useState<HTMLElement | null>(null);
  const [isLoadingExportResults, exportResultsWithSnackbar] = useSnackbarPromiseHandler(api.exports.results);
  const [isLoadingExportOrders, exportOrdersWithSnackbar] = useSnackbarPromiseHandler(api.exports.orders);
  const isOpenPopoverFilters = Boolean(popoverFiltersAnchorEl);
  const isOpenPopoverExports = Boolean(popoverExportsAnchorEl);

  const intl = useIntl();

  const handleOpenExportDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverExportsAnchorEl(event.currentTarget);
  };

  const handleCloseExportDropdown = () => {
    setPopoverExportsAnchorEl(null);
  };

  const fetchExportedResultsUrl = async (): Promise<[any, string]> => {
    const result = await exportResultsWithSnackbar(challenge.id);
    const filename = retrieveFilenameFromHeader(result) || intl.formatMessage({ id: "results.export.filename" });

    return [window.URL.createObjectURL(new Blob([result.data], { type: result.data.type })), filename];
  };

  const fetchExportedOrdersUrl = async (): Promise<[any, string]> => {
    const result = await exportOrdersWithSnackbar(challenge.id);
    const filename = retrieveFilenameFromHeader(result) || intl.formatMessage({ id: "results.export.orders.filename" });

    return [window.URL.createObjectURL(new Blob([result.data], { type: result.data.type })), filename];
  };

  const handleClickExportResults = async () => {
    handleCloseExportDropdown();

    const [url, filename] = await fetchExportedResultsUrl();
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", filename);
    link.click();
  };

  const handleClickExportOrders = async () => {
    handleCloseExportDropdown();

    const [url, filename] = await fetchExportedOrdersUrl();
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", filename);
    link.click();
  };

  const handleOpenFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverFiltersAnchorEl(event.currentTarget);
  };

  const handleCloseFilters = () => {
    setPopoverFiltersAnchorEl(null);
  };

  const debouncedSubmitForm = useCallback(debounce(submitForm, 500), []);
  const handleActiveTabChange = (event: React.SyntheticEvent, newValue: ChallengeTab) => {
    dispatch(updateChallengeTab(newValue));
    debouncedSubmitForm();
  };

  return (
    <Grid item container xs={12}>
      <Grow in>
        <PaperWide>
          <Grid item container xs={12}>
            <Typography variant="h3" color="textPrimary">
              {challenge.name}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Tabs value={activeTab} onChange={handleActiveTabChange} textColor="primary" indicatorColor="primary">
              <Tab value={ChallengeTab.CONTACTS} label={intl.formatMessage({ id: "form.challenge.tabs.participants" })} />
              <Tab value={ChallengeTab.ACCOUNTS} label={intl.formatMessage({ id: "form.challenge.tabs.accounts" })} />
            </Tabs>
          </Grid>
          <FormContainer display="flex" alignItems="center">
            {activeTab === ChallengeTab.CONTACTS && (
              <>
                <Button variant="contained" onClick={handleOpenFilters}>
                  <FormattedMessage id="form.button.filter" />
                </Button>
                <Popover
                  open={isOpenPopoverFilters}
                  anchorEl={popoverFiltersAnchorEl}
                  onClose={handleCloseFilters}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  keepMounted
                >
                  <ResultsFormFilters quests={challenge.quests} debouncedSubmitForm={debouncedSubmitForm} />
                </Popover>
              </>
            )}
            <SearchBarContainer>
              <SearchBar
                value={activeTab === ChallengeTab.CONTACTS ? fieldSearchValue : fieldAccountSearchValue}
                onChange={(e) => {
                  if (activeTab === ChallengeTab.CONTACTS) {
                    setFieldSearch(e.target.value);
                  } else {
                    setFieldAccountSearch(e.target.value);
                  }
                  debouncedSubmitForm();
                }}
                placeholderIntlId={
                  activeTab === ChallengeTab.ACCOUNTS ? "form.accounts.search.placeholder" : "form.results.search.placeholder"
                }
              />
            </SearchBarContainer>
            <Button variant="contained" color="primary" type="submit">
              <FormattedMessage id="form.search" />
            </Button>
            <ButtonLoading
              isLoading={isLoadingExportResults || isLoadingExportOrders}
              variant="contained"
              onClick={handleOpenExportDropdown}
            >
              <FormattedMessage id="form.button.exports" />
            </ButtonLoading>
            <Menu anchorEl={popoverExportsAnchorEl} keepMounted open={isOpenPopoverExports} onClose={handleCloseExportDropdown}>
              <MenuItemNoPadding>
                <ButtonWide onClick={handleClickExportResults}>
                  <FormattedMessage id="form.button.exportResults" />
                </ButtonWide>
              </MenuItemNoPadding>
              <MenuItemNoPadding>
                <ButtonWide onClick={handleClickExportOrders}>
                  <FormattedMessage id="form.button.exportOrders" />
                </ButtonWide>
              </MenuItemNoPadding>
            </Menu>
          </FormContainer>
        </PaperWide>
      </Grow>
    </Grid>
  );
};

export default ResultsForm;
