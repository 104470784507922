const API_BASE_HOSTNAME = process.env.REACT_APP_API_BASE_HOSTNAME || "https://clubdes100.rvo.fr";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://clubdes100.rvo.fr/api/club";
const ROUTER_BASE_URL = process.env.ROUTER_BASE_URL || "/club";

const ENV = process.env.REACT_APP_ENV || "PRD";

const DEV_TOKEN = "";

const WEBSOCKET_ENDPOINT = process.env.REACT_APP_WEBSOCKET_ENDPOINT || "https://clubdes100.rvo.fr/websocket";

const RECAPTCHA_KEY = "6LdEHuIUAAAAAH1VnewKJ_t0QT79Qq69gIeeWbxN";

const ACR_VALUES_ARCA = process.env.REACT_APP_ACR_VALUES || "secure/name/x509-kerb-form/uri";
const AUTHORIZATION_ENDPOINT = process.env.REACT_APP_AUTHORIZATION_ENDPOINT || "https://idp.renault.com/nidp/oauth/nam/authz";
const CLIENT_ID_ARCA = process.env.REACT_APP_CLIENT_ID_ARCA || "c2d1fd2b-2f85-4672-b9c2-4993ab227255";
const REDIRECT_URI = "https://clubdes100.rvo.fr/club";
const REQUESTED_SCOPES_ARCA = process.env.REACT_APP_REQUESTED_SCOPES || "role arcaX role-vvo-irn70754";
const TOKEN_ENDPOINT = process.env.REACT_APP_TOKEN_ENDPOINT || "https://idp.renault.com/nidp/oauth/nam/token";

export {
  ENV,
  API_BASE_HOSTNAME,
  ROUTER_BASE_URL,
  API_BASE_URL,
  DEV_TOKEN,
  WEBSOCKET_ENDPOINT,
  RECAPTCHA_KEY,
  ACR_VALUES_ARCA,
  AUTHORIZATION_ENDPOINT,
  CLIENT_ID_ARCA,
  REDIRECT_URI,
  REQUESTED_SCOPES_ARCA,
  TOKEN_ENDPOINT,
};
