import React from "react";
import styled from "styled-components";
import { Box, MenuItem, Select } from "@material-ui/core";
import { spacing } from "assets/styles/spacing";

const ContainerSelectYear = styled(Box)`
  margin-bottom: ${spacing(3)}px;
  padding-right: ${({ $paddingRight }) => `${$paddingRight}px`};
`;

interface Props {
  years: number[];
  year: number;
  setYear: (year: number) => void;
  paddingRight?: number;
}

const SelectYear: React.FC<Props> = ({ years, year, setYear, paddingRight = 0 }) => (
  <ContainerSelectYear display="flex" flexDirection="column" alignItems="flex-end" $paddingRight={paddingRight}>
    <Select
      value={year}
      onChange={(event: { target: { value: number } }) => {
        setYear(event.target.value);
      }}
      variant="outlined"
    >
      {years.map((y) => (
        <MenuItem key={y} value={y}>
          {y}
        </MenuItem>
      ))}
    </Select>
  </ContainerSelectYear>
);

export default SelectYear;
