import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  FormControlLabel,
  Grid,
  Grow,
  Paper,
  Switch,
  TableContainer as MuiTableContainer,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import { FormattedMessage, useIntl } from "react-intl";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import styled from "styled-components";
import { AlertCircle, BarChart2, CheckCircle } from "react-feather";

import { spacing } from "assets/styles/spacing";
import { QuestType } from "types/common.types";
import { QuestGoalType } from "enums/type.enums";
import theme from "theme";
import { ResultsFilterResponseType, ResultsFilterRowResponseType } from "types/form.types";
import AbsoluteLoader from "components/loader/AbsoluteLoader";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import api from "api";
import { ServerSuccesses } from "enums/server.enums";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import actions from "store/actions";
import { formatQuestResult, isWon } from "utils/format.utils";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import { AuthMode } from "enums/api.enums";
import CommentModal from "screens/results/modal/Comment.modal";
import AdjudicationToggle from "../components/AdjudicationToggle";

const PaperWide = muiStyled(Paper)({
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  maxWidth: "100%",
  boxSizing: "border-box",
});

const Title = styled.div`
  display: flex;
  width: 100%;
  margin: ${spacing(3)}px 0;
`;

const ColumnIconContainer = muiStyled(Box)({
  marginRight: 8,
});

const ChipResultWon = muiStyled(Chip)({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.success.light,
});

const ChipResultLost = muiStyled(Chip)({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.error.light,
});

const ChipResultInProgress = muiStyled(Chip)({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.warning.light,
});

const TableContainer = muiStyled(MuiTableContainer)({
  flex: 1,
  overflow: "auto",
});

const TableRowSelectable = muiStyled(TableRow)({
  cursor: "pointer",
});

const GridContainer = styled(Grid)`
  height: 100%;
`;

interface Column {
  id: "account" | "name" | "registered" | "result" | "adjudication" | "active" | string;
  label: string;
  minWidth?: number;
  width?: number;
  align?: "right";
  type?: string;
}

interface Props {
  results: ResultsFilterResponseType;
  selectedRow: ResultsFilterRowResponseType;
  setSelectedRow: (row: ResultsFilterRowResponseType) => void;
  canExpand: boolean;
  isLoading: boolean;
}

const ResultsTable: React.FC<Props> = ({ results, selectedRow, setSelectedRow, canExpand, isLoading }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [openCommentModal, setOpenCommentModal] = React.useState<boolean>(false);
  const [rowModal, setRowModal] = useState<ResultsFilterRowResponseType | undefined>();
  const [columns, setColumns] = useState<Column[]>([]);
  const [, changeRegistrationWithSnackbar] = useSnackbarPromiseHandler(
    async (challengeId: string, accountId: string, registered: boolean) => {
      dispatch(actions.results.updateContactRegistration(accountId, registered));
      await api.results.registration(challengeId, accountId, registered);
    },
    `server.success.${ServerSuccesses.EDIT_SUCCESS}`
  );
  const isAuthModeCLUB = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE) === AuthMode.CLUB;

  const { challenge } = useSelector((state: RootState) => state.challenge);

  useEffect(() => {
    if (results.length === 0) return;

    const progressiveQuests = results[0].quests.filter((quest) => quest.type === QuestGoalType.PROGRESSIVE);
    const unitaryQuests = results[0].quests.filter((quest) => quest.type === QuestGoalType.UNITARY);

    setColumns([
      {
        id: "active",
        label: undefined,
        width: 0,
      },
      {
        id: "account",
        label: intl.formatMessage({ id: "results.table.column.label.account" }),
      },
      {
        id: "name",
        label: intl.formatMessage({ id: "results.table.column.label.name" }),
      },
      {
        id: "registered",
        label: intl.formatMessage({ id: "results.table.column.label.registered" }),
      },
      ...[...progressiveQuests, ...unitaryQuests].map((quest: QuestType) => ({
        id: String(quest.id),
        label: quest.name,
        type: quest.type,
      })),
      {
        id: "result",
        label: intl.formatMessage({ id: "results.table.column.label.result" }),
      },
      {
        id: "adjudication",
        label: intl.formatMessage({ id: "results.table.column.label.adjudication" }),
      },
    ]);
  }, [results]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleChangeRegistration = async (accountId: string, checked: boolean) => {
    await changeRegistrationWithSnackbar(challenge.id, accountId, checked);
  };

  const handleChangeAdjudication = async (
    accountId: string,
    event: React.ChangeEvent<{}>,
    newValue: boolean | undefined | null,
    row: ResultsFilterRowResponseType
  ) => {
    event.preventDefault();
    event.stopPropagation();

    setRowModal(row);
    setOpenCommentModal(true);

    if (newValue === null) return;
    dispatch(actions.results.updateContactAdjudication(accountId, newValue));
  };

  return (
    <GridContainer
      item
      container
      xs={canExpand ? 12 : 8}
      style={{
        transition: theme.transitions.create("all", {
          easing: theme.transitions.easing.sharp,
          duration: 500,
        }),
      }}
    >
      {isLoading && <AbsoluteLoader />}
      <Grow in>
        <PaperWide>
          <Title>
            {isLoading ? (
              <Typography variant="h4">
                <FormattedMessage id="common.loading" />
              </Typography>
            ) : (
              <Typography variant="h4">
                <FormattedMessage id="results.table.title" values={{ count: results.length }} />
              </Typography>
            )}
          </Title>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => {
                    // no minWidth on last item
                    const minWidth = index === columns.length - 1 ? 0 : 100;
                    const isProgressive = column.type === QuestGoalType.PROGRESSIVE;
                    const isUnitary = column.type === QuestGoalType.UNITARY;

                    return (
                      <TableCell key={column.id} align="left" style={{ minWidth: column.width ?? minWidth, maxWidth: column.width ?? 200 }}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          {(isProgressive || isUnitary) && (
                            <ColumnIconContainer>
                              {isProgressive && <BarChart2 />}
                              {isUnitary && <CheckCircle />}
                            </ColumnIconContainer>
                          )}
                          {column.label}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {results &&
                  results
                    .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                    .map((row: ResultsFilterRowResponseType) => (
                      <TableRowSelectable
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => {
                          setSelectedRow(row);
                        }}
                        selected={row.id === selectedRow?.id}
                      >
                        {columns.map((column: Column): React.ReactElement => {
                          switch (column.id) {
                            case "active": {
                              return (
                                <TableCell key={column.id} align="center" width="30px">
                                  <Box display="flex" flexDirection="column" justifyContent="center">
                                    <Box display="flex" m="auto">
                                      {!row?.active && (
                                        <Tooltip title={intl.formatMessage({ id: "results.table.column.item.active.tooltip" })}>
                                          <AlertCircle style={{ stroke: theme.palette.primary.main }} />
                                        </Tooltip>
                                      )}
                                    </Box>
                                  </Box>
                                </TableCell>
                              );
                            }

                            case "account": {
                              return (
                                <TableCell key={column.id} align="left">
                                  {row.accounts.length === 1 ? (
                                    row.accounts[0].name
                                  ) : (
                                    <FormattedMessage id="results.table.column.item.severalAccounts" />
                                  )}
                                </TableCell>
                              );
                            }

                            case "name": {
                              return (
                                <TableCell key={column.id} align="left">
                                  {row.name}
                                </TableCell>
                              );
                            }

                            case "registered": {
                              return (
                                <TableCell key={column.id} align="left">
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={row.registered}
                                        onClick={(event: React.MouseEvent<any>) => event.stopPropagation()}
                                        onChange={(event: any) => handleChangeRegistration(row.id, event.target.checked)}
                                        style={{
                                          color: row.registered ? theme.palette.success.light : theme.palette.error.light,
                                          cursor: row.ordered ? "default" : "pointer",
                                        }}
                                        disabled={row.ordered || isAuthModeCLUB}
                                      />
                                    }
                                    label={intl.formatMessage({
                                      id: `results.table.filter.item.registered${row.registered ? "" : ".not"}`,
                                    })}
                                  />
                                </TableCell>
                              );
                            }

                            case "result": {
                              return (
                                <TableCell key={column.id} align="left">
                                  {row.allQuestsCompleted ? (
                                    <ChipResultWon label={intl.formatMessage({ id: "results.table.column.item.result.won" })} />
                                  ) : (
                                    <ChipResultLost label={intl.formatMessage({ id: "results.table.column.item.result.lost" })} />
                                  )}
                                </TableCell>
                              );
                            }

                            case "adjudication": {
                              return (
                                <TableCell key={column.id} align="left">
                                  {!row?.comment ? (
                                    <AdjudicationToggle
                                      row={row}
                                      handleChangeAdjudication={handleChangeAdjudication}
                                      isAuthModeCLUB={isAuthModeCLUB}
                                    />
                                  ) : (
                                    <Tooltip title={row?.comment} placement="left">
                                      <Box>
                                        <AdjudicationToggle
                                          row={row}
                                          handleChangeAdjudication={handleChangeAdjudication}
                                          isAuthModeCLUB={isAuthModeCLUB}
                                        />
                                      </Box>
                                    </Tooltip>
                                  )}
                                </TableCell>
                              );
                            }

                            default: {
                              const quest: QuestType = row.quests.find((curQuest: QuestType) => String(curQuest.id) === column.id);

                              if (!quest) {
                                return null;
                              }

                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {isWon(quest) ? (
                                    <ChipResultWon label={formatQuestResult(quest.done, quest.goal, quest.type)} />
                                  ) : (
                                    <ChipResultInProgress label={formatQuestResult(quest.done, quest.goal, quest.type)} />
                                  )}
                                </TableCell>
                              );
                            }
                          }
                        })}
                      </TableRowSelectable>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={results.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={intl.formatMessage({ id: "common.table.rowsPerPage" })}
            labelDisplayedRows={({ from, to, count }) =>
              intl.formatMessage(
                { id: "common.table.DisplayedRows" },
                {
                  from,
                  to,
                  count,
                }
              )
            }
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </PaperWide>
      </Grow>

      <CommentModal
        open={openCommentModal}
        onClose={() => setOpenCommentModal(false)}
        row={rowModal}
        isAuthModeCLUB={isAuthModeCLUB}
        challengeId={challenge.id}
      />
    </GridContainer>
  );
};

export default ResultsTable;
