import { StoreItemGamut } from "enums/type.enums";
import { colors } from "assets/styles/colors";

export const storeItemGamutToColor = (gamut: StoreItemGamut) => {
  switch (gamut) {
    case StoreItemGamut.BRONZE:
      return colors.bronze;
    case StoreItemGamut.SILVER:
      return colors.silver;
    case StoreItemGamut.GOLD:
      return colors.gold;
    default:
      return "white";
  }
};
