import { AccountsFilterResponseType, AccountsFilterRowResponseType, SearchAccountResponse, SearchContactResponse } from "types/form.types";
import { Account } from "types/common.types";

export interface AccountsState {
  accounts: AccountsFilterResponseType;
  account: Account;
  filteredAccounts: SearchAccountResponse[];
  filteredContacts: SearchContactResponse[];
  accountDetails: AccountsFilterRowResponseType;
}

type ActionTypes = {
  GET_ACCOUNTS: "GET_ACCOUNTS";
  GET_ACCOUNT: "GET_ACCOUNT";
  DELETE_ACCOUNT_CONTACT: "DELETE_ACCOUNT_CONTACT";
  GET_FILTERED_ACCOUNTS: "GET_FILTERED_ACCOUNTS";
  GET_FILTERED_CONTACTS: "GET_FILTERED_CONTACTS";
  ACCOUNT_DETAILS: "ACCOUNT_DETAILS";
  DELETE_C0NTACT: "DELETE_CONTACT";
};

export const ACTION_TYPES: ActionTypes = {
  GET_ACCOUNTS: "GET_ACCOUNTS",
  GET_ACCOUNT: "GET_ACCOUNT",
  DELETE_ACCOUNT_CONTACT: "DELETE_ACCOUNT_CONTACT",
  GET_FILTERED_ACCOUNTS: "GET_FILTERED_ACCOUNTS",
  GET_FILTERED_CONTACTS: "GET_FILTERED_CONTACTS",
  ACCOUNT_DETAILS: "ACCOUNT_DETAILS",
  DELETE_C0NTACT: "DELETE_CONTACT",
};

export type ResultsActionTypes =
  | {
      type: typeof ACTION_TYPES.GET_ACCOUNTS;
      payload: AccountsFilterResponseType;
    }
  | {
      type: typeof ACTION_TYPES.GET_ACCOUNT;
      payload: Account;
    }
  | {
      type: typeof ACTION_TYPES.DELETE_ACCOUNT_CONTACT;
      payload: string;
    }
  | {
      type: typeof ACTION_TYPES.GET_FILTERED_ACCOUNTS;
      payload: SearchAccountResponse[];
    }
  | {
      type: typeof ACTION_TYPES.GET_FILTERED_CONTACTS;
      payload: SearchContactResponse[];
    }
  | {
      type: typeof ACTION_TYPES.ACCOUNT_DETAILS;
      payload: AccountsFilterRowResponseType;
    }
  | {
      type: typeof ACTION_TYPES.DELETE_C0NTACT;
      payload: string;
    };
