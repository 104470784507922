import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useField } from "formik";
import styled from "styled-components";

const ItemBody = styled.div`
  margin-left: 56px;
`;

const ResultsFormFiltersRegisteredItem: React.FC = () => {
  const [fieldRegistered] = useField({ name: "registered" });
  const [fieldNotRegistered] = useField({ name: "notRegistered" });

  return (
    <ListItem key="filter-result">
      <ItemBody>
        <FormControlLabel
          control={<Checkbox color="primary" {...fieldNotRegistered} checked={fieldNotRegistered.value} />}
          label={
            <Typography variant="body2">
              <FormattedMessage id="results.table.filter.item.registered.not" />
            </Typography>
          }
          labelPlacement="end"
        />
        <FormControlLabel
          control={<Checkbox color="primary" {...fieldRegistered} checked={fieldRegistered.value} />}
          label={
            <Typography variant="body2">
              <FormattedMessage id="results.table.filter.item.registered" />
            </Typography>
          }
          labelPlacement="end"
        />
      </ItemBody>
    </ListItem>
  );
};

export default ResultsFormFiltersRegisteredItem;
