import * as ENV from "config/env";
import { LOCAL_STORAGE } from "./authentication-constants.utils";

declare global {
  interface Window {
    __UPDATING_AUTHENTICATION__: boolean;
  }
}

/* const TIMEOUT_ERROR = { error: "timeout", error_description: "Timeout" }; */

const sha256 = (plain: string): Promise<ArrayBuffer> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
};

const base64urlencode = (str: ArrayBuffer): string =>
  btoa(String.fromCharCode.apply(null, new Uint8Array(str) as unknown as Array<number>))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

const generateRandomString = (): string => {
  const array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => `0${dec.toString(16)}`.substr(-2)).join("");
};

const pkceChallengeFromVerifier = async (v: string): Promise<string> => {
  const hashed = await sha256(v);
  return base64urlencode(hashed);
};

export const isInIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const generateLoginUrl = async (silent = false): Promise<string> => {
  const state = generateRandomString();
  localStorage.setItem(LOCAL_STORAGE.PKCE, state);

  // Create and store a new PKCE code_verifier (the plaintext random secret)
  const codeVerifier = generateRandomString();
  localStorage.setItem(LOCAL_STORAGE.PKCE_VERIFIER, codeVerifier);

  // Hash and base64-urlencode the secret to use as the challenge
  const codeChallenge = await pkceChallengeFromVerifier(codeVerifier);

  let params: any = {
    response_type: "code",
    client_id: encodeURIComponent(ENV.CLIENT_ID_ARCA),
    state: encodeURIComponent(state),
    scope: encodeURIComponent(ENV.REQUESTED_SCOPES_ARCA),
    acr_values: ENV.ACR_VALUES_ARCA,
    redirect_uri: encodeURIComponent(ENV.REDIRECT_URI),
    code_challenge: encodeURIComponent(codeChallenge),
    code_challenge_method: "S256",
  };

  if (silent) {
    params = { ...params, prompt: "none", response_mode: "web_message" };
  }

  const parameters = Object.keys(params)
    .map((key) => (params[key] ? `${key}=${params[key]}` : ""))
    .join("&");

  return `${ENV.AUTHORIZATION_ENDPOINT}?${parameters}`;
};

export const getAccessToken = (
  code: string,
  success: (request: XMLHttpRequest, body: any) => void,
  error: (request: XMLHttpRequest, error: any) => void
) => {
  const defaultParams = {
    grant_type: "authorization_code",
    client_id: ENV.CLIENT_ID_ARCA,
    redirect_uri: ENV.REDIRECT_URI,
    code_verifier: localStorage.getItem(LOCAL_STORAGE.PKCE_VERIFIER),
  };

  const params = { ...defaultParams, code };

  const request = new XMLHttpRequest();
  request.open("POST", ENV.TOKEN_ENDPOINT, true);
  request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");

  request.onload = function onLoad() {
    let body = {};
    try {
      body = JSON.parse(request.response);
    } catch (e) {
      /* eslint-disable-next-line no-console */
      console.error(e);
    }

    if (request.status === 200) {
      success(request, body);
    } else {
      error(request, body);
    }
  };

  request.onerror = function onError() {
    error(request, {});
  };

  const body = Object.keys(params)
    .map((key) => (params[key as keyof typeof params] ? `${key}=${params[key as keyof typeof params]}` : ""))
    .join("&");

  request.send(body);
};

/*
export const getTokenInformations = (): Record<string, string> => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE.API_TOKEN) || "";
  const decodedToken = window.atob(accessToken.split(".")[1] || "") || "{}";
  return JSON.parse(decodedToken);
};
*/

/*
const runIframe = (authorizeUrl: string, eventOrigin: string): Promise<any> =>
  new Promise((res, rej) => {
    const iframe = window.document.createElement("iframe");
    iframe.setAttribute("width", "0");
    iframe.setAttribute("name", "authentication-frame");
    iframe.setAttribute("height", "0");
    iframe.style.display = "none";

    const timeoutSetTimeoutId = setTimeout(() => {
      rej(TIMEOUT_ERROR);
      window.document.body.removeChild(iframe);
    }, 60 * 1000);

    const iframeEventHandler = function iframeHandler(e: any) {
      if (e.origin !== eventOrigin) return;
      if (!e.data) return;

      e.source.close();
      if (e.data.error) {
        rej(e.data);
      } else {
        res(e.data);
      }
      clearTimeout(timeoutSetTimeoutId);
      window.removeEventListener("message", iframeEventHandler, false);
      if (window.document.body) {
        window.document.body.removeChild(iframe);
      }
    };

    window.addEventListener("message", iframeEventHandler, false);
    window.document.body.appendChild(iframe);
    iframe.setAttribute("src", authorizeUrl);
  });
*/

/*
const updateToken = async (): Promise<boolean> => {
  if (!window.__UPDATING_AUTHENTICATION__) {
    try {
      window.__UPDATING_AUTHENTICATION__ = true;
      const url = await generateLoginUrl(true);
      const response = await runIframe(url, window.location.origin);
      const { access_token: accessToken } = response;
      localStorage.setItem(LOCAL_STORAGE.API_TOKEN, `Bearer ${accessToken}`);
      return accessToken;
    } catch (e) {
      return false;
    } finally {
      window.__UPDATING_AUTHENTICATION__ = false;
    }
  }

  return true;
};
*/

export const getTokenSilently = async (): Promise<any> => {
  const jwt = localStorage.getItem(LOCAL_STORAGE.API_TOKEN) || "";
  if (ENV.ENV === "DEV") {
    return jwt;
  }

  /* try {
    const { exp } = jwtDecode(jwt) as any;
    if (exp * 1000 - 60 * 10 * 1000 < new Date().getTime()) {
      updateToken();
    }
  } catch (e) {
    return jwt;
  } */

  return jwt;
};
