import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { Eye, X } from "react-feather";
import theme from "theme";
import { styled as muiStyled } from "@material-ui/core/styles";
import type { AccountsFilterRowResponseType } from "types/form.types";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
import AbsoluteLoader from "components/loader/AbsoluteLoader";
import { RootState } from "store/types";
import ListSubheader from "@material-ui/core/ListSubheader";
import { ChallengeModalTab, QuestGoalType } from "enums/type.enums";
import { AccountQuestType } from "types/common.types";
import styled from "styled-components";
import List from "@material-ui/core/List";
import ResultsPanelQuestItem from "screens/results/body/panel/ResultsPanelQuestItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import { ServerSuccesses } from "enums/server.enums";

import TableButton from "screens/results/components/TableButton";
import { updateChallengeModalTab } from "store/challenge/challenge.actions";
import AddContactModal from "./AddContact.modal";

const ModalTitle = muiStyled(Grid)({
  borderBottom: `solid 1px ${theme.palette.primary.main}`,
});

const ModalAction = muiStyled(Grid)({
  display: "flex",
  justifyContent: "flex-end",
  paddingLeft: 25,
  paddingRight: 25,
  paddingTop: 10,
  paddingBottom: 20,
});

const BodyContainer = styled.div`
  overflow: auto;
  flex: 1;
`;

const ListContainer = muiStyled(List)({
  width: "100%",
});

interface Column {
  id: "name" | "ipn" | "allQuestsCompleted";
  label: string;
  minWidth?: number;
  width?: number;
  align?: "right";
}

const ChipResultWon = muiStyled(Chip)({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.success.light,
});

const ChipResultLost = muiStyled(Chip)({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.error.light,
});

interface Props {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  row?: AccountsFilterRowResponseType;
  submitForm: () => void;
  isLoadingResults: boolean;
}

const AccountModal: React.FC<Props> = ({ open, onClose, row, submitForm, isLoadingResults }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const account = useSelector((state: RootState) => state.accounts.account);

  const [openAddContact, setOpenAddContact] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progressiveQuests, setProgressiveQuests] = useState<AccountQuestType[]>([]);
  const [unitaryQuests, setUnitaryQuests] = useState<AccountQuestType[]>([]);
  const { challenge } = useSelector((state: RootState) => state.challenge);
  const contactsList = useSelector((state: RootState) => state.results);

  const [isLoadingDelete, deleteAccountContact] = useSnackbarPromiseHandler(async (contactId: string) => {
    dispatch(actions.accounts.deleteAccountContact(challenge?.id, account?.id, contactId));
    dispatch(actions.results.deleteContactAccount(contactId, account?.id));
  }, `server.success.${ServerSuccesses.DELETE_CONTACT}`);

  const contacts: Column[] = [
    {
      id: "name",
      label: intl.formatMessage({ id: "accounts.details.contacts.name" }),
    },
    {
      id: "ipn",
      label: intl.formatMessage({ id: "accounts.details.contacts.ipn" }),
    },
    {
      id: "allQuestsCompleted",
      label: intl.formatMessage({ id: "accounts.details.contacts.allQuestsCompleted" }),
    },
  ];

  const getAccountDetails = async () => {
    dispatch(actions.challenge.setResultDetails(undefined));
    dispatch(actions.accounts.getAccount(row?.id, challenge.id));
  };

  const getContactDetails = async (id: string) => {
    dispatch(actions.accounts.setAccountDetails(undefined));
    const payload = contactsList.filter((c) => c.id === id);
    dispatch(actions.challenge.setResultDetails(payload[0] ?? undefined));
  };

  useEffect(() => {
    if (challenge?.id && row?.id) {
      (async function getAccountEffect() {
        try {
          setIsLoading(true);
          await getAccountDetails();
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [row, challenge?.id]);

  useEffect(() => {
    if (account !== undefined) {
      setProgressiveQuests(account?.quests?.filter((quest: AccountQuestType) => quest.questType === QuestGoalType.PROGRESSIVE) ?? []);
      setUnitaryQuests(account?.quests?.filter((quest: AccountQuestType) => quest.questType === QuestGoalType.UNITARY) ?? []);
    }
  }, [account]);

  useEffect(() => {
    if (open) {
      dispatch(updateChallengeModalTab(ChallengeModalTab.ACCOUNT_MODAL));
    }
  }, [open]);

  const loading = isLoading || isLoadingDelete;

  return (
    <>
      {loading && <AbsoluteLoader />}
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
        {isLoadingResults && <AbsoluteLoader />}
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <ModalTitle xs={12} display="flex">
            <Box>
              <Typography fontWeight={500} />
              {row?.name}

              <Typography>
                {row?.contacts?.length === 1 && row.contacts[0].name}
                {row?.contacts?.length === 0 && <FormattedMessage id="results.table.column.item.noAccounts" />}
                {row?.contacts?.length > 1 && <FormattedMessage id="results.table.column.item.severalAccounts" />}
              </Typography>
            </Box>
            <Box position="absolute" top={0} right={0} p={1} pr={2}>
              <IconButton aria-label="close" onClick={onClose} size="small">
                <X />
              </IconButton>
            </Box>
          </ModalTitle>
        </DialogTitle>
        <DialogContent>
          <BodyContainer>
            <ListContainer>
              <ListSubheader disableSticky>
                <FormattedMessage
                  id={`quest.type.plural.${QuestGoalType.PROGRESSIVE}`}
                  values={{ count: progressiveQuests?.length ?? 0 }}
                />
              </ListSubheader>
              {progressiveQuests?.map((quest: AccountQuestType) => (
                <ResultsPanelQuestItem
                  quest={quest}
                  key={quest.questId}
                  type={quest.questType}
                  resultId={row?.id}
                  submitForm={submitForm}
                />
              ))}
              {progressiveQuests?.length === 0 && (
                <Box ml={3}>
                  <FormattedMessage id="accounts.details.noquest" />
                </Box>
              )}
              <ListSubheader disableSticky>
                <FormattedMessage id={`quest.type.plural.${QuestGoalType.UNITARY}`} values={{ count: unitaryQuests?.length ?? 0 }} />
              </ListSubheader>
              {unitaryQuests?.map((quest: AccountQuestType) => (
                <ResultsPanelQuestItem
                  quest={quest}
                  key={quest.questId}
                  type={quest.questType}
                  resultId={row?.id}
                  submitForm={submitForm}
                />
              ))}
            </ListContainer>
            <Box mt={4} mb={4}>
              <Divider variant="middle" />
            </Box>
            <TableContainer>
              <Box pb={3}>
                <Typography variant="h6">
                  <FormattedMessage id="accounts.details.contacts" />
                </Typography>
              </Box>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {contacts.map((column) => (
                      <TableCell key={column.id} align="left" style={{ maxWidth: 200 }}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          {column.label}
                        </Box>
                      </TableCell>
                    ))}
                    <TableCell key="info" align="right" style={{ width: 30 }} />
                    <TableCell key="delete" align="right" style={{ width: 30 }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {account?.contacts?.map((item) => (
                    <TableRow>
                      {contacts.map((column: Column): React.ReactElement => {
                        switch (column.id) {
                          case "name": {
                            return (
                              <TableCell key={column.id} align="left">
                                {`${item.lastname} ${item.firstname}`}
                              </TableCell>
                            );
                          }

                          case "ipn": {
                            return (
                              <TableCell key={column.id} align="left">
                                {item.ipn}
                              </TableCell>
                            );
                          }

                          case "allQuestsCompleted": {
                            return (
                              <TableCell key={column.id} align="left">
                                {item.won ? (
                                  <ChipResultWon
                                    label={intl.formatMessage({
                                      id: item?.adjudication
                                        ? "results.table.column.item.result.won.override"
                                        : "results.table.column.item.result.won",
                                    })}
                                  />
                                ) : (
                                  <ChipResultLost
                                    label={intl.formatMessage({
                                      id: item?.adjudication
                                        ? "results.table.column.item.result.lost.override"
                                        : "results.table.column.item.result.lost",
                                    })}
                                  />
                                )}
                              </TableCell>
                            );
                          }

                          default: {
                            return (
                              <TableCell key={column.id} align="left">
                                -
                              </TableCell>
                            );
                          }
                        }
                      })}
                      <TableCell key="info" align="right" style={{ width: 30 }}>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                          <TableButton onClick={() => getContactDetails(item.sfid)}>
                            <Eye />
                          </TableButton>
                        </Box>
                      </TableCell>
                      <TableCell key="delete" align="right" style={{ width: 30 }}>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                          <TableButton onClick={() => deleteAccountContact(item.sfid)}>
                            <X style={{ stroke: theme.palette.error.main }} />
                          </TableButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  {account?.contacts?.length === 0 && <FormattedMessage id="accounts.details.noContact" />}
                </TableBody>
              </Table>
            </TableContainer>
          </BodyContainer>
        </DialogContent>
        <ModalAction>
          <Button variant="contained" color="primary" pr={2} onClick={() => setOpenAddContact(true)}>
            <FormattedMessage id="button.accounts.details.addContact" />
          </Button>
        </ModalAction>
      </Dialog>

      {row?.name && (
        <AddContactModal
          open={openAddContact}
          onClose={() => setOpenAddContact(false)}
          challengeId={challenge?.id ?? ""}
          account={{
            name: row.name,
            id: row.id,
            rootAccountName: row.group,
          }}
        />
      )}
    </>
  );
};

export default AccountModal;
