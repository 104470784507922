import { IntlShape } from "react-intl";
import { ProviderContext } from "notistack";
import { ServerErrors } from "../enums/server.enums";

export const snackbarServerErrorRecognized = (
  error: any,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  intl: IntlShape
): boolean => {
  const serverError = error?.response?.data?.error;

  if (serverError && Object.values(ServerErrors).includes(serverError)) {
    enqueueSnackbar(intl.formatMessage({ id: `server.error.${serverError}` }), { variant: "error" });
    return true;
  }

  return false;
};

export const snackbarServerErrorUnknown = (error: any, enqueueSnackbar: ProviderContext["enqueueSnackbar"], intl: IntlShape) =>
  enqueueSnackbar(intl.formatMessage({ id: `server.error.${ServerErrors.UNKNOWN_ERROR}` }), { variant: "error" });

export const snackbarHandleServerError = (error: any, enqueueSnackbar: ProviderContext["enqueueSnackbar"], intl: IntlShape) => {
  if (!snackbarServerErrorRecognized(error, enqueueSnackbar, intl)) {
    snackbarServerErrorUnknown(error, enqueueSnackbar, intl);
  }
};
