import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Form as FormikForm, Formik } from "formik";
import { Box, Grid, Typography } from "@material-ui/core";
import { object, string } from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { colors } from "assets/styles/colors";
import { LoginType } from "types/common.types";
import { spacing } from "assets/styles/spacing";
import actions from "store/actions";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import ButtonLoading from "components/buttons/ButtonLoading";

import ModalForgottenPassword from "./ModalForgottenPassword";

/* == custom styles == */
const Form = styled(FormikForm)`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;
const LostPassword = styled.span`
  display: inline-block;
  margin-top: ${spacing(4)}px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;
const ErrorContainer = styled.div`
  padding-bottom: 6px;
  margin: 0;
`;

const ErrorText = styled.div`
  font-size: 14px;
  color: ${colors.red};
`;

const ContainerBlock = styled.div`
  // get priority over MUI styling
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    & > * {
      margin: ${spacing(6)}px;
    }
  }
`;

interface Props {
  initialValues?: LoginType;
}

const AuthForm: React.FC<Props> = ({
  initialValues = {
    login: "",
    password: "",
  },
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isOpenModalForgotPassword, setIsOpenModalForgotPassword] = useState<boolean>(false);
  const [isLoading, loginWithSnackbar] = useSnackbarPromiseHandler(async (formValues: LoginType) => {
    const token = await executeRecaptcha("LOGIN");

    await dispatch(actions.auth.loginByCredentials(formValues.login, formValues.password, token));
  });

  const validationSchema = object().shape({
    login: string().required("form.error.field.mandatory"),
    password: string().required("form.error.field.mandatory"),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={loginWithSnackbar}>
      {({ values, handleChange, handleBlur, errors, touched }: any) => (
        <Form>
          <ContainerBlock>
            <Typography>
              <FormattedMessage id="screen.auth.form.title" />
            </Typography>
            <Grid container item xs={10}>
              <TextField
                label={intl.formatMessage({
                  id: "screen.auth.form.email.label",
                })}
                margin="dense"
                fullWidth
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{ shrink: true }}
                inputProps={{ placeholder: "jean.dupont@renault.fr" }}
                name="login"
                error={errors.login && touched.login}
                value={values.login}
                variant="outlined"
              />
              {errors.login && touched.login && (
                <ErrorContainer>
                  <ErrorText>
                    <FormattedMessage id={errors.login} />
                  </ErrorText>
                </ErrorContainer>
              )}
              <TextField
                label={intl.formatMessage({
                  id: "screen.auth.form.password.label",
                })}
                margin="dense"
                fullWidth
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{ shrink: true }}
                inputProps={{ placeholder: "********" }}
                name="password"
                error={errors.password && touched.password}
                value={values.password}
                variant="outlined"
              />
              {errors.password && touched.password && (
                <ErrorContainer>
                  <ErrorText>
                    <FormattedMessage id={errors.password} />
                  </ErrorText>
                </ErrorContainer>
              )}
              <Grid container item xs={12}>
                <LostPassword onClick={() => setIsOpenModalForgotPassword(true)}>
                  <FormattedMessage id="screen.auth.form.forgottenPassword" />
                </LostPassword>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Box m={1}>
                <ButtonLoading isLoading={isLoading} variant="contained" color="primary" type="submit">
                  <FormattedMessage id="screen.auth.login.button" />
                </ButtonLoading>
              </Box>
            </Grid>
          </ContainerBlock>
          <ModalForgottenPassword isOpen={isOpenModalForgotPassword} onClose={() => setIsOpenModalForgotPassword(false)} />
        </Form>
      )}
    </Formik>
  );
};

export default AuthForm;
