import React from "react";
import { Route, Switch } from "react-router-dom";
import { useIntl } from "react-intl";

import Layout from "layouts";
import Challenge from "screens/challenge";
import ImportsHistory from "screens/importsHistory";
import ImportsUpload from "screens/importsUpload/ImportsUpload";
import StoreManagement from "screens/storeManagement";
import StoreManagementProduct from "screens/storeManagement/product/StoreManagementProduct";
import Dashboard from "screens/dashboard/Dashboard";
import Authentication from "screens/authentication/Authentication";
import Results from "screens/results/Results";
import Activation from "screens/activation/Activation";

const AppRouter: React.FunctionComponent = () => {
  const intl = useIntl();

  return (
    <Layout>
      <Switch>
        <Route path={intl.formatMessage({ id: "routes.imports.history" })} exact component={ImportsHistory} />
        <Route path={intl.formatMessage({ id: "routes.imports.upload" })} exact component={ImportsUpload} />
        <Route path={intl.formatMessage({ id: "routes.store.management" })} exact component={StoreManagement} />
        <Route path={`${intl.formatMessage({ id: "routes.store.management" })}/:productSlug`} exact component={StoreManagementProduct} />
        <Route path={intl.formatMessage({ id: "routes.authentication" })} exact component={Authentication} />
        <Route path={intl.formatMessage({ id: "routes.challenge" })} exact component={Challenge} />
        <Route path={intl.formatMessage({ id: "routes.results" })} exact component={Results} />
        <Route path={intl.formatMessage({ id: "routes.reset.password" })} exact component={Activation} />
        <Route path={intl.formatMessage({ id: "routes.login" })} exact component={Activation} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </Layout>
  );
};

export default AppRouter;
