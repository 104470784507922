import { styled as muiStyled } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const TableButton = muiStyled(Box)({
  "&:hover > *": {
    cursor: "pointer",
  },
});

export default TableButton;
