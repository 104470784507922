import {
  AccountsFilterFormType,
  AccountsFilterResponseType,
  AccountsFilterRowResponseType,
  SearchAccountResponse,
  SearchContactResponse,
} from "types/form.types";
import { AccountListFilter } from "types/common.types";
import api from "api";

import { ACTION_TYPES } from "./accounts.types";

export const getAccountsResults = (id: string, form: AccountsFilterFormType | {}) => async (dispatch: any) => {
  const { data: payload }: { data: AccountsFilterResponseType } = await api.challenge.accounts(id, form);

  dispatch({
    type: ACTION_TYPES.GET_ACCOUNTS,
    payload,
  });
};

export const getAccount = (id: string, challengeId: string) => async (dispatch: any) => {
  const { data: payload } = await api.challenge.account(id, challengeId);

  dispatch({
    type: ACTION_TYPES.GET_ACCOUNT,
    payload,
  });
};

export const deleteAccountContact = (challengeId: string, accountId: string, contactId: string) => async (dispatch: any) => {
  await api.challenge.deleteAccountContact(challengeId, accountId, contactId);
  const payload = contactId;

  dispatch({
    type: ACTION_TYPES.DELETE_ACCOUNT_CONTACT,
    payload,
  });
};

export const deleteContact = (challengeId: string, contactId: string) => async (dispatch: any) => {
  await api.challenge.deleteContact(challengeId, contactId);
  const payload = contactId;

  dispatch({
    type: ACTION_TYPES.DELETE_C0NTACT,
    payload,
  });
};

export const getFilteredAccounts = (form: AccountListFilter | {}) => async (dispatch: any) => {
  const { data: payload }: { data: SearchAccountResponse[] } = await api.account.filter(form);

  dispatch({
    type: ACTION_TYPES.GET_FILTERED_ACCOUNTS,
    payload,
  });
};

export const getFilteredContacts = (form: AccountListFilter | {}) => async (dispatch: any) => {
  const { data: payload }: { data: SearchContactResponse[] } = await api.contact.filter(form);

  dispatch({
    type: ACTION_TYPES.GET_FILTERED_CONTACTS,
    payload,
  });
};

export const setAccountDetails = (row: AccountsFilterRowResponseType | undefined) => async (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.ACCOUNT_DETAILS,
    payload: row,
  });
};
