import { createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

import { breakpoints } from "assets/styles/breakpoints";
import { colors } from "assets/styles/colors";
import { fonts } from "assets/styles/fonts";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
  palette: {
    type: "dark",
    primary: { main: colors.yellowRenault },
    secondary: {
      main: grey[600],
    },
    tonalOffset: 0.05,
    action: {},
  },
  typography: {
    fontFamily: [fonts.renaultLife, "-apple-system", '"Segoe UI"', "Roboto", "Arial", "sans-serif"].join(","),
  },
});

export default theme;
