import { ChallengeModalTab, ChallengeTab } from "enums/type.enums";
import type { Challenge, Contact } from "types/common.types";
import type { AddContactRequest } from "types/api.types";
import type { ResultsFilterRowResponseType } from "types/form.types";

type ActionTypes = {
  GET_CHALLENGE: "GET_CHALLENGE";
  SELECT_YEAR: "SELECT_YEAR";
  OPEN_STORE: "OPEN_STORE";
  PUBLISH_RESULTS: "PUBLISH_RESULTS";
  SET_CHALLENGE_RULES_URL: "SET_CHALLENGE_RULES_URL";
  GET_CHALLENGE_CONTACTS: "GET_CHALLENGE_CONTACTS";
  CREATE_CHALLENGE: "CREATE_CHALLENGE";
  UPDATE_CHALLENGE: "UPDATE_CHALLENGE";
  CREATE_QUEST: "CREATE_QUEST";
  EDIT_QUEST: "EDIT_QUEST";
  DELETE_QUEST: "DELETE_QUEST";
  UPDATE_CHALLENGE_TAB: "UPDATE_CHALLENGE_TAB";
  UPDATE_CHALLENGE_MODAL_TAB: "SELECTED_CHALLENGE_MODAL_TAB";
  ADD_CONTACT: "ADD_CONTACT";
  RESULT_DETAILS: "RESULT_DETAILS";
};

export const ACTION_TYPES: ActionTypes = {
  GET_CHALLENGE: "GET_CHALLENGE",
  SELECT_YEAR: "SELECT_YEAR",
  OPEN_STORE: "OPEN_STORE",
  PUBLISH_RESULTS: "PUBLISH_RESULTS",
  SET_CHALLENGE_RULES_URL: "SET_CHALLENGE_RULES_URL",
  GET_CHALLENGE_CONTACTS: "GET_CHALLENGE_CONTACTS",
  CREATE_CHALLENGE: "CREATE_CHALLENGE",
  UPDATE_CHALLENGE: "UPDATE_CHALLENGE",
  CREATE_QUEST: "CREATE_QUEST",
  EDIT_QUEST: "EDIT_QUEST",
  DELETE_QUEST: "DELETE_QUEST",
  UPDATE_CHALLENGE_TAB: "UPDATE_CHALLENGE_TAB",
  UPDATE_CHALLENGE_MODAL_TAB: "SELECTED_CHALLENGE_MODAL_TAB",
  ADD_CONTACT: "ADD_CONTACT",
  RESULT_DETAILS: "RESULT_DETAILS",
};

export interface ChallengeState {
  // Available years for challenges
  years: Array<number>;
  selectedYear: number;
  // Challenge info
  challenge?: Challenge;
  // Contacts of the challenge
  contacts: Array<Contact>;
  // temporary link to download uploaded rules
  rulesLink?: string;
  selectedTab: ChallengeTab;
  selectedModalTab: ChallengeModalTab;
  resultDetails?: ResultsFilterRowResponseType;
}

export type GetChallengeActionPayloadType = Challenge & { years: number[] };

export type ChallengeActionTypes =
  | {
      type: typeof ACTION_TYPES.GET_CHALLENGE;
      payload: GetChallengeActionPayloadType;
    }
  | {
      type: typeof ACTION_TYPES.SELECT_YEAR;
      payload: number;
    }
  | {
      type: typeof ACTION_TYPES.OPEN_STORE;
      payload: boolean;
    }
  | {
      type: typeof ACTION_TYPES.PUBLISH_RESULTS;
      payload: boolean;
    }
  | {
      type: typeof ACTION_TYPES.CREATE_CHALLENGE;
      payload: GetChallengeActionPayloadType;
    }
  | {
      type: typeof ACTION_TYPES.UPDATE_CHALLENGE;
      payload: GetChallengeActionPayloadType;
    }
  | {
      type: typeof ACTION_TYPES.SET_CHALLENGE_RULES_URL;
      payload: string;
    }
  | {
      type: typeof ACTION_TYPES.DELETE_QUEST;
      // questId
      payload: string;
    }
  | {
      type: typeof ACTION_TYPES.CREATE_QUEST;
      payload: Challenge;
    }
  | {
      type: typeof ACTION_TYPES.EDIT_QUEST;
      payload: Challenge;
    }
  | {
      type: typeof ACTION_TYPES.UPDATE_CHALLENGE_TAB;
      payload: ChallengeTab;
    }
  | {
      type: typeof ACTION_TYPES.UPDATE_CHALLENGE_MODAL_TAB;
      payload: ChallengeModalTab;
    }
  | {
      type: typeof ACTION_TYPES.GET_CHALLENGE_CONTACTS;
      payload: Array<Contact>;
    }
  | {
      type: typeof ACTION_TYPES.RESULT_DETAILS;
      payload: ResultsFilterRowResponseType;
    }
  | {
      type: typeof ACTION_TYPES.ADD_CONTACT;
      payload: AddContactRequest;
    };
