import React from "react";
import { CheckCircle, BarChart2 } from "react-feather";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { FieldArray, useField } from "formik";

import { QuestType } from "types/common.types";
import { QuestGoalType } from "enums/type.enums";

interface Props {
  quest: QuestType;
  debouncedSubmitForm: () => void;
}

const ResultsFormFiltersQuestItem: React.FC<Props> = ({ quest, debouncedSubmitForm }) => {
  const [fieldDoneQuestIds] = useField({ name: "doneQuestIdsFilter" });
  const [fieldOnGoingQuestIds] = useField({ name: "onGoingQuestIdsFilter" });
  const isProgressive = quest.type === QuestGoalType.PROGRESSIVE;
  const isUnitary = quest.type === QuestGoalType.UNITARY;

  return (
    <ListItem key={quest.id}>
      <ListItemIcon>
        {isProgressive && <BarChart2 />}
        {isUnitary && <CheckCircle />}
      </ListItemIcon>
      <div>
        <Typography>{quest.name}</Typography>
        <div>
          <FormControlLabel
            control={
              <FieldArray
                name="onGoingQuestIdsFilter"
                render={(arrayHelpers) => (
                  <Checkbox
                    color="primary"
                    checked={fieldOnGoingQuestIds.value.includes(quest.id)}
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        arrayHelpers.push(quest.id);
                      } else {
                        const idIndex = fieldOnGoingQuestIds.value.indexOf(quest.id);

                        if (idIndex !== -1) {
                          arrayHelpers.remove(idIndex);
                        }
                      }
                      debouncedSubmitForm();
                    }}
                  />
                )}
              />
            }
            label={
              <Typography variant="body2">
                <FormattedMessage id="form.results.filter.reached.no" />
              </Typography>
            }
            labelPlacement="end"
          />
          <FormControlLabel
            control={
              <FieldArray
                name="doneQuestIdsFilter"
                render={(arrayHelpers) => (
                  <Checkbox
                    color="primary"
                    checked={fieldDoneQuestIds.value.includes(quest.id)}
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        arrayHelpers.push(quest.id);
                      } else {
                        const idIndex = fieldDoneQuestIds.value.indexOf(quest.id);

                        if (idIndex !== -1) {
                          arrayHelpers.remove(idIndex);
                        }
                      }
                      debouncedSubmitForm();
                    }}
                  />
                )}
              />
            }
            label={
              <Typography variant="body2">
                <FormattedMessage id="form.results.filter.reached.yes" />
              </Typography>
            }
            labelPlacement="end"
          />
        </div>
      </div>
    </ListItem>
  );
};

export default ResultsFormFiltersQuestItem;
