import { ContactType } from "enums/type.enums";

const contact = {
  "contact.email": "Email",
  "contact.name": "Nom",
  "contact.accounts": "Comptes représentés",
  "contact.total": "{count} participants",
  [`contact.${ContactType.CG}`]: "Coordinateur VO",
  [`contact.${ContactType.CVVO}`]: "Chef des ventes VO",
  [`contact.${ContactType.CVVOMULTI}`]: "Chef des ventes VO multi-site",
};

export default contact;
