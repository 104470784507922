import React from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";

import { AccountsFilterResponseType } from "types/form.types";

import AccountsTable from "./AccountsTable";

const GridContainer = styled(Grid)`
  max-height: 750px;
`;

interface Props {
  results: AccountsFilterResponseType;
  isLoading: boolean;
}

const AccountsBody: React.FC<Props> = ({ results, isLoading }) => (
  <GridContainer item container spacing={2}>
    <AccountsTable isLoading={isLoading} results={results} />
  </GridContainer>
);

export default AccountsBody;
