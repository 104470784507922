import React, { useEffect, useState } from "react";
import { styled as muiStyled } from "@material-ui/core/styles";
import { Container as MuiContainer, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import actions from "store/actions";
import { RootState } from "store/types";
import AbsoluteLoader from "components/loader/AbsoluteLoader";

import { selectYear } from "store/challenge/challenge.actions";
import SelectYear from "components/selects/SelectYear";
import ImportsUploadInputSide from "./ImportsUploadInputSide";
import ImportsUploadAnalysisSide from "./ImportsUploadAnalysisSide";

const ContainerNoChallenge = styled.div`
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

const Container = muiStyled(MuiContainer)({
  flex: 1,
  marginBottom: 20,
});

const ImportsUpload: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoadingChallenge, setIsLoadingChallenge] = useState<boolean>();
  const [previewResult, setPreviewResult] = useState<any>();
  const getChallenge = (y: number) => dispatch(actions.challenge.getChallenge(y));

  const { challenge, years } = useSelector((state: RootState) => state.challenge);
  const year = useSelector((state: RootState) => state.challenge.selectedYear);

  useEffect(() => {
    (async function auth() {
      try {
        setIsLoadingChallenge(true);
        await getChallenge(year);
      } finally {
        setIsLoadingChallenge(false);
      }
    })();
  }, [year]);

  return isLoadingChallenge ? (
    <AbsoluteLoader />
  ) : (
    <Container>
      <SelectYear years={years} year={year} setYear={(y) => dispatch(selectYear(y))} paddingRight={6} />
      {challenge?.id ? (
        <Grid container>
          <ImportsUploadInputSide challengeId={challenge?.id} setPreviewResult={setPreviewResult} />
          <ImportsUploadAnalysisSide previewResult={previewResult} />
        </Grid>
      ) : (
        <ContainerNoChallenge>
          <Typography variant="h4">
            <FormattedMessage id="screen.challenge.no.challenge" values={{ year }} />
          </Typography>
        </ContainerNoChallenge>
      )}
    </Container>
  );
};
export default ImportsUpload;
