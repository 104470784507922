import React from "react";
import styled from "styled-components";

import { fade, withStyles } from "@material-ui/core/styles";
import { Search as SearchIcon } from "react-feather";
import MuiInputBase from "@material-ui/core/InputBase";
import { spacing } from "assets/styles/spacing";
import { useIntl } from "react-intl";

const Search = styled.div`
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
  background-color: ${fade("#fff", 0.6)};
  &:hover {
    background-color: ${fade("#fff", 0.85)};
  }
  padding: 0 ${spacing(2)}px;
  width: 100%;
`;

const SearchIconContainer = styled.div`
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputBase = withStyles({
  root: {
    color: "black",
    width: "100%",
  },
  input: {
    padding: `${spacing(2)}px ${spacing(2)}px ${spacing(2)}px 0`,
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${spacing(4)}px)`,
    width: "100%",
  },
})(MuiInputBase);

interface Props {
  onChange?: (e: any) => any;
  value?: string;
  placeholderIntlId?: string;
}

const SearchBar: React.FunctionComponent<Props> = ({ onChange, value, placeholderIntlId = "form.search" }) => {
  const intl = useIntl();

  return (
    <Search>
      <SearchIconContainer>
        <SearchIcon color="black" />
      </SearchIconContainer>
      <InputBase
        value={value}
        onChange={onChange}
        placeholder={intl.formatMessage({ id: placeholderIntlId })}
        inputProps={{ "aria-label": "search" }}
      />
    </Search>
  );
};

export default SearchBar;
