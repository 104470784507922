import React from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";

import { StoreItemType } from "types/common.types";
import Dropzone from "components/dropzone/Dropzone";
import { spacing } from "assets/styles/spacing";

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 94%;
  height: 96%;
`;

const ContainerDropzone = styled.div`
  color: white;
`;

const ContainerImage = styled.div`
  margin-top: ${spacing(2)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

const ImgWide = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

interface Props {
  product: StoreItemType;
}

const StoreManagementProductRightPanel: React.FC<Props> = ({ product }) => {
  const { setFieldValue } = useFormikContext();

  const onChangeImageFile = (files: any[]) => {
    const file = files?.[0];

    if (file) {
      setFieldValue("file", file);
    }
  };

  return (
    <Container>
      <ContainerDropzone>
        <Dropzone name="file" onChange={onChangeImageFile} minHeight={60} accept="image/*" />
      </ContainerDropzone>
      <ContainerImage>
        <ImgWide src={product.photo} alt="product" />
      </ContainerImage>
    </Container>
  );
};

export default StoreManagementProductRightPanel;
