const importation = {
  "importation.table.title": "Historique des importations",
  "importation.table.column.header.date": "Date de l'importation",
  "importation.table.column.header.type": "Type de donnée",
  "importation.table.column.header.lineCount": "Nombre de lignes",
  "importation.table.column.header.filename": "Nom du fichier",
  "importation.upload.inputSide.title.filename": "Importer vos données",
  "importation.upload.analysisSide.title.dataType": "Type de données",
  "importation.upload.analysisSide.title.lineCount": "Nombre de lignes",
  "importation.upload.analysisSide.title.preview": "Prévisualisation",
  "importation.upload.table.column.header.company": "Affaire",
  "importation.upload.table.column.header.firstname": "Prénom",
  "importation.upload.table.column.header.lastname": "Nom",
  "importation.upload.table.column.header.account": "Nom du compte",
  "importation.upload.table.column.header.type": "Type",
  "importation.upload.table.column.header.result": "Résultat",
  "importation.upload.table.column.header.adjudication": "Adjudication",
  "importation.upload.table.column.header.gamut": "Gamme",
  "importation.upload.table.column.header.productName": "Nom du produit",
  "importation.upload.table.column.header.brand": "Marque",
  "importation.upload.table.column.header.category": "Catégorie",
  "importation.upload.table.column.header.quest": "Défi",
  "importation.upload.table.column.header.realization": "Réalisation",
  "importation.upload.table.row.result.won": "Gagné",
  "importation.upload.table.row.result.lost": "Perdu",
  "importation.upload.dropzone.empty": "Déposez ici votre fichier à importer, ou cliquez",
  "importation.upload.dropzone.file.invalid": "Le fichier est invalide",
  "importation.upload.dropzone.file.accepted": "Le fichier est chargé",
  "importation.upload.button.analyze": "Analyser",
  "importation.upload.button.import": "Importer",
  "importation.export.media.extensions": ".zip contenant des .png",
  "importation.nav.menu.history": "Historique",
  "importation.nav.menu.files": "Fichiers",
  "importation.preview.errors": "Erreurs :",
  "importation.ws.CHECKING_DATA_CONSISTENCY": "Calcul de la cohérence des données",
  "importation.ws.READING_ROWS": "Lecture des lignes",
  "importation.ws.UPLOADING_TO_STORAGE": "Upload en cours",
  "importation.ws.SAVING": "Sauvegarde des données en base",
};

export default importation;
