import React from "react";
import { styled as muiStyled } from "@material-ui/core/styles";
import MuiModal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { Grow, Paper as MuiPaper, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import { spacing } from "assets/styles/spacing";
import AbsoluteLoader from "components/loader/AbsoluteLoader";

const Modal = muiStyled(MuiModal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Paper = muiStyled(MuiPaper)({
  "&:focus": {
    outline: "none",
  },
  position: "relative",
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
});

interface Props {
  open?: boolean;
  loading?: boolean;
  onClose?: () => any;
  children?: any;
  title: string;
  titleIntlValues?: any;
  titleVariant?: string;
}

const TransitionsModal: React.FunctionComponent<Props> = ({
  open,
  onClose,
  children,
  title,
  titleIntlValues = {},
  titleVariant = "h3",
  loading,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Grow in={open}>
      <Paper>
        <Typography variant={titleVariant}>
          <FormattedMessage id={title} values={titleIntlValues} />
        </Typography>
        {loading && <AbsoluteLoader />}
        {children}
      </Paper>
    </Grow>
  </Modal>
);

export default TransitionsModal;
