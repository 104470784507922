import React from "react";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { spacing } from "assets/styles/spacing";
import Modal from "./Modal";

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  & > button {
    margin-left: ${spacing(2)}px;
  }
`;

interface Props {
  open?: boolean;
  loading?: boolean;
  onClose?: () => any;
  onConfirm?: () => any;
  title: string;
}

const ConfirmationModal: React.FunctionComponent<Props> = ({ open, loading, onClose, onConfirm, title }) => (
  <Modal open={open} loading={loading} title={title} onClose={onClose}>
    <Row>
      <Button variant="outlined" onClick={onClose}>
        <FormattedMessage id="form.cancel" />
      </Button>
      <Button variant="contained" color="primary" onClick={onConfirm}>
        <FormattedMessage id="form.submit" />
      </Button>
    </Row>
  </Modal>
);

export default ConfirmationModal;
