import { Dispatch } from "redux";

import Websocket from "./websocket";
import { ACTION_TYPES_LISTEN_IMPORT, WebsocketActionTypes } from "./websocket.types";
import { initialState } from "./websocket.reducer";

export const listenImport = (): any => async (dispatch: Dispatch<WebsocketActionTypes>) =>
  Websocket.subscribe("/contact/queue/importUpdate", (message: any) => {
    const response = JSON.parse(message.body);
    const { payload } = response;

    if (payload && payload.index && payload.status) {
      const { index, status } = payload;
      dispatch({
        type: ACTION_TYPES_LISTEN_IMPORT,
        payload: {
          index,
          status,
        },
      });
    }
  });

export const resetImportProgress = (): WebsocketActionTypes => ({
  type: ACTION_TYPES_LISTEN_IMPORT,
  payload: initialState,
});

export const setImportToDone = (): WebsocketActionTypes => ({
  type: ACTION_TYPES_LISTEN_IMPORT,
  payload: {
    index: 9,
    status: "DONE",
  },
});

export const removeListenerImport = async (): Promise<any> => {
  await Websocket.unsubscribe("/contact/queue/importUpdate");
};
