import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { styled as muiStyled, withStyles } from "@material-ui/core/styles";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { TextField } from "formik-material-ui";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import Modal from "components/modal/Modal";
import { spacing } from "assets/styles/spacing";
import api from "api";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import { ServerSuccesses } from "enums/server.enums";
import ButtonLoading from "components/buttons/ButtonLoading";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  max-width: 400px;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  & > button {
    margin-left: ${spacing(2)}px;
  }
`;

const Body = muiStyled(Typography)({
  marginTop: spacing(4),
  maxWidth: 350,
});

const FieldEmail = withStyles({
  root: {
    flex: 1,
    margin: `${spacing(10)}px 0`,
  },
})(Field);

const ButtonContainer = styled.div`
  margin-right: ${spacing(3)}px;
`;

type FormValuesType = {
  email: string;
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalForgottenPassword: React.FC<IProps> = ({ isOpen, onClose }) => {
  const intl = useIntl();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, askResetPasswordWithSnackbar] = useSnackbarPromiseHandler(async ({ email }: FormValuesType) => {
    const token = await executeRecaptcha("ASK_RESET_PASSWORD");

    await api.auth.askResetPassword({ login: email, token });
    onClose();
  }, `server.success.${ServerSuccesses.EMAIL_SUCCESS}`);

  const validationSchema = object().shape({
    email: string()
      .email(intl.formatMessage({ id: "form.error.email.regex" }))
      .required(intl.formatMessage({ id: "form.error.field.mandatory" })),
  });

  return (
    <Modal open={isOpen} onClose={onClose} title="screen.auth.form.forgottenPassword.modal.title" titleVariant="h5">
      <Container>
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={askResetPasswordWithSnackbar}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }: any) => (
            <Form onSubmit={handleSubmit}>
              <Body variant="body2">
                <FormattedMessage id="screen.auth.form.forgottenPassword.modal.body" />
              </Body>
              <Grid item container xs={12} direction="column">
                <Grid item container xs={12}>
                  <FieldEmail
                    type="text"
                    id="email"
                    name="email"
                    component={TextField}
                    variant="filled"
                    label={intl.formatMessage({ id: "screen.auth.form.email.label" })}
                    inputProps={{ placeholder: "jean.dupont@renault.fr" }}
                  />
                </Grid>
              </Grid>
              <Buttons>
                <ButtonContainer>
                  <Button variant="outlined" onClick={onClose}>
                    <FormattedMessage id="form.cancel" />
                  </Button>
                </ButtonContainer>
                <ButtonLoading isLoading={isLoading} variant="contained" color="primary" type="submit">
                  <FormattedMessage id="form.submit" />
                </ButtonLoading>
              </Buttons>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

export default ModalForgottenPassword;
