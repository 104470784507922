import React from "react";
import styled from "styled-components";
import { Box, Grid, IconButton, Tooltip, MenuItem as MuiMenuItem, Popover } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import { Database, ShoppingCart, BarChart2, LogOut } from "react-feather";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import logo from "assets/images/logo-rvo-club-des-100.png";
import { spacing } from "assets/styles/spacing";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import { RootState } from "store/types";

import MenuItem from "./MenuItem";

const RightBlock = muiStyled(Grid)({
  "& > *": {
    marginLeft: spacing(6),
  },
});

const Sticky = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  background-color: #424242;
  z-index: 100;
  padding: ${spacing(3)}px ${spacing(6)}px;
`;

const Logo = styled.img`
  height: 60px;
`;

const HomeLink = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
`;

const ContainerNavMenuButton = muiStyled(Box)({
  display: "flex",
  alignItems: "center",
  "& > *": {
    paddingLeft: spacing(2),
  },

  "&:hover > *": {
    fontWeight: "bold",
    strokeWidth: 3,
    cursor: "pointer",
  },
});

const Text = styled.span`
  font-size: 18px;
  color: white;
  line-height: 0;
`;

const IconButtonHover = muiStyled(IconButton)({
  "&:hover > * > svg": {
    strokeWidth: 3,
  },
});

const onClickDisconnect = () => {
  localStorage.setItem(LOCAL_STORAGE.REDIRECT_URL, window.location.pathname);
  localStorage.removeItem(LOCAL_STORAGE.API_TOKEN);
  window.dispatchEvent(new CustomEvent("resetAuth"));
};

const items = [
  {
    icon: ShoppingCart,
    url: "routes.store.management",
    key: "common.header.store",
  },
];

type MenuType = "challenge" | "imports";

const Header = () => {
  const intl = useIntl();
  const history = useHistory();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuType, setMenuType] = React.useState<MenuType>();

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openChallengeMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    openMenu(event);
    setMenuType("challenge");
  };

  const openImportsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    openMenu(event);
    setMenuType("imports");
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const navigate = (intlId: string) => {
    closeMenu();
    history.push(intl.formatMessage({ id: intlId }));
  };

  return (
    <Sticky>
      <Grid item container justify="space-between" xs={12}>
        <Grid item container alignItems="center" xs={12} sm={3} md={4} lg={5} xl={6} justify="flex-start">
          <HomeLink to="/">
            <Logo src={logo} alt="logo-rvo-club-des-100" />
          </HomeLink>
        </Grid>
        <RightBlock item container alignItems="center" xs={12} sm={9} md={8} lg={7} xl={6} justify="flex-end">
          <ContainerNavMenuButton display="flex" flexDirection="row" onClick={openChallengeMenu}>
            <BarChart2 color="white" size={26} />
            <Text>
              <FormattedMessage id="common.header.challenge" />
            </Text>
          </ContainerNavMenuButton>
          {items.map((i) => (
            <MenuItem key={i.key} translationKey={i.key} Icon={i.icon} url={intl.formatMessage({ id: i.url })} />
          ))}
          <ContainerNavMenuButton display="flex" flexDirection="row" onClick={openImportsMenu}>
            <Database color="white" size={24} />
            <Text>
              <FormattedMessage id="common.header.import" />
            </Text>
          </ContainerNavMenuButton>
          {isAuthenticated && (
            <Tooltip title={intl.formatMessage({ id: "screen.account.button.logout" })}>
              <IconButtonHover onClick={onClickDisconnect}>
                <LogOut />
              </IconButtonHover>
            </Tooltip>
          )}
        </RightBlock>
      </Grid>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        autoFocus={false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {(() => {
          switch (menuType) {
            case "challenge":
              return (
                <>
                  <MuiMenuItem onClick={() => navigate("routes.challenge")}>
                    <FormattedMessage id="common.header.challenge.management" />
                  </MuiMenuItem>
                  <MuiMenuItem onClick={() => navigate("routes.results")}>
                    <FormattedMessage id="common.header.challenge.results" />
                  </MuiMenuItem>
                </>
              );
            case "imports":
              return (
                <>
                  <MuiMenuItem onClick={() => navigate("routes.imports.history")}>
                    <FormattedMessage id="importation.nav.menu.history" />
                  </MuiMenuItem>
                  <MuiMenuItem onClick={() => navigate("routes.imports.upload")}>
                    <FormattedMessage id="importation.nav.menu.files" />
                  </MuiMenuItem>
                </>
              );
            default:
              return null;
          }
        })()}
      </Popover>
    </Sticky>
  );
};

export default Header;
