import React, { useEffect, useState } from "react";
import { Box, Chip, Grid, Grow, Paper, TableContainer as MuiTableContainer, Tooltip, Typography } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import { FormattedMessage, useIntl } from "react-intl";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import styled from "styled-components";
import { AlertCircle, BarChart2, CheckCircle } from "react-feather";

import { spacing } from "assets/styles/spacing";
import { QuestGoalType } from "enums/type.enums";
import theme from "theme";
import { AccountsFilterResponseType, AccountsFilterRowResponseType } from "types/form.types";
import AbsoluteLoader from "components/loader/AbsoluteLoader";
import { useDispatch } from "react-redux";
import actions from "store/actions";
import { AccountQuestType } from "types/common.types";
import { formatQuestResult, isAccountQuestWon } from "utils/format.utils";

const PaperWide = muiStyled(Paper)({
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  maxWidth: "100%",
  boxSizing: "border-box",
});

const Title = styled.div`
  display: flex;
  width: 100%;
  margin: ${spacing(3)}px 0;
`;

const ColumnIconContainer = muiStyled(Box)({
  marginRight: 8,
});

const TableContainer = muiStyled(MuiTableContainer)({
  flex: 1,
  overflow: "auto",
});

const TableRowSelectable = muiStyled(TableRow)({
  cursor: "pointer",
});

const GridContainer = styled(Grid)`
  height: 100%;
`;

const ChipResultWon = muiStyled(Chip)({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.success.light,
});

const ChipResultInProgress = muiStyled(Chip)({
  color: theme.palette.background.default,
  backgroundColor: theme.palette.warning.light,
});

interface Column {
  id: "group" | "name" | "contact" | "noContact" | string;
  label?: string;
  minWidth?: number;
  width?: number;
  align?: "right";
  type?: string;
}

interface Props {
  results: AccountsFilterResponseType;
  isLoading: boolean;
}

const AccountsTable: React.FC<Props> = ({ results, isLoading }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [columns, setColumns] = useState<Column[]>([]);

  const accountsWithQuests = results.filter((r) => r.quests.length > 0);
  const progressiveQuests = accountsWithQuests[0]?.quests.filter((quest) => quest.questType === QuestGoalType.PROGRESSIVE);
  const unitaryQuests = accountsWithQuests[0]?.quests.filter((quest) => quest.questType === QuestGoalType.UNITARY);

  useEffect(() => {
    if (results.length === 0) return;

    const progressiveQuestsColumns =
      progressiveQuests !== undefined
        ? progressiveQuests.map((quest: AccountQuestType) => ({
            id: String(quest.questId),
            label: quest.name,
            type: quest.questType,
          }))
        : [];

    const unitaryQuestsColumns =
      unitaryQuests !== undefined
        ? unitaryQuests.map((quest: AccountQuestType) => ({
            id: String(quest.questId),
            label: quest.name,
            type: quest.questType,
          }))
        : [];

    setColumns([
      {
        id: "noContact",
        label: undefined,
        width: 0,
      },
      {
        id: "name",
        label: intl.formatMessage({ id: "accounts.table.column.label.name" }),
      },
      {
        id: "group",
        label: intl.formatMessage({ id: "accounts.table.column.label.group" }),
      },
      {
        id: "contacts",
        label: intl.formatMessage({ id: "accounts.table.column.label.contacts" }),
      },
      ...progressiveQuestsColumns,
      ...unitaryQuestsColumns,
    ]);
  }, [results]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  return (
    <GridContainer
      item
      container
      xs={12}
      style={{
        transition: theme.transitions.create("all", {
          easing: theme.transitions.easing.sharp,
          duration: 500,
        }),
      }}
    >
      {isLoading && <AbsoluteLoader />}
      <Grow in>
        <PaperWide>
          <Title>
            {isLoading ? (
              <Typography variant="h4">
                <FormattedMessage id="common.loading" />
              </Typography>
            ) : (
              <Typography variant="h4">
                <FormattedMessage id="results.table.title" values={{ count: results.length }} />
              </Typography>
            )}
          </Title>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => {
                    // no minWidth on last item
                    const minWidth = index === columns.length - 1 ? 0 : 100;
                    const isProgressive = column.type === QuestGoalType.PROGRESSIVE;
                    const isUnitary = column.type === QuestGoalType.UNITARY;

                    return (
                      <TableCell key={column.id} align="left" style={{ minWidth: column.width ?? minWidth, maxWidth: column.width ?? 200 }}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          {(isProgressive || isUnitary) && (
                            <ColumnIconContainer>
                              {isProgressive && <BarChart2 />}
                              {isUnitary && <CheckCircle />}
                            </ColumnIconContainer>
                          )}
                          {column?.label}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {results &&
                  results
                    .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                    .map((row: AccountsFilterRowResponseType) => (
                      <TableRowSelectable
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => {
                          dispatch(actions.accounts.setAccountDetails(row));
                        }}
                      >
                        {columns.map((column: Column): React.ReactElement => {
                          switch (column.id) {
                            case "noContact": {
                              return (
                                <TableCell key={column.id} align="center" width="30px">
                                  <Box display="flex" flexDirection="column" justifyContent="center">
                                    <Box display="flex" m="auto">
                                      {row?.contacts?.length === 0 && (
                                        <Tooltip title={intl.formatMessage({ id: "results.table.column.item.noContact" })}>
                                          <AlertCircle style={{ stroke: theme.palette.primary.main }} />
                                        </Tooltip>
                                      )}
                                    </Box>
                                  </Box>
                                </TableCell>
                              );
                            }

                            case "name": {
                              return (
                                <TableCell key={column.id} align="left">
                                  {row.name}
                                </TableCell>
                              );
                            }

                            case "group": {
                              return (
                                <TableCell key={column.id} align="left">
                                  {row.group}
                                </TableCell>
                              );
                            }

                            case "contacts": {
                              return (
                                <TableCell key={column.id} align="left">
                                  {row?.contacts?.length === 1 && row.contacts[0].name}
                                  {row?.contacts?.length === 0 && <FormattedMessage id="results.table.column.item.noAccounts" />}
                                  {row?.contacts?.length > 1 && <FormattedMessage id="results.table.column.item.severalAccounts" />}
                                </TableCell>
                              );
                            }

                            default: {
                              const quest: AccountQuestType = row.quests.find(
                                (curQuest: AccountQuestType) => String(curQuest.questId) === column.id
                              );

                              if (!quest) {
                                return (
                                  <TableCell>
                                    <ChipResultInProgress
                                      label={intl.formatMessage({ id: "results.table.column.item.quest.noParticipation" })}
                                    />
                                  </TableCell>
                                );
                              }

                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {isAccountQuestWon(quest) ? (
                                    <ChipResultWon label={formatQuestResult(quest.done, quest.goal, quest.questType)} />
                                  ) : (
                                    <ChipResultInProgress label={formatQuestResult(quest.done, quest.goal, quest.questType)} />
                                  )}
                                </TableCell>
                              );
                            }
                          }
                        })}
                      </TableRowSelectable>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={results.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={intl.formatMessage({ id: "common.table.rowsPerPage" })}
            labelDisplayedRows={({ from, to, count }) =>
              intl.formatMessage(
                { id: "common.table.DisplayedRows" },
                {
                  from,
                  to,
                  count,
                }
              )
            }
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </PaperWide>
      </Grow>
    </GridContainer>
  );
};

export default AccountsTable;
