export const ACTION_TYPES_LISTEN_IMPORT = "LISTEN_IMPORT";

export interface WebsocketState {
  index: number;
  status: string;
}

export type WebsocketActionTypes = {
  type: typeof ACTION_TYPES_LISTEN_IMPORT;
  payload: WebsocketState;
};
