import React from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

import { spacing } from "assets/styles/spacing";
import CircularProgressWithLabel from "components/progression/CircularProgressWithLabel";

import { ContactsResultSummaryType } from "types/form.types";
import { ContactType } from "enums/type.enums";

import CardDoneOverGoal from "./cards/CardDoneOverGoal";

const PaperWide = muiStyled(Paper)({
  flex: 1,
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
});

const TypographyItalic = muiStyled(Typography)({
  fontStyle: "italic",
  textAlign: "right",
  marginTop: spacing(3),
});

interface Props {
  summary: ContactsResultSummaryType;
  title: React.ReactElement | string;
  caption: React.ReactElement | string;
}

const DashboardContactsSummary: React.FC<Props> = ({ title, summary, caption }) => {
  const intl = useIntl();

  return (
    <Grid item container xs={12}>
      <PaperWide>
        <Box display="flex" flexDirection="column">
          <Typography variant="h4" color="textPrimary">
            {title}
          </Typography>
          <Box mt={2} ml={2} mr={2}>
            <Grid container spacing={2} justify="space-evenly" alignItems="center">
              <Grid item container xs={2} direction="column">
                <Box mt={2} mb={2} width="100%" display="flex" flexDirection="column">
                  <CircularProgressWithLabel value={summary.percentage} thickness={9} />
                  <TypographyItalic variant="caption">{caption}</TypographyItalic>
                </Box>
              </Grid>
              <Grid item xs={1} /> {/* space holder */}
              <Grid item container xs={3}>
                <CardDoneOverGoal
                  title={intl.formatMessage({ id: `contact.${ContactType.CVVO}` })}
                  done={summary.contactsType.CVVO?.done}
                  goal={summary.contactsType.CVVO?.total}
                />
              </Grid>
              <Grid item container xs={3}>
                <CardDoneOverGoal
                  title={intl.formatMessage({ id: `contact.${ContactType.CVVOMULTI}` })}
                  done={summary.contactsType.CVVOMULTI?.done}
                  goal={summary.contactsType.CVVOMULTI?.total}
                />
              </Grid>
              <Grid item container xs={3}>
                <CardDoneOverGoal
                  title={intl.formatMessage({ id: `contact.${ContactType.CG}` })}
                  done={summary.contactsType.CG?.done}
                  goal={summary.contactsType.CG?.total}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </PaperWide>
    </Grid>
  );
};

export default DashboardContactsSummary;
