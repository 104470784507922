import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Plus } from "react-feather";
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import { Box } from "@material-ui/core";

import ChipsArray from "../chips/ChipsArray";
import PopoverTextField from "./PopoverTextField";

interface Props {
  label: string | React.ReactElement;
  name: string;
}

const FieldAddTextToList: React.FC<Props> = ({ label, name }) => {
  const [field] = useField(name);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  const handleAdd = (textToAdd: string, arrayHelpers: FieldArrayRenderProps) => {
    if (textToAdd) {
      arrayHelpers.push(textToAdd);
      closePopover();
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            <Box display="flex" alignItems="center">
              {label}
              <IconButton onClick={openPopover}>
                <Plus />
              </IconButton>
              <PopoverTextField
                open={isOpen}
                anchorEl={anchorEl}
                onClose={closePopover}
                onSubmit={(value: string) => handleAdd(value, arrayHelpers)}
              />
            </Box>
            <ChipsArray items={field.value} handleDeleteIndex={arrayHelpers.remove} />
          </>
        )}
      />
    </Box>
  );
};

export default FieldAddTextToList;
