import { QuestGoalType } from "../enums/type.enums";
import { AccountQuestType, QuestType } from "../types/common.types";

const formatQuestResult = (done: number, goal: number, type: QuestGoalType): string => {
  if (done === undefined) {
    return "-";
  }

  if (type === QuestGoalType.UNITARY) {
    return Number(done) === 1.0 ? "Oui" : "Non";
  }
  return goal ? `${done}/${goal}` : "-";
};

const isWon = (quest: QuestType): boolean => {
  if (quest.type === QuestGoalType.UNITARY) {
    if (!quest.reverse && Number(quest.done) === 1.0) {
      return true;
    }
    return quest.reverse && Number(quest.done) === 0;
  }

  if (!quest.reverse && quest.done >= quest.goal) {
    return true;
  }

  return quest.reverse && quest.done <= quest.goal;
};

const isAccountQuestWon = (quest: AccountQuestType): boolean => {
  if (quest.questType === QuestGoalType.UNITARY) {
    if (!quest.reverse && Number(quest.done) === 1.0) {
      return true;
    }
    return quest.reverse && Number(quest.done) === 0;
  }

  if (!quest.reverse && quest.done >= quest.goal) {
    return true;
  }

  return quest.reverse && quest.done <= quest.goal;
};

export { formatQuestResult, isWon, isAccountQuestWon };
