import React, { useState } from "react";
import { CheckCircle, BarChart2, Edit, Save } from "react-feather";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import { Box, LinearProgress, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";

import type { AccountQuestType, QuestType } from "types/common.types";
import { ChallengeTab, QuestGoalType, QuestsResults } from "enums/type.enums";
import { useField } from "formik";
import TableButton from "screens/results/components/TableButton";

interface Props {
  quest: QuestType | AccountQuestType;
  type: QuestGoalType;
  challengeTab?: ChallengeTab;
  resultId: string;
  submitForm: () => void;
}

const Container = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledTextField = styled(TextField)(() => ({
  "& .MuiInputBase-input": {
    fontSize: 12,
    marginLeft: 3,
    '&[type="number"]': {
      "-moz-appearance": "textfield",
    },
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
  },
}));

const ResultsPanelQuestItem: React.FC<Props> = ({ quest, type, challengeTab, resultId, submitForm }) => {
  const isProgressive = type === QuestGoalType.PROGRESSIVE;
  const isUnitary = type === QuestGoalType.UNITARY;

  const [{ value }, , { setValue }] = useField("results");

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const currQuest = () => {
    const currAccount = value.find((resultAccount: { resultId: string; quests: QuestType[] }) => resultAccount.resultId === resultId);
    return currAccount?.quests?.find((itQuest: AccountQuestType) =>
      "id" in quest ? itQuest?.questId === quest?.id : itQuest?.questId === quest?.questId
    );
  };

  const initialValue = (item: string) => (item === QuestsResults.DONE ? currQuest()?.done : currQuest()?.goal);

  const onChangeResult = (result: EventTarget & (HTMLInputElement | HTMLTextAreaElement)) => {
    const currAccount = value.find((resultAccount: { resultId: string; quests: QuestType[] }) => resultAccount.resultId === resultId);
    const filteredValues = currAccount?.quests?.filter((itQuest: AccountQuestType) =>
      "id" in quest ? itQuest?.questId !== quest?.id : itQuest?.questId !== quest?.questId
    );

    const { goal, done, questId } = currQuest();
    const newValuesQuest =
      result.name === QuestsResults.DONE
        ? { questId, done: result.value ? parseInt(result.value, 10) : "", goal, modified: true }
        : { questId, done, goal: result.value ? parseInt(result.value, 10) : "", modified: true };
    const newCurrResult = {
      resultId,
      quests: [...filteredValues, newValuesQuest],
      modified: true,
    };

    const filteredAccounts = value.filter(
      (resultAccount: { resultId: string; quests: QuestType[] }) => resultAccount.resultId !== resultId
    );
    setValue([...filteredAccounts, newCurrResult]);
  };

  return (
    <Container>
      <ListItem>
        <ListItemIcon>
          {isProgressive && <BarChart2 />}
          {isUnitary && <CheckCircle />}
        </ListItemIcon>
        <ContentContainer>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>{quest.name}</Typography>
            <TableButton>
              {isEditing ? (
                <Save
                  width={16}
                  onClick={() => {
                    setIsEditing(false);
                    submitForm();
                  }}
                />
              ) : (
                !(challengeTab === ChallengeTab.CONTACTS && quest.account) && <Edit width={16} onClick={() => setIsEditing(true)} />
              )}
            </TableButton>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress
                variant="determinate"
                value={Math.min((initialValue(QuestsResults.DONE) / initialValue(QuestsResults.GOAL)) * 100, 100)}
              />
            </Box>
            <Box>
              {isEditing ? (
                <Box width={70} display="flex">
                  <StyledTextField
                    fullWidth
                    type="number"
                    value={initialValue(QuestsResults.DONE)}
                    onChange={(event) => onChangeResult(event.target)}
                    name={QuestsResults.DONE}
                  />
                  <Typography variant="body2" color="textSecondary">
                    /
                  </Typography>
                  <StyledTextField
                    fullWidth
                    type="number"
                    value={initialValue(QuestsResults.GOAL)}
                    name={QuestsResults.GOAL}
                    onChange={(event) => onChangeResult(event.target)}
                  />
                </Box>
              ) : !initialValue(QuestsResults.GOAL) && !initialValue(QuestsResults.DONE) ? (
                <Typography variant="body2" color="textSecondary">
                  -
                </Typography>
              ) : (
                <Typography variant="body2" color="textSecondary">{`${initialValue(QuestsResults.DONE)}/${initialValue(
                  QuestsResults.GOAL
                )}`}</Typography>
              )}
            </Box>
          </Box>
        </ContentContainer>
      </ListItem>
    </Container>
  );
};

export default ResultsPanelQuestItem;
