import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { spacing } from "assets/styles/spacing";
import { Grid } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";

import AbsoluteLoader from "components/loader/AbsoluteLoader";
import { RootState } from "store/types";
import actions from "store/actions";
import { AccountsFilterFormType, ResultsFilterFormType } from "types/form.types";
import SelectYear from "components/selects/SelectYear";

import { selectYear, updateChallengeModalTab } from "store/challenge/challenge.actions";
import { ChallengeModalTab, ChallengeTab } from "enums/type.enums";
import api from "api/api";
import ResultsForm from "./form/ResultsForm";
import ResultsBody from "./body/ResultsBody";
import AccountsBody from "./accounts/AccountsBody";
import AccountModal from "./modal/Account.modal";
import ContactModal from "./modal/Contact.modal";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 ${spacing(10)}px;
  box-sizing: border-box;
  z-index: 10; // above background
  margin-bottom: 32px;
`;

const ContainerNoChallenge = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  color: white;
  font-size: 1.2rem;
  z-index: -1; // under ContainerSelectYear
`;

export type FormTypeResults = {
  search?: string;
  accountSearch?: string;
  isWonFilter: boolean;
  isLostFilter: boolean;
  isWonAdjudicationFilter: boolean;
  isLostAdjudicationFilter: boolean;
  doneQuestIdsFilter: string[];
  onGoingQuestIdsFilter: string[];
  registered: boolean;
  notRegistered: boolean;
  results: {
    resultId: string;
    quests: { questId: string; done: number | undefined; goal: number | undefined; modified?: boolean }[];
    modified?: boolean;
  }[];
};

const Results: React.FC = () => {
  const [isLoadingChallenge, setIsLoadingChallenge] = useState<boolean>(false);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);
  const dispatch = useDispatch();
  const results = useSelector((state: RootState) => state.results);
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const activeTab = useSelector((state: RootState) => state.challenge.selectedTab);
  const activeModalTab = useSelector((state: RootState) => state.challenge.selectedModalTab);
  const year = useSelector((state: RootState) => state.challenge.selectedYear);
  const accountDetails = useSelector((state: RootState) => state.accounts.accountDetails);
  const contactDetails = useSelector((state: RootState) => state.challenge.resultDetails);

  const getChallenge = (y: number) => dispatch(actions.challenge.getChallenge(y));

  const getResults = (challengeId: string, form: ResultsFilterFormType | {}) =>
    dispatch(actions.results.getChallengeResults(challengeId, form));

  const getAccountsResults = (challengeId: string, form: AccountsFilterFormType | {}) =>
    dispatch(actions.accounts.getAccountsResults(challengeId, form));

  const { challenge, years } = useSelector((state: RootState) => state.challenge);

  useEffect(() => {
    if (challenge?.id) {
      (async function getAllResultsEffect() {
        try {
          setIsLoadingResults(true);
          await getResults(challenge.id, {});
          await getAccountsResults(challenge.id, {});
        } finally {
          setIsLoadingResults(false);
        }
      })();
    }
  }, [challenge]);

  useEffect(() => {
    (async function auth() {
      try {
        setIsLoadingChallenge(true);
        await getChallenge(year);
      } finally {
        setIsLoadingChallenge(false);
      }
    })();
  }, [year]);

  useEffect(() => {
    if (challenge?.id) {
      (async function auth() {
        try {
          setIsLoadingChallenge(true);
          if (
            (activeTab === ChallengeTab.CONTACTS && activeModalTab !== ChallengeModalTab.ACCOUNT_MODAL) ||
            activeModalTab === ChallengeModalTab.CONTACT_MODAL
          ) {
            await getResults(challenge.id, {});
          }
          if (
            (activeTab === ChallengeTab.ACCOUNTS && activeModalTab !== ChallengeModalTab.CONTACT_MODAL) ||
            activeModalTab === ChallengeModalTab.ACCOUNT_MODAL
          ) {
            await getAccountsResults(challenge.id, {});
          }
        } finally {
          setIsLoadingChallenge(false);
        }
      })();
    }
  }, [activeTab]);

  const [initialValues, setInitialValues] = useState({
    search: undefined,
    accountSearch: undefined,
    isWonFilter: true,
    isLostFilter: true,
    isWonAdjudicationFilter: true,
    isLostAdjudicationFilter: true,
    doneQuestIdsFilter: [],
    onGoingQuestIdsFilter: [],
    registered: true,
    notRegistered: true,
    results: [],
  });

  const submit = async (formValues: FormTypeResults, formActions: any) => {
    const accountUpdate = formValues.results?.find((result) => result.modified);

    setInitialValues((prevState) => ({
      ...prevState,
      doneQuestIdsFilter: formValues.doneQuestIdsFilter,
      onGoingQuestIdsFilter: formValues.onGoingQuestIdsFilter,
    }));

    try {
      // remove values appearing in both done & onGoing arrays
      const doneQuestsUnique = formValues.doneQuestIdsFilter.filter(
        (doneQuestId) => !formValues.onGoingQuestIdsFilter.some((onGoingQuestId) => onGoingQuestId === doneQuestId)
      );
      const onGoingQuestsUnique = formValues.onGoingQuestIdsFilter.filter(
        (onGoingQuestId) => !formValues.doneQuestIdsFilter.some((doneQuestId) => doneQuestId === onGoingQuestId)
      );
      setIsLoadingResults(true);

      if (accountUpdate) {
        const questUpdate = accountUpdate?.quests?.find((quest) => quest.modified);
        if (activeModalTab && activeModalTab === ChallengeModalTab.ACCOUNT_MODAL) {
          await api.results.addResultsAccountChallenge(
            { challengeId: challenge.id, accountId: accountUpdate.resultId, questId: questUpdate.questId },
            { done: questUpdate.done ? questUpdate.done : 0, goal: questUpdate.goal ? questUpdate.goal : 0 }
          );
        } else {
          await api.results.addResultsContactChallenge(
            { challengeId: challenge.id, contactId: accountUpdate.resultId, questId: questUpdate.questId },
            { done: questUpdate.done ? questUpdate.done : 0, goal: questUpdate.goal ? questUpdate.goal : 0 }
          );
        }

        formActions.resetForm(initialValues);
      }

      if (
        (activeTab === ChallengeTab.CONTACTS && activeModalTab !== ChallengeModalTab.ACCOUNT_MODAL) ||
        activeModalTab === ChallengeModalTab.CONTACT_MODAL
      ) {
        await getResults(challenge.id, {
          q: formValues.search ? formValues.search : undefined,
          allQuestsCompleted: formValues.isLostFilter === formValues.isWonFilter ? undefined : formValues.isWonFilter,
          adjudication:
            formValues.isLostAdjudicationFilter === formValues.isWonAdjudicationFilter ? undefined : formValues.isWonAdjudicationFilter,
          onGoingQuests: challenge.quests.filter((quest) => onGoingQuestsUnique.includes(quest.id)).map((quest) => quest.slug),
          doneQuests: challenge.quests.filter((quest) => doneQuestsUnique.includes(quest.id)).map((quest) => quest.slug),
          registered: formValues.registered === formValues.notRegistered ? undefined : formValues.registered,
        });
      }

      if (
        (activeTab === ChallengeTab.ACCOUNTS && activeModalTab !== ChallengeModalTab.CONTACT_MODAL) ||
        activeModalTab === ChallengeModalTab.ACCOUNT_MODAL
      ) {
        await getAccountsResults(challenge.id, {
          q: formValues.accountSearch ? formValues.accountSearch : undefined,
        });
      }
    } finally {
      setIsLoadingResults(false);
    }
  };

  // const data = activeModalTab ? (activeModalTab === ChallengeModalTab.CONTACT_MODAL ? [contactDetails] : [accountDetails]) : results;

  const data = activeModalTab && activeModalTab === ChallengeModalTab.ACCOUNT_MODAL ? accounts : results;
  useEffect(() => {
    setInitialValues((prevState) => ({
      ...prevState,
      results: data.map((result) => ({
        resultId: result?.id,
        quests: result?.quests?.map((quest) => ({
          questId: "id" in quest ? quest.id : quest.questId,
          done: quest?.done,
          goal: quest?.goal,
        })),
      })),
    }));
  }, [results, accounts, accountDetails, contactDetails, activeTab, activeModalTab]);

  return (
    <Container>
      <Formik initialValues={initialValues} onSubmit={submit} enableReinitialize>
        {({ submitForm }) => (
          <Form>
            {isLoadingChallenge && <AbsoluteLoader />}
            <SelectYear years={years} year={year} setYear={(y) => dispatch(selectYear(y))} />
            {challenge?.id ? (
              <Grid container spacing={2}>
                <ResultsForm challenge={challenge} submitForm={submitForm} />
                {activeTab === ChallengeTab.CONTACTS && (
                  <ResultsBody results={results} isLoading={isLoadingResults} submitForm={submitForm} />
                )}
                {activeTab === ChallengeTab.ACCOUNTS && <AccountsBody results={accounts} isLoading={isLoadingResults} />}
              </Grid>
            ) : (
              <ContainerNoChallenge>
                <FormattedMessage id="screen.challenge.no.challenge" values={{ year }} />
              </ContainerNoChallenge>
            )}
            <AccountModal
              open={accountDetails !== undefined}
              onClose={() => {
                dispatch(actions.accounts.setAccountDetails(undefined));
                dispatch(updateChallengeModalTab(undefined));
              }}
              row={accountDetails}
              submitForm={submitForm}
              isLoadingResults={isLoadingResults}
            />
            <ContactModal
              open={contactDetails !== undefined}
              onClose={() => dispatch(actions.challenge.setResultDetails(undefined))}
              row={contactDetails}
              submitForm={submitForm}
              isLoading={isLoadingResults}
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Results;
