export const LOCAL_STORAGE = {
  /* Used to add header on each api call */
  API_TOKEN: "Authorization",
  /* Used to redirect after IDP login using url /login */
  REDIRECT_URL: "redirectUrl",
  /* Used to add header on each api call */
  AUTH_MODE: "x-authenticate-mode",
  AUTH_TYPE: "auth_type",
  PKCE: "pkce_state",
  PKCE_VERIFIER: "pkce_code_verifier",
};
