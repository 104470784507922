import { ResultsFilterRowResponseType } from "types/form.types";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import theme from "theme";
import { FormattedMessage } from "react-intl";
import React from "react";
import styled from "styled-components";
import { ToggleButtonProps } from "@material-ui/lab/ToggleButton";
import { colors } from "assets/styles/colors";

const ToggleButtonColor = styled(ToggleButton)`
  &.MuiToggleButton-root {
    max-height: 36px;
  }

  &.MuiToggleButton-root.Mui-selected {
    background-color: ${(
      props: ToggleButtonProps & {
        $activeBackgroundColor: string;
      }
    ) => props.$activeBackgroundColor};

    :hover {
      background-color: ${(
        props: ToggleButtonProps & {
          $activeBackgroundColor: string;
        }
      ) => props.$activeBackgroundColor};
    }
  }

  &.Mui-selected .MuiToggleButton-label {
    color: ${colors.black};
  }
`;

interface Props {
  row: ResultsFilterRowResponseType;
  handleChangeAdjudication: (
    accountId: string,
    event: React.ChangeEvent<{}>,
    newValue: boolean | undefined | null,
    row?: ResultsFilterRowResponseType
  ) => Promise<void>;
  isAuthModeCLUB: boolean;
}

const AdjudicationToggle: React.FC<Props> = ({ row, handleChangeAdjudication, isAuthModeCLUB }) => (
  <ToggleButtonGroup
    value={row.adjudication}
    exclusive
    onChange={(event: any, value: boolean) => handleChangeAdjudication(row.id, event, value, row)}
  >
    <ToggleButtonColor value={false} $activeBackgroundColor={theme.palette.error.light} disabled={row.ordered || isAuthModeCLUB}>
      <FormattedMessage id="results.table.filter.item.adjudication.lost" />
    </ToggleButtonColor>
    <ToggleButtonColor value={undefined} $activeBackgroundColor="inherit" disabled={row.ordered || isAuthModeCLUB}>
      -
    </ToggleButtonColor>
    <ToggleButtonColor value $activeBackgroundColor={theme.palette.success.light} disabled={row.ordered || isAuthModeCLUB}>
      <FormattedMessage id="results.table.filter.item.adjudication.won" />
    </ToggleButtonColor>
  </ToggleButtonGroup>
);

export default AdjudicationToggle;
