import { red } from "@material-ui/core/colors";

const colors = {
  yellowRenault: "#FFCC33",
  lightGrey: "#c7c7c7",
  black: "#333333",
  darkgrey: "#666666",
  grey: "#999999",
  red: red[400],
  bronze: "#CD7F32",
  silver: "#B3B3B3",
  gold: "#FFD966",
};

export { colors };
