import * as auth from "./auth/auth.actions";
import * as challenge from "./challenge/challenge.actions";
import * as i18n from "./i18n/i18n.actions";
import * as websocket from "./websocket/websocket.actions";
import * as results from "./results/results.actions";
import * as accounts from "./accounts/accounts.actions";

export const actions = {
  auth,
  challenge,
  i18n,
  websocket,
  results,
  accounts,
};

export default actions;
