import { combineReducers } from "redux";

import auth from "./auth/auth.reducer";
import challenge from "./challenge/challenge.reducer";
import i18n from "./i18n/i18n.reducer";
import websocket from "./websocket/websocket.reducer";
import results from "./results/results.reducer";
import accounts from "./accounts/accounts.reducer";

const reducer = combineReducers({
  auth,
  challenge,
  i18n,
  websocket,
  results,
  accounts,
});

export default reducer;
