import { LOCAL_STORAGE } from "utils/authentication-constants.utils";

import { ACTION_TYPES, AuthState, AuthActionTypes, AuthActionLoginType } from "./auth.types";

const initialState = {
  isAuthenticated: !!localStorage.getItem(LOCAL_STORAGE.API_TOKEN),
};

const authReducer = (state: AuthState = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_USER: {
      const { apiToken, authMode } = (action as AuthActionLoginType).payload;
      return { ...state, isAuthenticated: true, apiToken, authMode };
    }
    case ACTION_TYPES.AUTH_RESET:
      return {
        isAuthenticated: false,
        date: new Date(),
      };
    default:
      return state;
  }
};

export default authReducer;
