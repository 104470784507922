import { ACTION_TYPES_LISTEN_IMPORT, WebsocketState, WebsocketActionTypes } from "./websocket.types";

export const initialState: WebsocketState = {
  index: 0,
  status: "",
};

const websocketReducer = (state: WebsocketState = initialState, action: WebsocketActionTypes): WebsocketState => {
  switch (action.type) {
    case ACTION_TYPES_LISTEN_IMPORT:
      return action.payload;
    default:
      return state;
  }
};

export default websocketReducer;
