import React from "react";

interface IProps {
  color?: string;
  size?: number;
}

const USBKey: React.FC<IProps> = ({ color = "black", size = 24 }) => (
  <svg width={size} height={size} fill={color} viewBox="0 0 60 60">
    <g fillRule="nonzero">
      <path d="M14.973 60a14.873 14.873 0 01-10.587-4.386 14.972 14.972 0 010-21.174l20.9-20.9a3.068 3.068 0 014.237 0l16.938 16.937a3 3 0 010 4.237l-20.9 20.9A14.875 14.875 0 0114.972 60zm12.431-45.338a.99.99 0 00-.704.292l-20.9 20.9A12.972 12.972 0 1024.147 54.2l20.9-20.9a.997.997 0 000-1.409L28.109 14.954a.992.992 0 00-.705-.292zM47.871 28.655a1 1 0 01-.707-1.707L57.75 16.362a.91.91 0 00.249-.698 1.292 1.292 0 00-.39-.851L45.187 2.391a1.124 1.124 0 00-1.55-.14L33.052 12.836a1 1 0 11-1.414-1.414L42.224.836a3.104 3.104 0 014.378.141l12.42 12.421a3.29 3.29 0 01.975 2.17c.05.821-.253 1.624-.833 2.208L48.578 28.362a.997.997 0 01-.707.293z" />
      <path d="M44.342 30.773a.997.997 0 01-.707-.293L29.52 16.364a1 1 0 011.414-1.414l14.114 14.114a1 1 0 01-.707 1.707z" />
      <path d="M45.754 32.184a1 1 0 01-.707-.293L28.109 14.954a1 1 0 010-1.414l2.116-2.117a2.004 2.004 0 012.828 0l15.523 15.524c.78.781.78 2.047 0 2.828l-2.115 2.116a1 1 0 01-.707.293zM30.23 14.247L45.754 29.77l1.408-1.409L31.64 12.837zM44.342 12.01c-.53 0-1.04-.21-1.414-.587l-1.408-1.408a2.001 2.001 0 010-2.829l1.408-1.408a1.987 1.987 0 011.414-.586h.002c.53-.001 1.04.21 1.413.587l1.408 1.407c.78.782.78 2.047 0 2.829l-1.41 1.408a1.983 1.983 0 01-1.411.586zm0-2l1.409-1.41-1.41-1.408L42.935 8.6zM51.4 19.065c-.53.001-1.04-.209-1.415-.584l-1.409-1.409a2.004 2.004 0 010-2.827l1.41-1.41a2.001 2.001 0 012.827 0l1.41 1.409a2 2 0 010 2.828l-1.41 1.409a1.995 1.995 0 01-1.414.584zm0-1.999v.001l1.409-1.41-1.41-1.408-1.409 1.409zM11.976 55.079a7.11 7.11 0 01-4.768-2.287c-2.375-2.376-2.994-5.477-1.411-7.06 1.58-1.583 4.684-.965 7.06 1.411 2.375 2.375 2.995 5.476 1.412 7.06a3.159 3.159 0 01-2.293.876zM8.09 46.852a1.218 1.218 0 00-.88.294c-.665.665-.203 2.617 1.411 4.231s3.566 2.076 4.232 1.412c.665-.666.204-2.617-1.41-4.232a5.26 5.26 0 00-3.353-1.705zM16.818 46.176a2.994 2.994 0 112.118-.876 2.989 2.989 0 01-2.118.876zm0-3.99a.996.996 0 10.704 1.7.996.996 0 00-.704-1.7zM22.464 32.062a2.995 2.995 0 11.004-5.99 2.995 2.995 0 01-.004 5.99zm0-3.991a.996.996 0 100 1.992.996.996 0 000-1.992zM33.05 38.414a2.995 2.995 0 11.004-5.99 2.995 2.995 0 01-.004 5.99zm0-3.992a.996.996 0 100 1.992.996.996 0 000-1.992z" />
      <path d="M18.23 42.77a1 1 0 01-.707-1.707l13.896-13.896-.29.058a1 1 0 11-.392-1.961l3.529-.706a1 1 0 01.903 1.687L18.937 42.477a.997.997 0 01-.707.293z" />
      <path d="M33.757 30.067a1 1 0 01-.981-1.197l.705-3.529a1 1 0 011.961.393l-.706 3.529a1 1 0 01-.98.804zM21.76 39.241a1 1 0 01-.895-.553l-2.824-5.645a1 1 0 01.188-1.154l2.117-2.117a1 1 0 111.414 1.414l-1.608 1.607 2.501 5a1 1 0 01-.893 1.448zM29.521 39.947c-.155 0-.308-.036-.447-.105l-5.645-2.823a1 1 0 01.894-1.79l5.001 2.502 1.608-1.608a1 1 0 011.414 1.414l-2.117 2.117a1.001 1.001 0 01-.707.293z" />
    </g>
  </svg>
);

export default USBKey;
