import React from "react";
import { useIntl } from "react-intl";

import { storeItemGamutToColor } from "utils/colors.utils";
import { StoreItemGamut } from "enums/type.enums";
import ColorChip from "components/chips/ColorChip";

interface Props {
  gamut: StoreItemGamut;
}

const GamutChip: React.FC<Props> = ({ gamut }) => {
  const intl = useIntl();

  return <ColorChip color={storeItemGamutToColor(gamut)} tooltipText={intl.formatMessage({ id: `gamut.${gamut}` })} />;
};

export default GamutChip;
