import React, { FunctionComponent, useEffect } from "react";
import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { BrowserRouter as Router, withRouter } from "react-router-dom";

import * as ENV from "config/env";
import { RECAPTCHA_KEY } from "config/env";
import AppRouter from "routers/AppRouter";
import AuthenticationRouter from "routers/AuthenticationRouter";
import { RootState } from "store/types";

import "./App.css";

const ScrollToTop = withRouter(({ children, location: { pathname } }: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

const App: FunctionComponent = () => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  if (!window.location.pathname.includes(ENV.ROUTER_BASE_URL)) {
    window.history.replaceState("", "", ENV.ROUTER_BASE_URL + window.location.pathname);
  }

  return (
    <Router basename="/club">
      <ScrollToTop />
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} language="fr">
        <SnackbarProvider
          maxSnack={2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          {isAuthenticated ? <AppRouter /> : <AuthenticationRouter />}
        </SnackbarProvider>
      </GoogleReCaptchaProvider>
    </Router>
  );
};

export default App;
