export const ACTION_TYPES = {
  LOGIN_USER: "LOGIN_USER",
  AUTH_RESET: "AUTH_RESET",
};

export interface AuthState {
  isAuthenticated: boolean;
  apiToken?: string;
  authMode?: string;
  date?: Date;
}

export type AuthActionLoginType = {
  type: typeof ACTION_TYPES.LOGIN_USER;
  payload: { apiToken: string; authMode: string };
};

export type AuthActionTypes = AuthActionLoginType | { type: typeof ACTION_TYPES.AUTH_RESET };
