import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import { X } from "react-feather";
import theme from "theme";
import { styled as muiStyled } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AdjudicationToggle from "screens/results/components/AdjudicationToggle";
import { ResultsFilterRowResponseType } from "types/form.types";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import actions from "store/actions";
import api from "api";
import { ServerSuccesses } from "enums/server.enums";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import AbsoluteLoader from "components/loader/AbsoluteLoader";

const ModalTitle = muiStyled(Grid)({
  borderBottom: `solid 1px ${theme.palette.primary.main}`,
});

const ModalAction = muiStyled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: 25,
  paddingRight: 25,
  paddingTop: 10,
  paddingBottom: 20,
});

interface Props {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  row: ResultsFilterRowResponseType;
  isAuthModeCLUB: boolean;
  challengeId: string;
}

const CommentModal: React.FC<Props> = ({ open, onClose, row, isAuthModeCLUB, challengeId }) => {
  const dispatch = useDispatch();

  const participant = useSelector((state: RootState) => state.results.find((r) => r.id === row?.id));

  const [isLoading, setLoading] = useState<boolean>(false);
  const [modalComment, setModalComment] = React.useState<string>(participant?.comment ?? "");

  const [, changeAdjudicationWithSnackbar] = useSnackbarPromiseHandler(
    async (cId: string, accountId: string, adjudication: boolean, comment?: string) => {
      await api.results.adjudicate(cId, accountId, adjudication, comment);
    },
    `server.success.${ServerSuccesses.EDIT_SUCCESS}`
  );

  const handleChangeAdjudication = async (accountId: string, event: React.ChangeEvent<{}>, newValue: boolean | undefined | null) => {
    event.preventDefault();
    event.stopPropagation();

    if (newValue === null) return;

    dispatch(actions.results.updateContactAdjudication(accountId, newValue));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await changeAdjudicationWithSnackbar(challengeId, participant.id, participant.adjudication, modalComment);
      dispatch(actions.results.updateContactComment(participant.id, modalComment));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setModalComment(row?.comment);
  }, [row]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <ModalTitle xs={12} display="flex">
          <Box>
            <Typography fontWeight={500} />
            <FormattedMessage id="results.comment.modal.title" />
          </Box>
          <Box position="absolute" top={0} right={0} p={1} pr={2}>
            <IconButton aria-label="close" onClick={onClose} size="small">
              <X />
            </IconButton>
          </Box>
        </ModalTitle>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="results.comment.modal.content" />
        </DialogContentText>
        {isLoading && <AbsoluteLoader />}
        <TextField
          fullWidth
          multiline
          rows={4}
          maxRows={4}
          label="commentaire"
          variant="filled"
          defaultValue={modalComment ?? ""}
          onChange={(e) => setModalComment(e.target.value)}
        />
      </DialogContent>
      <ModalAction>
        <AdjudicationToggle row={participant} handleChangeAdjudication={handleChangeAdjudication} isAuthModeCLUB={isAuthModeCLUB} />
        <Button variant="contained" color="primary" pr={2} onClick={handleSave}>
          <FormattedMessage id="button.save" />
        </Button>
      </ModalAction>
    </Dialog>
  );
};

export default CommentModal;
