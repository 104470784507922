import React from "react";
import { Route, Switch } from "react-router-dom";
import { useIntl } from "react-intl";

import Layout from "layouts";
import Authentication from "screens/authentication/Authentication";
import Activation from "screens/activation/Activation";

const AuthenticationRouter: React.FunctionComponent = () => {
  const intl = useIntl();

  return (
    <Layout>
      <Switch>
        <Route path={intl.formatMessage({ id: "routes.reset.password" })} exact component={Activation} />
        <Route component={Authentication} />
      </Switch>
    </Layout>
  );
};

export default AuthenticationRouter;
