const account = {
  "screen.account.button.logout": "Se déconnecter",

  "accounts.table.column.label.name": "Établissement",
  "accounts.table.column.label.group": "Groupe national",
  "accounts.table.column.label.contacts": "Participant",

  "accounts.details.noquest": "Aucun objectif",
  "accounts.details.contacts": "Participants",
  "accounts.details.contacts.name": "Nom complet",
  "accounts.details.contacts.accountName": "Nom compte",
  "accounts.details.contacts.rootAccountName": "Groupe national",
  "accounts.details.contacts.ipn": "IPN",
  "accounts.details.contacts.allQuestsCompleted": "Challenge remporté",
  "accounts.details.noContact": "Aucun participant",
  "accounts.details.noAccount": "Aucun compte",
  "accounts.addContact.title": "Ajouter les participants",
  "accounts.addContact.contacts": "Liste des contacts",
  "accounts.addContact.accounts": "Liste des comptes",

  "button.accounts.details.addContact": "Ajouter un participant",
  "button.accounts.details.addContact.save": "Sauvegarder les participations",
  "button.accounts.details.addContact.create": "Créer une participation",
  "button.accounts.details.deleteContact": "Supprimer le participant",
};

export default account;
