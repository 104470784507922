import React from "react";
import { Field, Formik } from "formik";
import styled from "styled-components";
import { Button, Grid, styled as muiStyled } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment, { Moment } from "moment";
import "moment/locale/fr";

import { spacing } from "assets/styles/spacing";
import type { ChallengeFormType } from "types/form.types";

moment.locale("fr"); // it is required to select default locale manually

const Input = styled(Field)`
  flex: 1;
  margin: ${spacing(4)}px 0;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 550px;
  width: 100%;
  & > div {
    margin: ${spacing(4)}px 0;
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  & > button {
    margin-left: ${spacing(2)}px;
  }
`;

const KeyboardDatePickerWide = muiStyled(KeyboardDatePicker)({
  width: "100%",
});

const GridAlignedCenter = muiStyled(Grid)({
  alignSelf: "center",
});

const getValidationSchema = (intl: any) => {
  const required = intl.formatMessage({ id: "form.error.required" });

  return {
    name: Yup.string().required(required),
    startDate: Yup.date().required(required),
    endDate: Yup.date()
      .required(required)
      .min(Yup.ref("startDate"), intl.formatMessage({ id: "form.challenge.date.error.anterior.to.start" })),
    points: Yup.number()
      .required(required)
      .min(0, intl.formatMessage({ id: "form.challenge.points.error.positive" })),
  };
};

export interface ChallengeFormInitialValuesType
  extends Omit<ChallengeFormType, "startDate" | "endDate" | "registrationStartDate" | "registrationEndDate" | "closeDate"> {
  startDate: Moment;
  endDate: Moment;
  registrationStartDate: Moment;
  registrationEndDate: Moment;
  closeDate: Moment;
}

interface Props {
  onSubmit: (form: ChallengeFormInitialValuesType) => Promise<any>;
  onClose?: () => any;
  initialValues: ChallengeFormInitialValuesType;
}

const ChallengeForm: React.FunctionComponent<Props> = ({ onSubmit, onClose, initialValues }) => {
  const intl = useIntl();
  const validationSchema = getValidationSchema(intl);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={Yup.object(validationSchema)}>
      {({ handleSubmit, setFieldValue, values, errors }: any) => (
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item container xs={6}>
              <KeyboardDatePickerWide
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="startDate"
                name="startDate"
                minDateMessage={intl.formatMessage({ id: "form.challenge.date.error.anterior" })}
                maxDateMessage={intl.formatMessage({ id: "form.challenge.date.error.posterior" })}
                invalidDateMessage={intl.formatMessage({ id: "form.challenge.date.error.invalid" })}
                label={<FormattedMessage id="common.date.startDate" />}
                value={values.startDate}
                onChange={(value: Moment) => {
                  setFieldValue("startDate", value);
                }}
                helperText={errors?.startDate}
                error={!!errors?.startDate}
              />
            </Grid>
            <Grid item container xs={6}>
              <KeyboardDatePickerWide
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="endDate"
                name="endDate"
                minDateMessage={intl.formatMessage({ id: "form.challenge.date.error.anterior" })}
                maxDateMessage={intl.formatMessage({ id: "form.challenge.date.error.posterior" })}
                invalidDateMessage={intl.formatMessage({ id: "form.challenge.date.error.invalid" })}
                label={<FormattedMessage id="common.date.endDate" />}
                value={values.endDate}
                onChange={(value: Moment) => {
                  setFieldValue("endDate", value);
                }}
                helperText={errors?.endDate}
                error={!!errors?.endDate}
              />
            </Grid>
            <Grid item container xs={6}>
              <KeyboardDatePickerWide
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="registrationStartDate"
                name="registrationStartDate"
                minDate={values.startDate}
                minDateMessage={intl.formatMessage({ id: "form.challenge.date.error.anterior" })}
                maxDateMessage={intl.formatMessage({ id: "form.challenge.date.error.posterior" })}
                invalidDateMessage={intl.formatMessage({ id: "form.challenge.date.error.invalid" })}
                label={<FormattedMessage id="common.date.registrationStartDate" />}
                value={values.registrationStartDate}
                onChange={(value: Moment) => {
                  setFieldValue("registrationStartDate", value);
                }}
                helperText={errors?.registrationStartDate}
                error={!!errors?.registrationStartDate}
              />
            </Grid>
            <Grid item container xs={6}>
              <KeyboardDatePickerWide
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="registrationEndDate"
                name="registrationEndDate"
                minDate={values.registrationStartDate}
                minDateMessage={intl.formatMessage({ id: "form.challenge.date.error.anterior" })}
                maxDate={values.endDate}
                maxDateMessage={intl.formatMessage({ id: "form.challenge.date.error.posterior" })}
                invalidDateMessage={intl.formatMessage({ id: "form.challenge.date.error.invalid" })}
                label={<FormattedMessage id="common.date.registrationEndDate" />}
                value={values.registrationEndDate}
                onChange={(value: Moment) => {
                  setFieldValue("registrationEndDate", value);
                }}
                helperText={errors?.registrationEndDate}
                error={!!errors?.registrationEndDate}
              />
            </Grid>
            <Grid item container xs={6}>
              <KeyboardDatePickerWide
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="closeDate"
                name="closeDate"
                minDate={values.endDate}
                minDateMessage={intl.formatMessage({ id: "form.challenge.date.error.anterior" })}
                maxDateMessage={intl.formatMessage({ id: "form.challenge.date.error.posterior" })}
                invalidDateMessage={intl.formatMessage({ id: "form.challenge.date.error.invalid" })}
                label={<FormattedMessage id="common.date.closeDate" />}
                value={values.closeDate}
                onChange={(value: Moment) => {
                  setFieldValue("closeDate", value);
                }}
                helperText={errors?.closeDate}
                error={!!errors?.closeDate}
              />
            </Grid>
            <GridAlignedCenter item container xs={6}>
              <Input
                type="number"
                id="points"
                name="points"
                component={TextField}
                variant="filled"
                label={intl.formatMessage({ id: "form.challenge.points" })}
                inputProps={{ min: 0 }}
              />
            </GridAlignedCenter>
            <Buttons>
              <Button variant="outlined" onClick={onClose}>
                <FormattedMessage id="form.cancel" />
              </Button>
              <Button variant="contained" color="primary" type="submit">
                <FormattedMessage id="form.submit" />
              </Button>
            </Buttons>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ChallengeForm;
