import api from "api";
import type { ChallengeFormType, QuestFormType, ResultsFilterRowResponseType } from "types/form.types";
import type { AddContactRequest, GETChallenge, GETChallengeByYear, GETChallengeContacts } from "types/api.types";
import { ChallengeModalTab, ChallengeTab } from "enums/type.enums";
import { ACTION_TYPES } from "store/challenge/challenge.types";

const openStoreAction = (payload: boolean) => ({
  type: ACTION_TYPES.OPEN_STORE,
  payload,
});

const publishResultsAction = (payload: boolean) => ({
  type: ACTION_TYPES.PUBLISH_RESULTS,
  payload,
});

export const selectYear = (payload: number) => ({
  type: ACTION_TYPES.SELECT_YEAR,
  payload,
});

const getChallengeAction = ({ data: payload }: GETChallengeByYear) => ({
  type: ACTION_TYPES.GET_CHALLENGE,
  payload,
});

const setChallengeRulesLinkAction = (rulesLink: string) => ({
  type: ACTION_TYPES.SET_CHALLENGE_RULES_URL,
  payload: rulesLink,
});

const createChallengeAction = ({ data: payload }: GETChallenge) => ({
  type: ACTION_TYPES.CREATE_CHALLENGE,
  payload,
});

const updateChallengeAction = ({ data: payload }: GETChallenge) => ({
  type: ACTION_TYPES.UPDATE_CHALLENGE,
  payload,
});

const createQuestAction = ({ data: payload }: GETChallenge) => ({
  type: ACTION_TYPES.CREATE_QUEST,
  payload,
});

const editQuestAction = ({ data: payload }: GETChallenge) => ({
  type: ACTION_TYPES.EDIT_QUEST,
  payload,
});

const deleteQuestAction = (questId: string) => ({
  type: ACTION_TYPES.DELETE_QUEST,
  payload: questId,
});

const getChallengeContactsAction = ({ data: payload }: GETChallengeContacts) => ({
  type: ACTION_TYPES.GET_CHALLENGE_CONTACTS,
  payload,
});

export const updateChallengeTab = (payload: ChallengeTab) => ({
  type: ACTION_TYPES.UPDATE_CHALLENGE_TAB,
  payload,
});

export const updateChallengeModalTab = (payload: ChallengeModalTab) => ({
  type: ACTION_TYPES.UPDATE_CHALLENGE_MODAL_TAB,
  payload,
});

export const setResultDetails = (payload: ResultsFilterRowResponseType | undefined) => async (dispatch: any) => {
  dispatch({
    type: ACTION_TYPES.RESULT_DETAILS,
    payload,
  });
};

export const getChallenge =
  (year: number): any =>
  async (dispatch: any) => {
    // Get challenge + years { Challenge, years }
    const payload = await api.challenge.byYear(year);

    dispatch(getChallengeAction(payload));
  };

export const openStore =
  (challengeId: string, open: boolean): any =>
  async (dispatch: any) => {
    dispatch(openStoreAction(open));

    await api.storeManagement.openStore(challengeId, open);
  };

export const publishResults =
  (challengeId: string, open: boolean): any =>
  async (dispatch: any) => {
    dispatch(publishResultsAction(open));

    await api.storeManagement.publishResults(challengeId, open);
  };

export const getChallengeRulesLink =
  (filename: string): any =>
  async (dispatch: any) => {
    const {
      data: { link },
    } = await api.challenge.getRulesLink(filename);

    dispatch(setChallengeRulesLinkAction(link));
  };

export const uploadNewRules =
  (challengeId: string, formData: FormData): any =>
  async (dispatch: any) => {
    const { data } = await api.challenge.uploadNewRules(challengeId, formData);

    dispatch(getChallengeRulesLink(data.rulesFilename));

    const contacts = await api.challenge.getContacts(challengeId);

    dispatch(getChallengeContactsAction(contacts));
  };

export const createChallenge =
  (formValues: ChallengeFormType): any =>
  async (dispatch: any) => {
    // Get challenge
    const payload = await api.challenge.create(formValues);

    dispatch(createChallengeAction(payload));

    const contacts = await api.challenge.getContacts(payload.data.id);

    dispatch(getChallengeContactsAction(contacts));
  };

export const updateChallenge =
  (challengeId: string, formValues: ChallengeFormType): any =>
  async (dispatch: any) => {
    // Get challenge
    const payload = await api.challenge.update(challengeId, formValues);

    dispatch(updateChallengeAction(payload));

    const contacts = await api.challenge.getContacts(payload.data.id);

    dispatch(getChallengeContactsAction(contacts));
  };

export const createQuest =
  (challengeId: string, form: QuestFormType): any =>
  async (dispatch: any) => {
    // Get challenge
    const payload = await api.quest.create(challengeId, form);

    dispatch(createQuestAction(payload));

    const contacts = await api.challenge.getContacts(challengeId);

    dispatch(getChallengeContactsAction(contacts));
  };

export const editQuest =
  (challengeId: string, questId: string, form: QuestFormType): any =>
  async (dispatch: any) => {
    const payload = await api.quest.edit(challengeId, questId, form);

    dispatch(editQuestAction(payload));

    const contacts = await api.challenge.getContacts(challengeId);

    dispatch(getChallengeContactsAction(contacts));
  };

export const deleteQuest =
  (challengeId: string, questId: string): any =>
  async (dispatch: any) => {
    // Get challenge
    await api.quest.delete(challengeId, questId);

    dispatch(deleteQuestAction(questId));

    const contacts = await api.challenge.getContacts(challengeId);

    dispatch(getChallengeContactsAction(contacts));
  };

export const getChallengeContacts =
  (id: string): any =>
  async (dispatch: any) => {
    const payload = await api.challenge.getContacts(id);

    dispatch(getChallengeContactsAction(payload));
  };

export const addContact = (challengeId: string, form: AddContactRequest) => async () => {
  await api.challenge.addContact(challengeId, form);
};
