import React from "react";
import styled from "styled-components";

import rcoin from "assets/icons/r-coin-logo.svg";

type ContainerProps = {
  $size: number;
};

/* === styled-component === */
const Container = styled.div`
  width: ${(props: ContainerProps) => props.$size}px;
  margin: 3px 4px 0 4px;
`;

interface Props {
  size: number;
}

const Rcoin: React.FC<Props> = ({ size = 28 }: Props) => (
  <Container $size={size}>
    <img src={rcoin} alt="logo-coin" />
  </Container>
);

export default Rcoin;
