import { ResultsFilterResponseType } from "types/form.types";

import { ACTION_TYPES, ResultsActionTypes } from "./results.types";

const initialState: ResultsFilterResponseType = [];

const resultsReducer = (state: ResultsFilterResponseType = initialState, action: ResultsActionTypes): ResultsFilterResponseType => {
  switch (action.type) {
    case ACTION_TYPES.GET_RESULTS: {
      return action.payload;
    }
    case ACTION_TYPES.UPDATE_REGISTRATION: {
      const { id, registered } = action.payload;
      return state.map((c) => (c.id === id ? { ...c, registered } : c));
    }
    case ACTION_TYPES.UPDATE_ADJUDICATION: {
      const { id, adjudication } = action.payload;
      return state.map((c) => (c.id === id ? { ...c, adjudication } : c));
    }
    case ACTION_TYPES.UPDATE_TYPE: {
      const { id, type } = action.payload;
      return state.map((c) => (c.id === id ? { ...c, type } : c));
    }
    case ACTION_TYPES.DELETE_RESULT_ACCOUNT: {
      const { contactId, accountId } = action.payload;
      return state.map((c) => (c.id === contactId ? { ...c, accounts: c.accounts.filter((a) => a.id !== accountId) } : c));
    }
    case ACTION_TYPES.UPDATE_COMMENT: {
      const { id, comment } = action.payload;
      return state.map((c) => (c.id === id ? { ...c, comment } : c));
    }
    case ACTION_TYPES.DELETE_CONTACT_ACCOUNT: {
      const { id, account } = action.payload;
      return state.map((c) => (c.id === id ? { ...c, accounts: c.accounts.filter((a) => a.id !== account) } : c));
    }
    case ACTION_TYPES.DELETE_CONTACT: {
      const { id } = action.payload;
      return state.filter((c) => c.id !== id);
    }
    default:
      return state;
  }
};

export default resultsReducer;
