import challenge from "./challenge.i18n";
import common from "./common.i18n";
import contact from "./contact.i18n";
import form from "./form.i18n";
import routes from "./routes.i18n";
import server from "./server.i18n";
import importation from "./importation.i18n";
import auth from "./auth.i18n";
import dashboard from "./dashboard.i18n";
import storeManagement from "./storeManagement.i18n";
import results from "./results";
import account from "./account.i18n";

const fr = {
  locale: "fr",
  messages: {
    ...challenge,
    ...contact,
    ...common,
    ...form,
    ...routes,
    ...server,
    ...importation,
    ...auth,
    ...dashboard,
    ...storeManagement,
    ...results,
    ...account,
  },
};

export default fr;
