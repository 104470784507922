import React, { useState } from "react";
import { Box, Popover, TextField } from "@material-ui/core";

interface Props {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onSubmit: (value: string) => any;
  inputProps?: any;
}

const PopoverTextField: React.FC<Props> = ({ open, anchorEl, onClose, onSubmit, inputProps }) => {
  const [textToAdd, setTextToAdd] = useState<string>("");

  return (
    <Popover
      onExit={() => setTextToAdd("")}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <form onSubmit={() => onSubmit(textToAdd)}>
        <Box p={2}>
          <TextField
            autoFocus
            name="textToAdd"
            value={textToAdd}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTextToAdd(event.target.value)}
            inputProps={inputProps}
          />
        </Box>
      </form>
    </Popover>
  );
};

export default PopoverTextField;
