import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Grow, IconButton, Typography } from "@material-ui/core";
import { ArrowLeft } from "react-feather";
import { useIntl } from "react-intl";
import { styled as muiStyled } from "@material-ui/core/styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { number, object } from "yup";

import { StoreItemType } from "types/common.types";
import AbsoluteLoader from "components/loader/AbsoluteLoader";
import { RootState } from "store/types";
import actions from "store/actions";
import { GETStoreItems } from "types/api.types";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import api from "api";

import StoreManagementProductLeftPanel from "./StoreManagementProductLeftPanel";
import StoreManagementProductRightPanel from "./StoreManagementProductRightPanel";

const ContainerPage = muiStyled(Container)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingBottom: 32, // to correct grid negative margin c.f. mui-doc limitations
});

const Title = muiStyled(Typography)({
  color: "white",
});

const GridContainerRightPanel = muiStyled(Grid)({
  position: "relative",
});

type LocationState = {
  storeItems: StoreItemType[];
  product: StoreItemType;
};

type UrlParams = {
  productSlug: string;
};

const StoreManagementProduct: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<LocationState>();
  const { productSlug } = useParams<UrlParams>();
  const [isLoadingGetItems, getItemsWithSnackbar] = useSnackbarPromiseHandler(api.storeManagement.getItems);
  const year = useSelector((state: RootState) => state.challenge.selectedYear);
  const [storeItems, setStoreItems] = useState<StoreItemType[]>([]);
  const [product, setProduct] = useState<StoreItemType>();
  const history = useHistory();
  const intl = useIntl();

  const getChallenge = (y: number) => dispatch(actions.challenge.getChallenge(y));

  const { challenge } = useSelector((state: RootState) => state.challenge);

  const validationSchema = object().shape({
    price: number().min(0, intl.formatMessage({ id: "form.error.field.min" })),
  });

  useEffect(() => {
    getChallenge(year);
  }, []);

  useEffect(() => {
    if (location?.state?.product && location?.state?.storeItems) {
      setProduct(location.state.product);
      setStoreItems(location.state.storeItems);
    } else if (productSlug && challenge) {
      (async function getStoreItems() {
        const { data }: GETStoreItems = await getItemsWithSnackbar(challenge.id);

        setProduct(data.results.find((fetchedProduct) => fetchedProduct.slug === productSlug));
        setStoreItems(data.results);
      })();
    } else {
      history.push(intl.formatMessage({ id: "routes.store.management" }));
    }
  }, [location]);

  return !product || !challenge?.id || isLoadingGetItems ? (
    <AbsoluteLoader />
  ) : (
    <ContainerPage>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="back"
            onClick={() => {
              history.push(`${intl.formatMessage({ id: "routes.store.management" })}`);
            }}
          >
            <ArrowLeft />
          </IconButton>
          <Title variant="h3">{product.name}</Title>
        </Box>
      </Box>
      <Formik
        initialValues={{
          name: "",
          brand: "",
          shippingDate: "",
          categoryId: product?.category?.id || "",
          gamut: "",
          reference: "",
          choices: [],
          description: "",
          price: 0,
          file: undefined,
          ...product,
        }}
        validationSchema={validationSchema}
        onSubmit={() => {} /* handled by child since there are no validations */}
      >
        {({ setFieldValue, values, errors }: any) => (
          <Form>
            <Grid container>
              <Grow in>
                <Grid item xs={6}>
                  <StoreManagementProductLeftPanel
                    product={product}
                    challengeId={challenge.id}
                    storeItems={storeItems}
                    setFieldValue={setFieldValue}
                    formValues={values}
                    formErrors={errors}
                  />
                </Grid>
              </Grow>
              <Grow in>
                <GridContainerRightPanel item container xs={6} justify="center" alignItems="center">
                  <StoreManagementProductRightPanel product={product} />
                </GridContainerRightPanel>
              </Grow>
            </Grid>
          </Form>
        )}
      </Formik>
    </ContainerPage>
  );
};

export default StoreManagementProduct;
