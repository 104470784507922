import * as React from "react";
import styled from "styled-components";

import Header from "components/header";
import { spacing } from "assets/styles/spacing";
import background from "assets/images/background.png";

const Container = styled.main`
  display: flex;
  flex: 1;
  min-height: 100vh;
  flex-direction: column;
  // background: url(${background}) 50% no-repeat;
  // background-size: cover;
  background-color: #303030;
  width: 100%;
`;

const Body = styled.div`
  margin-top: ${spacing(4)}px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

interface Props {
  children: React.ReactNode;
}

const DefaultLayout: React.FC<Props> = ({ children }) => (
  <Container>
    <Header />
    <Body>{children}</Body>
  </Container>
);

export default DefaultLayout;
