import { ContactType } from "enums/type.enums";

const results = {
  "results.table.title": "{count} résultat{count, plural, one {} other {s}}",
  "results.table.column.label.account": "Compte représenté",
  "results.table.column.label.name": "Nom complet",
  "results.table.column.label.registered": "Inscrit",
  "results.table.column.label.result": "Résultat",
  "results.table.column.label.adjudication": "Arbitrage",
  "results.table.column.item.severalAccounts": "Multi-site",
  "results.table.column.item.quest.noParticipation": "Non concerné",
  "results.table.column.item.noAccounts": "Non représenté",
  "results.table.column.item.result.won": "Gagné",
  "results.table.column.item.result.won.override": "Gagné (Surchargé)",
  "results.table.column.item.result.lost": "Perdu",
  "results.table.column.item.result.lost.override": "Perdu (Surchargé)",
  "results.table.column.item.noContact": "Ce compte n’est pas encore représenté",
  "results.table.column.item.active.tooltip": "Contact inactif d’après SalesForce",
  "results.table.filter.title.result": "Résultats du challenge",
  "results.table.filter.title.adjudication": "Après arbitrage",
  "results.table.filter.title.registered": "Inscrit",
  "results.table.filter.item.result.won": "Gagné",
  "results.table.filter.item.registered": "Oui",
  "results.table.filter.item.registered.not": "Non",
  "results.table.filter.item.result.lost": "Perdu",
  "results.table.filter.item.adjudication.won": "Gagné",
  "results.table.filter.item.adjudication.lost": "Perdu",
  "results.panel.accounts.title": "Comptes représentés",
  "results.panel.accounts.title.plural": "Compte{count, plural, one {} other {s}} représenté{count, plural, one {} other {s}}",
  "results.panel.accounts.add": "Ajouter un compte",
  "results.panel.accounts.phone": "Numéro de téléphone: ",
  "results.panel.accounts.type": "Type d'utilisateur: ",
  [`results.panel.accounts.type.value.${ContactType.CG}`]: "CG",
  [`results.panel.accounts.type.value.${ContactType.CVVO}`]: "CVVO",
  [`results.panel.accounts.type.value.${ContactType.CVVOMULTI}`]: "CVVO Multisite",
  "results.panel.orders.title": "Commande",
  "results.panel.orders.column.label.number": "N° de commande",
  "results.panel.orders.column.label.date": "Date commande",
  "results.panel.orders.column.label.status": "Statut",
  "results.panel.orders.none": "Aucune commande",
  "results.panel.orders.orderDate": "Date de la commande",
  "results.panel.orders.address": "Adresse de livraison",
  "results.panel.orders.contactForDelivery": "Contact livraison",
  "results.panel.orders.comment": "Commentaire",
  "results.panel.orders.name": "Lot",
  "results.panel.orders.gamut": "Gamme",
  "results.panel.orders.choice": "Choix utilisateur",
  "results.export.filename": "résultats.xlsx",
  "results.export.orders.filename": "Commandes.xlsx",
  "form.addResults.error.number": "Vous devez renseigner un nombre",

  // Comment modal
  "results.comment.modal.title": "Ajouter un commentaire",
  "results.comment.modal.content":
    "Si vous le souhaitez, vous pouvez ajouter un commentaire afin de décrire la raison de votre adjudication.",
};

export default results;
