import { AccountsState, ACTION_TYPES, ResultsActionTypes } from "./accounts.types";

const initialState: AccountsState = {
  accounts: [],
  account: undefined,
  filteredAccounts: [],
  filteredContacts: [],
  accountDetails: undefined,
};

const accountsReducer = (state: AccountsState = initialState, action: ResultsActionTypes): AccountsState => {
  switch (action.type) {
    case ACTION_TYPES.GET_ACCOUNTS: {
      return {
        ...state,
        accounts: action.payload,
      };
    }

    case ACTION_TYPES.GET_ACCOUNT: {
      return {
        ...state,
        account: action.payload,
      };
    }

    case ACTION_TYPES.DELETE_ACCOUNT_CONTACT: {
      return {
        ...state,
        account: { ...state.account, contacts: state.account.contacts.filter((contact) => contact.sfid !== action.payload) },
      };
    }

    case ACTION_TYPES.DELETE_C0NTACT: {
      return {
        ...state,
        account: { ...state.account, contacts: state.account?.contacts?.filter((contact) => contact.sfid !== action.payload) },
      };
    }

    case ACTION_TYPES.GET_FILTERED_CONTACTS: {
      return {
        ...state,
        filteredContacts: action.payload,
      };
    }

    case ACTION_TYPES.GET_FILTERED_ACCOUNTS: {
      return {
        ...state,
        filteredAccounts: action.payload,
      };
    }

    case ACTION_TYPES.ACCOUNT_DETAILS: {
      return {
        ...state,
        accountDetails: action.payload,
      };
    }

    default:
      return state;
  }
};

export default accountsReducer;
