import React from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { X as CloseIcon } from "react-feather";
import styled from "styled-components";

import { spacing } from "assets/styles/spacing";
import { colors } from "assets/styles/colors";
import { ResultsFilterRowResponseType } from "types/form.types";

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${spacing(2)}px;
  border-bottom: 1px solid ${colors.yellowRenault};
`;

interface Props {
  row: ResultsFilterRowResponseType;
  onClose: () => void;
}

const ResultsPanelHead: React.FC<Props> = ({ row, onClose }) => (
  <HeadContainer>
    <Box display="flex" flexDirection="column">
      <Typography variant="h5">{row.name}</Typography>
      {row.accounts.length === 1 ? (
        row.accounts[0].name
      ) : (
        <Typography>
          <FormattedMessage id="results.table.column.item.severalAccounts" />
        </Typography>
      )}
    </Box>

    <Box display="flex" flexDirection="row" alignItems="center">
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  </HeadContainer>
);

export default ResultsPanelHead;
