import React from "react";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";

const TypographyCentered = muiStyled(Typography)({
  textAlign: "center",
  fontWeight: 600,
});

const CardWide = muiStyled(Card)({
  width: "100%",
  height: "50%",
});

interface Props {
  title: string;
  done: number;
  goal: number;
}

const CardDoneOverGoal: React.FC<Props> = ({ title, done, goal }) => (
  <CardWide variant="outlined">
    <CardContent>
      <Box display="flex" flexDirection="column">
        <Typography paragraph variant="body2">
          {title}
        </Typography>
        {goal !== undefined ? (
          <TypographyCentered paragraph variant="h6">
            {`${done} / ${goal}`}
          </TypographyCentered>
        ) : (
          <TypographyCentered paragraph variant="h6">
            -
          </TypographyCentered>
        )}
      </Box>
    </CardContent>
  </CardWide>
);

export default CardDoneOverGoal;
