/**
 * Sleep before returning an object
 * @param ms, time to sleep
 * @param object, the object to return
 */
export const sleep = (ms: number, object: any): Promise<any> => new Promise((resolve) => setTimeout(() => resolve({ data: object }), ms));

export function digitCount(num: number) {
  if (num === 0) return 1;
  return Math.floor(Math.log10(Math.abs(num))) + 1;
}

export function completeWithSpaces(num: number, goal: number): string {
  const count = digitCount(goal) - digitCount(num);
  let result = `${num}`;
  if (count === 0) {
    return result;
  }
  for (let i = 0; i < count; i += 1) {
    result = `\u2007${result}`;
  }
  return result;
}
