import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";

import { ResultsFilterResponseType, ResultsFilterRowResponseType } from "types/form.types";

import ResultsPanel from "./panel/ResultsPanel";
import ResultsTable from "./ResultsTable";

const GridContainer = styled(Grid)`
  max-height: 750px;
`;

interface Props {
  results: ResultsFilterResponseType;
  isLoading: boolean;
  submitForm: () => void;
}

const ResultsBody: React.FC<Props> = ({ results, isLoading, submitForm }) => {
  const [selectedRow, setSelectedRow] = useState<ResultsFilterRowResponseType>();
  const [canExpand, setCanExpand] = useState<boolean>(!selectedRow);

  const handleClosePanel = () => setSelectedRow(undefined);

  const handleOnClosePanelAnimationExited = () => setCanExpand(true);

  useEffect(() => {
    if (selectedRow) {
      setCanExpand(false);
    }
  }, [selectedRow]);

  return (
    <GridContainer item container spacing={2}>
      <ResultsTable
        isLoading={isLoading}
        results={results}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        canExpand={canExpand}
      />
      <ResultsPanel
        row={selectedRow}
        onClose={handleClosePanel}
        onCloseAnimationExited={handleOnClosePanelAnimationExited}
        submitForm={submitForm}
      />
    </GridContainer>
  );
};

export default ResultsBody;
