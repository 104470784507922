import React, { useLayoutEffect, useRef, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import CircularProgress, { CircularProgressProps } from "@material-ui/core/CircularProgress";
import { styled as muiStyled } from "@material-ui/core/styles";

const Container = muiStyled(Box)({
  width: "100%",
  height: "auto",
});

const CircularProgressWide = muiStyled(CircularProgress)({
  width: "100% !important",
  height: "auto !important",
});

type Props = CircularProgressProps & { value: number };

const CircularProgressWithLabel: React.FC<Props> = (props) => {
  const { value } = props;
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState<number>(0);

  useLayoutEffect(() => {
    if (containerRef.current) {
      setContainerSize(containerRef.current.offsetWidth);
    }
  }, []);

  return (
    <Container position="relative" display="inline-flex" ref={containerRef}>
      <CircularProgressWide variant="determinate" {...props} />
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          style={{ fontSize: `calc(${containerSize}px / 10)` }}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Container>
  );
};

export default CircularProgressWithLabel;
