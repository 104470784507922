import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { Box, Switch, Typography } from "@material-ui/core";

import { formatDateIntl } from "utils/dates.utils";
import { spacing } from "assets/styles/spacing";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import { ServerSuccesses } from "enums/server.enums";
import actions from "store/actions";
import { useDispatch } from "react-redux";

const Date = styled.div`
  font-size: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex: 1;
  padding: ${spacing(4)}px 0 ${spacing(2)}px 0;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
`;

interface Props {
  startDate: Date;
  endDate: Date;
  registrationStartDate: Date;
  registrationEndDate: Date;
  closeDate: Date;
  resultsPublished: boolean;
  id: string;
}

const DateBlock: React.FunctionComponent<Props> = ({
  id,
  resultsPublished,
  startDate,
  endDate,
  registrationStartDate,
  registrationEndDate,
  closeDate,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const publishResults = (event: any) => dispatch(actions.challenge.publishResults(id, event.target.checked));
  const [, publishResultsWithSnackbar] = useSnackbarPromiseHandler(publishResults, `server.success.${ServerSuccesses.PUBLISH_RESULTS}`);

  return (
    <Container>
      <Box width="100%" display="flex" flexDirection="row" justifyContent="space-evenly" mb={2}>
        <DateContainer>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage id="common.date.startDate" />
          </Typography>
          <Date>{formatDateIntl(intl, startDate)}</Date>
        </DateContainer>
        <DateContainer>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage id="common.date.endDate" />
          </Typography>
          <Date>{formatDateIntl(intl, endDate)}</Date>
        </DateContainer>
      </Box>
      <Box width="100%" display="flex" flexDirection="row" justifyContent="space-evenly" mb={2}>
        <DateContainer>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage id="common.date.registrationStartDate" />
          </Typography>
          <Date>{formatDateIntl(intl, registrationStartDate)}</Date>
        </DateContainer>
        <DateContainer>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage id="common.date.registrationEndDate" />
          </Typography>
          <Date>{formatDateIntl(intl, registrationEndDate)}</Date>
        </DateContainer>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start" flexDirection="row" mb={2}>
        <DateContainer>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage id="common.date.closeDate" />
          </Typography>
          <Date>{formatDateIntl(intl, closeDate)}</Date>
        </DateContainer>
        <DateContainer>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage id="screen.challenge.results.published" />
          </Typography>
          <Box>
            <Switch
              color="primary"
              checked={resultsPublished ?? false}
              onClick={(event: React.MouseEvent<any>) => event.stopPropagation()}
              onChange={publishResultsWithSnackbar}
            />
            <FormattedMessage id={`screen.challenge.results.switch.${resultsPublished ?? false}`} />
          </Box>
        </DateContainer>
      </Box>
    </Container>
  );
};

export default DateBlock;
