import { QuestAggregationTypeProgressive, QuestAggregationTypeUnitary, QuestGoalType, StoreItemGamut } from "enums/type.enums";

const common = {
  "common.application.name": "Club des 100",
  "common.boolean.true": "Oui",
  "common.boolean.false": "Non",
  "common.header.challenge": "Challenge",
  "common.header.challenge.management": "Gestion",
  "common.header.challenge.results": "Résultats",
  "common.header.import": "Imports",
  "common.header.store": "Gestion du magasin",
  "common.date.lastUpdate": "Date dernière mise à jour : {date}",
  "common.date.registrationStartDate": "Début d'inscription",
  "common.date.registrationEndDate": "Fin d'inscription",
  "common.date.closeDate": "Fermeture du challenge",
  "common.date.startDate": "Début du challenge",
  "common.date.endDate": "Fin du challenge",
  "common.table.rowsPerPage": "Lignes par page",
  "common.table.DisplayedRows": "{from}-{to} sur {count}",
  "common.button.import.file": "Importer un fichier",
  "common.link.download.template": "Télécharger un exemple",
  "common.loading": "Chargement...",
  "screen.home.rules.title": "Règlement",
  [`quest.aggregation.${QuestAggregationTypeUnitary.AND}`]: "Et logique",
  [`quest.aggregation.${QuestAggregationTypeUnitary.OR}`]: "Ou logique",
  [`quest.aggregation.${QuestAggregationTypeProgressive.MAX}`]: "Maximum",
  [`quest.aggregation.${QuestAggregationTypeProgressive.MIN}`]: "Minimum",
  [`quest.aggregation.${QuestAggregationTypeProgressive.SUM}`]: "Somme",
  [`quest.aggregation.${QuestAggregationTypeProgressive.MEAN}`]: "Moyenne",
  [`quest.aggregation.${QuestAggregationTypeProgressive.WEIGHTED_MEAN}`]: "Moyenne pondérée",
  [`quest.type.${QuestGoalType.UNITARY}`]: "Condition",
  [`quest.account.true`]: "Concession",
  [`quest.account.false`]: "Personnel",
  [`quest.type.plural.${QuestGoalType.UNITARY}`]: "Condition{count, plural, one {} other {s}}",
  [`quest.type.${QuestGoalType.PROGRESSIVE}`]: "Objectif",
  [`quest.type.plural.${QuestGoalType.PROGRESSIVE}`]: "Objectif{count, plural, one {} other {s}}",
  [`gamut.${StoreItemGamut.BRONZE}`]: "Bronze",
  [`gamut.${StoreItemGamut.SILVER}`]: "Argent",
  [`gamut.${StoreItemGamut.GOLD}`]: "Or",

  // Button
  "button.save": "Sauvegarder",
};

export default common;
