export const openUrl = (link: string) => {
  const a = document.createElement("a");

  a.href = link;
  a.target = "_blank";
  a.click();
};

export const retrieveFilenameFromHeader = (result: any): string | null => {
  let filename = "";
  const disposition = result.headers["content-disposition"];

  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }

  return filename || null;
};
