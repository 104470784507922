import { IntlShape } from "react-intl";

export const formatDateIntl = (intl: IntlShape, value: string | Date): string => {
  const date = new Date(value);
  return Number.isNaN(date.getTime())
    ? null
    : intl.formatDate(date, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
};
