import React from "react";
import { Grid } from "@material-ui/core";

import { Challenge } from "types/common.types";
import { QuestContactsResultSummaryType } from "types/form.types";
import { QuestGoalType } from "enums/type.enums";

import CardQuestProgressions from "./cards/CardQuestProgressions";

interface Props {
  challenge: Challenge;
  quests: QuestContactsResultSummaryType[];
}

const DashboardQuestList: React.FC<Props> = ({ challenge, quests }) => (
  <Grid item container xs={12} spacing={4}>
    {quests
      .filter((questSummary) =>
        challenge.quests.find((quest) => quest.type === QuestGoalType.PROGRESSIVE && quest.name === questSummary.name)
      )
      .map((questSummary) => (
        <Grid item xs={6} key={questSummary.name}>
          <CardQuestProgressions type={QuestGoalType.PROGRESSIVE} questContactsResultSummaryType={questSummary} />
        </Grid>
      ))}
    {quests
      .filter((questSummary) => challenge.quests.find((quest) => quest.type === QuestGoalType.UNITARY && quest.name === questSummary.name))
      .map((questSummary) => (
        <Grid item xs={6} key={questSummary.name}>
          <CardQuestProgressions type={QuestGoalType.UNITARY} questContactsResultSummaryType={questSummary} />
        </Grid>
      ))}
  </Grid>
);

export default DashboardQuestList;
