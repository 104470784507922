import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import { X } from "react-feather";
import theme from "theme";
import { styled as muiStyled } from "@material-ui/core/styles";
import type { ResultsFilterRowResponseType } from "types/form.types";
import ResultsPanelBody from "screens/results/body/panel/ResultsPanelBody";
import { useDispatch } from "react-redux";
import { updateChallengeModalTab } from "store/challenge/challenge.actions";
import { ChallengeModalTab } from "enums/type.enums";
import AbsoluteLoader from "components/loader/AbsoluteLoader";

const ModalTitle = muiStyled(Grid)({
  borderBottom: `solid 1px ${theme.palette.primary.main}`,
});

interface Props {
  open: boolean;
  row?: ResultsFilterRowResponseType;
  onClose: () => (dispatch: any) => Promise<void>;
  submitForm: () => void;
  isLoading: boolean;
}

const ContactModal: React.FC<Props> = ({ open, row, onClose, submitForm, isLoading }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    onClose();
    dispatch(updateChallengeModalTab(undefined));
  };

  useEffect(() => {
    if (open) {
      dispatch(updateChallengeModalTab(ChallengeModalTab.CONTACT_MODAL));
    }
  }, [open, row]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        {isLoading && <AbsoluteLoader />}
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <ModalTitle xs={12} display="flex">
            <Box>
              <Typography fontWeight={500} />
              {row?.name}

              <Typography>
                {row?.accounts?.length === 1 && row.accounts[0].name}
                {row?.accounts?.length === 0 && <FormattedMessage id="results.table.column.item.noAccounts" />}
                {row?.accounts?.length > 1 && <FormattedMessage id="results.table.column.item.severalAccounts" />}
              </Typography>
            </Box>
            <Box position="absolute" top={0} right={0} p={1} pr={2}>
              <IconButton aria-label="close" onClick={handleClose} size="small">
                <X />
              </IconButton>
            </Box>
          </ModalTitle>
        </DialogTitle>
        <DialogContent>
          <ResultsPanelBody row={row} onClose={handleClose} submitForm={submitForm} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContactModal;
