import React, { useState } from "react";
import { styled as muiStyled } from "@material-ui/core/styles";
import { Button, Container as MuiContainer, Grid, Grow, Paper as MuiPaper, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import * as queryString from "query-string";

import { spacing } from "assets/styles/spacing";
import USBKey from "assets/icons/USBKey";
import { colors } from "assets/styles/colors";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import { AuthMode } from "enums/api.enums";
import USBAuthenticator from "components/authentication/USBAuthenticator";

import AuthForm from "./AuthForm";

const Container = muiStyled(MuiContainer)({
  flex: 1,
});

const Paper = muiStyled(MuiPaper)({
  "&:focus": {
    outline: "none",
  },
  position: "relative",
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  flex: 1,
});

const TitlePage = muiStyled(Typography)({
  margin: spacing(10),
  color: colors.lightGrey,
});

const ContainerBlock = styled.div`
  // get priority over MUI styling
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    & > * {
      margin: ${spacing(6)}px;
    }
  }
`;

const Authentication: React.FC = () => {
  const [usb, setUsb] = useState(false);
  const authMode = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE);
  const authToken = localStorage.getItem(LOCAL_STORAGE.API_TOKEN);
  const location = useLocation();
  const { code } = queryString.parse(location.search);

  return (
    <Container>
      <Grid container item xs={12} justify="center">
        <TitlePage variant="h3">
          <FormattedMessage id="screen.auth.title" />
        </TitlePage>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item container xs={6}>
          <Grid item container xs={10}>
            <Grow in>
              <Paper>
                <ContainerBlock>
                  <Typography>
                    <FormattedMessage id="screen.auth.token.title" />
                  </Typography>
                  {usb || (authMode === AuthMode.IDP && authToken) || code ? (
                    <USBAuthenticator />
                  ) : (
                    <USBKey color={colors.lightGrey} size={110} />
                  )}
                  <Button variant="contained" color="primary" onClick={() => setUsb(true)}>
                    <FormattedMessage id="screen.auth.token.button" />
                  </Button>
                </ContainerBlock>
              </Paper>
            </Grow>
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <Grid item container xs={10}>
            <Grow in>
              <Paper>
                <AuthForm />
              </Paper>
            </Grow>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Authentication;
