import React from "react";
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Save, Trash2 } from "react-feather";
import { styled as muiStyled } from "@material-ui/core/styles";
import { Field } from "formik";
import _ from "lodash";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment, { Moment } from "moment";
import { TextField as FMUITextField } from "formik-material-ui";
import { useHistory } from "react-router-dom";

import { spacing } from "assets/styles/spacing";
import FieldAddTextToList from "components/fields/FieldAddTextToList";
import theme from "theme";
import { StoreItemType } from "types/common.types";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import { ServerSuccesses } from "enums/server.enums";
import api from "api";
import ButtonLoading from "components/buttons/ButtonLoading";
import Rcoin from "components/rcoin";

const PaperWide = muiStyled(Paper)({
  paddingTop: spacing(4),
  paddingBottom: spacing(10),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  margin: 6,
  flex: 1,
});

const GridWideChild = muiStyled(Grid)({
  [`& > div`]: {
    width: "100%",
  },
});

// to match KeyboardDatePicker
const BoxChildWidePadded = muiStyled(Box)({
  [`& > div`]: {
    width: "100%",
    paddingTop: 16,
    paddingBottom: 8,
  },
});

const KeyboardDatePickerWide = muiStyled(KeyboardDatePicker)({
  width: "100%",
});

const FormControlWide = muiStyled(FormControl)({
  width: "100%",
});

const ButtonLoadingWide = muiStyled(ButtonLoading)({
  width: "100%",
});

const ButtonLoadingDelete = muiStyled(ButtonLoadingWide)({
  backgroundColor: theme.palette.error.main,
  color: "black",
});

interface Props {
  storeItems: StoreItemType[];
  setFieldValue: any;
  formValues: any;
  formErrors: any;
  product: StoreItemType;
  challengeId: string;
}

const StoreManagementProductLeftPanel: React.FC<Props> = ({ product, challengeId, storeItems, setFieldValue, formValues, formErrors }) => {
  const intl = useIntl();
  const history = useHistory();

  const [isLoadingDeleteProduct, deleteProductWithSnackbar] = useSnackbarPromiseHandler(
    api.storeManagement.delete,
    `server.success.${ServerSuccesses.DELETE_SUCCESS}`
  );

  const [isLoadingUpdateProduct, updateProductWithSnackbar] = useSnackbarPromiseHandler(
    api.storeManagement.update,
    `server.success.${ServerSuccesses.EDIT_SUCCESS}`
  );

  const updateProduct = async () => {
    const json = JSON.stringify({ ...formValues, shippingDate: moment(formValues.shippingDate).format("yyyy-MM-DD") });
    const blob = new Blob([json], {
      type: "application/json",
    });
    const data = new FormData();
    data.append("item", blob);
    data.append("photo", formValues?.file);

    await updateProductWithSnackbar(challengeId, product.id, data);
  };

  const deleteProduct = async () => {
    await deleteProductWithSnackbar(challengeId, product.id);

    history.push(`${intl.formatMessage({ id: "routes.store.management" })}`);
  };

  const anyError = Object.keys(formErrors).length > 0;

  return (
    <PaperWide>
      <Grid container spacing={4}>
        <Grid item container spacing={4}>
          <GridWideChild item xs={6}>
            <Field
              id="name"
              name="name"
              component={FMUITextField}
              label={intl.formatMessage({ id: "storeManagement.product.form.field.title" })}
            />
          </GridWideChild>
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <BoxChildWidePadded>
              <Field
                id="brand"
                name="brand"
                component={FMUITextField}
                label={intl.formatMessage({ id: "storeManagement.product.form.field.brand" })}
              />
            </BoxChildWidePadded>
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePickerWide
              disableToolbar
              variant="inline"
              format="DD/MM/yyyy"
              margin="normal"
              id="shippingDate"
              name="shippingDate"
              invalidDateMessage={intl.formatMessage({ id: "storeManagement.product.form.field.datePublished.error.invalid" })}
              label={<FormattedMessage id="storeManagement.product.form.field.datePublished" />}
              value={formValues.shippingDate}
              onChange={(value: Moment) => {
                setFieldValue("shippingDate", value);
              }}
              helperText={formErrors?.startDate}
              error={!!formErrors?.startDate}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <FormControlWide variant="outlined">
              <InputLabel id="label-select-category">
                <FormattedMessage id="storeManagement.table.filter.category" />
              </InputLabel>
              <Select
                variant="outlined"
                label={<FormattedMessage id="storeManagement.table.filter.category" />}
                labelId="label-select-category"
                value={formValues.categoryId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue("categoryId", event.target.value)}
              >
                {_.sortBy(
                  _.uniqBy(storeItems, (item) => item.category.name),
                  (item) => item.category.name
                ).map((item) => (
                  <MenuItem value={item.category.id} key={item.category.id}>
                    {item.category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControlWide>
          </Grid>
          <Grid container alignItems="flex-end" item xs={6}>
            <Field
              component={FMUITextField}
              id="price"
              name="price"
              inputProps={{ step: 250, min: 0 }}
              type="number"
              label={<FormattedMessage id="storeManagement.product.form.field.price" />}
              error={!!formErrors?.price}
            />
            <Rcoin size={24} />
          </Grid>
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <Field
              component={FMUITextField}
              id="reference"
              name="reference"
              label={<FormattedMessage id="storeManagement.product.form.field.reference" />}
              multiline
            />
          </Grid>
          <Grid item xs={6}>
            <FieldAddTextToList name="choices" label={<FormattedMessage id="storeManagement.product.form.field.userChoices" />} />
          </Grid>
        </Grid>
        <Grid item container spacing={4}>
          <GridWideChild item xs={12}>
            <Field
              component={FMUITextField}
              id="description"
              name="description"
              label={<FormattedMessage id="storeManagement.product.form.field.description" />}
              multiline
            />
          </GridWideChild>
        </Grid>
        <Grid item container spacing={4} justify="center">
          <Grid item xs={4}>
            <ButtonLoadingWide
              variant="contained"
              color="primary"
              startIcon={<Save />}
              isLoading={isLoadingUpdateProduct}
              onClick={updateProduct}
              disabled={anyError}
            >
              <FormattedMessage id="storeManagement.product.form.button.save" />
            </ButtonLoadingWide>
          </Grid>
          <Grid item xs={4}>
            <ButtonLoadingDelete
              isLoading={isLoadingDeleteProduct}
              variant="contained"
              color="secondary"
              startIcon={<Trash2 />}
              onClick={deleteProduct}
            >
              <FormattedMessage id="storeManagement.product.form.button.delete" />
            </ButtonLoadingDelete>
          </Grid>
        </Grid>
      </Grid>
    </PaperWide>
  );
};

export default StoreManagementProductLeftPanel;
