import React, { useEffect, useState } from "react";
import { Container, Grid, Paper, Typography } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import { FormattedMessage, useIntl } from "react-intl";
import { object, ref, string } from "yup";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { spacing } from "assets/styles/spacing";
import { colors } from "assets/styles/colors";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import api from "api";
import { useQuery } from "hooks/router";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import { ServerSuccesses } from "enums/server.enums";
import ButtonLoading from "components/buttons/ButtonLoading";

const ContainerWide = muiStyled(Container)({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const PaperSized = muiStyled(Paper)({
  minWidth: 600,
  padding: spacing(10),
});

const FormBreathing = muiStyled(Form)({
  "& > .MuiTextField-root, & > .MuiGrid-root": {
    marginTop: spacing(8),
  },
});

const ErrorContainer = styled.div`
  padding-bottom: 6px;
  margin: 0;
`;

const ErrorText = styled.div`
  font-size: 14px;
  color: ${colors.red};
`;

const validationSchema = object().shape({
  password: string()
    .required("form.error.field.mandatory")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[€@$!%*?&])[A-Za-z\d€@$!%*?&]{8,}$/, "form.error.password.regex"),
  repeatPassword: string().oneOf([ref("password"), null], "form.error.field.mustMatch"),
});

type FormValuesType = {
  password: string;
  repeatPassword: string;
};

const Activation: React.FC = () => {
  const intl = useIntl();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const history = useHistory();
  const query = useQuery();
  const [resetKey, setResetKey] = useState<string>();
  const [isLoading, resetPasswordWithSnackbar] = useSnackbarPromiseHandler(async (formValues: FormValuesType) => {
    const token = await executeRecaptcha("RESET_PASSWORD");

    localStorage.removeItem(LOCAL_STORAGE.REDIRECT_URL);
    await api.auth.resetPassword({ ...formValues, key: resetKey, token });
    history.push("/");
  }, `server.success.${ServerSuccesses.DEFAULT_SUCCESS}`);

  useEffect(() => {
    const key = query.get("key");

    if (key) {
      setResetKey(key);
    } else {
      // invalid navigation protocol
      history.push("/");
    }
  }, [query]);

  return (
    <ContainerWide>
      <PaperSized>
        <Formik
          initialValues={{
            password: "",
            repeatPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={resetPasswordWithSnackbar}
        >
          {({ values, handleChange, handleBlur, errors, touched }: any) => (
            <FormBreathing>
              <Typography variant="h4">
                <FormattedMessage id="screen.activation.form.title" />
              </Typography>
              <TextField
                label={intl.formatMessage({
                  id: "screen.activation.form.field.password.label",
                })}
                margin="dense"
                fullWidth
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ placeholder: "********" }}
                name="password"
                error={errors.password && touched.password}
                value={values.password}
                variant="outlined"
              />
              {errors.password && touched.password && (
                <ErrorContainer>
                  <ErrorText>
                    <FormattedMessage id={errors.password} />
                  </ErrorText>
                </ErrorContainer>
              )}
              <TextField
                label={intl.formatMessage({
                  id: "screen.activation.form.field.repeatPassword.label",
                })}
                margin="dense"
                fullWidth
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ placeholder: "********" }}
                name="repeatPassword"
                error={errors.repeatPassword && touched.repeatPassword}
                value={values.repeatPassword}
                variant="outlined"
              />
              {errors.repeatPassword && touched.repeatPassword && (
                <ErrorContainer>
                  <ErrorText>
                    <FormattedMessage id={errors.repeatPassword} />
                  </ErrorText>
                </ErrorContainer>
              )}
              <Grid xs={12} item container justify="center">
                <ButtonLoading
                  isLoading={isLoading}
                  disabled={errors.repeatPassword || errors.password}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  <FormattedMessage id="form.submit" />
                </ButtonLoading>
              </Grid>
            </FormBreathing>
          )}
        </Formik>
      </PaperSized>
    </ContainerWide>
  );
};

export default Activation;
