import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Radio, TableContainer, Typography } from "@material-ui/core";
import { X } from "react-feather";
import theme from "theme";
import { styled as muiStyled } from "@material-ui/core/styles";
import {
  AccountsFilterFormType,
  ResultsAccounts,
  ResultsFilterFormType,
  SearchAccountResponse,
  SearchContactResponse,
} from "types/form.types";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
import AbsoluteLoader from "components/loader/AbsoluteLoader";
import { RootState } from "store/types";
import styled from "styled-components";
import SearchBar from "components/header/SearchBar";
import { spacing } from "assets/styles/spacing";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import { ServerSuccesses } from "enums/server.enums";

const ModalTitle = muiStyled(Grid)({
  borderBottom: `solid 1px ${theme.palette.primary.main}`,
});

const ModalAction = muiStyled(Grid)({
  display: "flex",
  justifyContent: "flex-end",
  paddingLeft: 25,
  paddingRight: 25,
  paddingTop: 10,
  paddingBottom: 20,
});

const SearchContainer = muiStyled(Grid)({
  display: "flex",
  margin: spacing(4),
});

const SearchSection = muiStyled(Grid)({
  margin: spacing(4),
});

const NoResultTable = muiStyled(Grid)({
  marginTop: spacing(4),
  display: "flex",
});

const SearchSectionAction = muiStyled(Grid)({
  display: "flex",
  justifyContent: "flex-end",
  paddingBottom: spacing(4),
});

const BodyContainer = styled.div`
  overflow: auto;
  flex: 1;
`;

const SearchBarContainer = styled.div`
  flex: 1;
  margin-bottom: ${spacing(4)}px;
  margin-top: ${spacing(3)}px;
`;

interface Column {
  id: "name" | "ipn" | "accountName" | "rootAccountName" | string;
  label: string;
  minWidth?: number;
  align?: "right";
}

interface AddContactListType {
  contactId: string;
  accountId: string;
  name: string;
  ipn: string;
  accountName: string;
  rootAccountName: string;
}

const TableButton = muiStyled(Box)({
  display: "flex",
  margin: "auto",

  "&:hover > *": {
    cursor: "pointer",
  },
});

interface Props {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  challengeId: string;
  account?: SearchAccountResponse;
  contact?: SearchContactResponse;
  setContactAccounts?: React.Dispatch<React.SetStateAction<ResultsAccounts[]>>;
  contactAccounts?: ResultsAccounts[];
}

const AddContactModal: React.FC<Props> = ({ open, onClose, challengeId, account, contact, setContactAccounts, contactAccounts }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const filteredAccounts = useSelector((state: RootState) => state.accounts.filteredAccounts);
  const filteredContacts = useSelector((state: RootState) => state.accounts.filteredContacts);

  const [accountSearchValue, setAccountSearch] = useState<string>();
  const [contactSearchValue, setContactSearch] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAccountsResults = (form: AccountsFilterFormType | {}) => dispatch(actions.accounts.getAccountsResults(challengeId, form));

  const getResults = (form: ResultsFilterFormType | {}) => dispatch(actions.results.getChallengeResults(challengeId, form));

  const [selectedAccount, setSelectedAccount] = useState<SearchAccountResponse>();
  const [selectedContact, setSelectedContact] = useState<SearchContactResponse>();

  const [addContactList, setAddContactList] = useState<AddContactListType[]>([]);

  const [isLoadingSave, save] = useSnackbarPromiseHandler(async () => {
    dispatch(
      actions.challenge.addContact(
        challengeId,
        addContactList?.map((item) => ({
          accountId: item.accountId,
          contactId: item.contactId,
        }))
      )
    );
  }, `server.success.${ServerSuccesses.ADD_CONTACT}`);

  const contactsList: Column[] = [
    {
      id: "name",
      label: intl.formatMessage({ id: "accounts.details.contacts.name" }),
    },
    {
      id: "ipn",
      label: intl.formatMessage({ id: "accounts.details.contacts.ipn" }),
    },
  ];

  const accountsList: Column[] = [
    {
      id: "name",
      label: intl.formatMessage({ id: "accounts.details.contacts.accountName" }),
    },
    {
      id: "rootAccountName",
      label: intl.formatMessage({ id: "accounts.details.contacts.rootAccountName" }),
    },
  ];

  const addContactListColumn: Column[] = [
    {
      id: "name",
      label: intl.formatMessage({ id: "accounts.details.contacts.name" }),
    },
    {
      id: "ipn",
      label: intl.formatMessage({ id: "accounts.details.contacts.ipn" }),
    },
    {
      id: "accountName",
      label: intl.formatMessage({ id: "accounts.details.contacts.accountName" }),
    },
    {
      id: "rootAccountName",
      label: intl.formatMessage({ id: "accounts.details.contacts.rootAccountName" }),
    },
  ];

  const searchFilteredAccounts = async () => {
    dispatch(actions.accounts.getFilteredAccounts({ page: 0, pageSize: 12, text: accountSearchValue }));
  };

  const searchFilteredContacts = async () => {
    dispatch(actions.accounts.getFilteredContacts({ page: 0, pageSize: 12, text: contactSearchValue }));
  };

  const handleSave = () => {
    try {
      save();
    } finally {
      if (setContactAccounts && contactAccounts) {
        const newAccounts: ResultsAccounts[] = addContactList
          .filter((item) => item.contactId === contact.id)
          .map((item) => ({ id: item.accountId, name: item.accountName, group: item.rootAccountName }));
        setContactAccounts([...contactAccounts, ...newAccounts]);
      }
      setAddContactList([]);
    }
  };

  const handleCreate = async () => {
    if (selectedAccount && selectedAccount) {
      setAddContactList([
        ...addContactList,
        {
          name: selectedContact.name,
          ipn: selectedContact.ipn,
          accountName: selectedAccount.name,
          rootAccountName: selectedAccount.rootAccountName,
          contactId: selectedContact.id,
          accountId: selectedAccount.id,
        },
      ]);
    }
    await getResults({});
    await getAccountsResults({});
    setSelectedContact(undefined);
    setSelectedAccount(undefined);
  };

  useEffect(() => {
    if (open) {
      (async function getSearchEffect() {
        try {
          setIsLoading(true);
          await searchFilteredAccounts();
          await searchFilteredContacts();
        } finally {
          setIsLoading(false);
          if (account) {
            setAccountSearch(account.name);
            setSelectedAccount(account);
          } else {
            setAccountSearch(undefined);
          }
          if (contact) {
            setContactSearch(contact.name);
            setSelectedContact(contact);
          } else {
            setContactSearch(undefined);
          }
        }
      })();
    }
  }, [open]);

  useEffect(() => {
    (async function getSearchEffect() {
      try {
        setIsLoading(true);
        await searchFilteredAccounts();
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accountSearchValue]);

  useEffect(() => {
    (async function getSearchEffect() {
      try {
        setIsLoading(true);
        await searchFilteredContacts();
      } finally {
        setIsLoading(false);
      }
    })();
  }, [contactSearchValue]);

  const loading = isLoading || isLoadingSave;

  return (
    <>
      {loading && <AbsoluteLoader />}
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <ModalTitle xs={12} display="flex">
            <Box>
              <Typography fontWeight={500} />
              <FormattedMessage id="accounts.addContact.title" />
            </Box>
            <Box position="absolute" top={0} right={0} p={1} pr={2}>
              <IconButton aria-label="close" onClick={onClose} size="small">
                <X />
              </IconButton>
            </Box>
          </ModalTitle>
        </DialogTitle>
        <DialogContent>
          <BodyContainer>
            <SearchContainer>
              <SearchSection xs={6}>
                <Typography>
                  <FormattedMessage id="accounts.addContact.contacts" />
                </Typography>
                <SearchBarContainer>
                  <SearchBar
                    value={contactSearchValue}
                    onChange={(e) => setContactSearch(e.target.value)}
                    placeholderIntlId="form.results.search.placeholder"
                  />
                </SearchBarContainer>
                <TableContainer style={{ maxHeight: "440px" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell key="radio" align="right" style={{ width: 30 }} />
                        {contactsList.map((column) => (
                          <TableCell key={column.id} align="left" style={{ maxWidth: 200 }}>
                            <Box display="flex" flexDirection="row" alignItems="center">
                              {column.label}
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredContacts.map((item) => (
                        <TableRow>
                          <TableCell key="radio" align="left">
                            <Radio
                              value={item.id}
                              color="primary"
                              checked={selectedContact?.id === item.id}
                              onChange={() => setSelectedContact(item)}
                            />
                          </TableCell>
                          <TableCell key="name" align="left">
                            {item.name}
                          </TableCell>
                          <TableCell key="ipn" align="left">
                            {item.ipn}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {filteredContacts?.length === 0 && (
                  <NoResultTable xs={12}>
                    <Box margin="auto">
                      <FormattedMessage id="accounts.details.noAccount" />
                    </Box>
                  </NoResultTable>
                )}
              </SearchSection>
              <SearchSection xs={6}>
                <Typography>
                  <FormattedMessage id="accounts.addContact.accounts" />
                </Typography>
                <SearchBarContainer>
                  <SearchBar
                    value={accountSearchValue}
                    onChange={(e) => setAccountSearch(e.target.value)}
                    placeholderIntlId="form.accounts.search.placeholder"
                  />
                </SearchBarContainer>
                <TableContainer style={{ maxHeight: "440px" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell key="info" align="right" style={{ width: 30 }} />
                        {accountsList.map((column) => (
                          <TableCell key={column.id} align="left" style={{ maxWidth: 200 }}>
                            <Box display="flex" flexDirection="row" alignItems="center">
                              {column.label}
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredAccounts.map((item) => (
                        <TableRow>
                          <TableCell key="radio" align="left">
                            <Radio
                              value={item.id}
                              color="primary"
                              checked={selectedAccount?.id === item.id}
                              onChange={() => setSelectedAccount(item)}
                            />
                          </TableCell>
                          <TableCell key="name" align="left">
                            {item.name}
                          </TableCell>
                          <TableCell key="rootAccountName" align="left">
                            {item.rootAccountName}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {filteredAccounts?.length === 0 && (
                  <NoResultTable xs={12}>
                    <Box margin="auto">
                      <FormattedMessage id="accounts.details.noAccount" />
                    </Box>
                  </NoResultTable>
                )}
              </SearchSection>
            </SearchContainer>
            <SearchSectionAction>
              <Button variant="contained" color="primary" pr={2} onClick={handleCreate} disabled={!selectedContact || !selectedAccount}>
                <FormattedMessage id="button.accounts.details.addContact.create" />
              </Button>
            </SearchSectionAction>

            <TableContainer style={{ maxHeight: "440px" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {addContactListColumn.map((column) => (
                      <TableCell key={column.id} align="left" style={{ maxWidth: 200 }}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          {column.label}
                        </Box>
                      </TableCell>
                    ))}
                    <TableCell key="delete" align="right" style={{ width: 30 }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addContactList.map((item) => (
                    <TableRow>
                      <TableCell key="name" align="left">
                        {item.name}
                      </TableCell>
                      <TableCell key="ipn" align="left">
                        {item.ipn}
                      </TableCell>
                      <TableCell key="accountName" align="left">
                        {item.accountName}
                      </TableCell>
                      <TableCell key="rootAccountName" align="left">
                        {item.rootAccountName}
                      </TableCell>
                      <TableCell key="delete" align="right" style={{ width: 30 }}>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                          <TableButton
                            onClick={() =>
                              setAddContactList(
                                addContactList?.filter((c) => c.accountId !== item.accountId && c.contactId !== item.contactId)
                              )
                            }
                          >
                            <X style={{ stroke: theme.palette.error.main }} />
                          </TableButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {addContactList?.length === 0 && (
              <NoResultTable xs={12}>
                <Box margin="auto">
                  <FormattedMessage id="accounts.details.noAccount" />
                </Box>
              </NoResultTable>
            )}
          </BodyContainer>
        </DialogContent>
        <ModalAction>
          <Button variant="contained" color="primary" pr={2} onClick={handleSave} disabled={addContactList.length === 0}>
            <FormattedMessage id="button.accounts.details.addContact.save" />
          </Button>
        </ModalAction>
      </Dialog>
    </>
  );
};

export default AddContactModal;
