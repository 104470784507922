import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Trash2, CheckCircle, X, Upload } from "react-feather";
import { Grid, Typography } from "@material-ui/core";

import { File } from "types/api.types";
import { colors } from "assets/styles/colors";
import { ICON_SIZE } from "assets/styles/constants";

const getColor = (props: any) => {
  if (props.error) {
    return colors.red;
  }
  if (props.isDragAccept || props.isDragReject || props.isDragActive) {
    return colors.grey;
  }
  if (props.anyFile) {
    return colors.yellowRenault;
  }
  return colors.darkgrey;
};

const Container = styled.div<ContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props: any) => getColor(props)};
  border-style: dashed;
  outline: none;
  cursor: pointer;
  transition: border 0.24s ease-in-out;
  min-height: ${(props: any) => props.minHeight}px;
`;

const DropzoneContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const TrashIcon = styled(Trash2)`
  cursor: pointer;
`;

const FileContainer = styled.div`
  margin: 6px 0;
`;

type Props = {
  name: string;
  onChange: (acceptedFiles: Array<any>) => any;
  error?: boolean;
  handleSubmit?: () => any;
  submitOnchange?: boolean;
  minHeight: number;
  resetForm?: () => any;
  accept?: string;
};

type ContainerProps = {
  error?: boolean;
  minHeight: number;
  anyFile?: boolean;
};

const Dropzone: React.FC<Props> = ({
  onChange,
  name,
  error = false,
  handleSubmit = undefined,
  submitOnchange = undefined,
  minHeight,
  resetForm = undefined,
  accept = "",
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState<Array<File>>([]);

  const deleteFromAcceptedFile = (path?: string) => {
    setAcceptedFiles(acceptedFiles.filter((file) => file.path !== path));
    if (resetForm) {
      resetForm();
    }
  };

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      <Grid container spacing={1} alignItems="center">
        <Grid item> {file.path}</Grid>
        <Grid item>
          <TrashIcon onClick={() => deleteFromAcceptedFile(file.path)} />
        </Grid>
      </Grid>
    </li>
  ));

  const onDrop = useCallback(
    (currentFiles) => {
      setAcceptedFiles(currentFiles);
      onChange(currentFiles);
      currentFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onload = () => {
          // Do whatever you want with the file contents
          // const binaryStr = reader.result;
          // console.log(binaryStr);
        };
        reader.readAsArrayBuffer(file);
      });
      if (submitOnchange && handleSubmit) {
        handleSubmit();
      }
    },
    [onChange, handleSubmit, submitOnchange]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const anyFile = acceptedFiles.length > 0;
  const icon = () => {
    if (anyFile) {
      if (error) {
        return <X size={ICON_SIZE.BIG} color={colors.yellowRenault} />;
      }
      return <CheckCircle size={ICON_SIZE.BIG} color={colors.yellowRenault} />;
    }
    return <Upload color={colors.yellowRenault} />;
  };

  const dropzoneMessage = () => {
    if (files?.length > 0) {
      if (error) {
        return "importation.upload.dropzone.file.invalid";
      }
      return "importation.upload.dropzone.file.accepted";
    }
    return "importation.upload.dropzone.empty";
  };

  return (
    <DropzoneContainer>
      <Container {...getRootProps()} error={error} minHeight={minHeight} anyFile={anyFile}>
        <input {...getInputProps()} name={name} accept={accept} />
        <Grid container direction="column" alignItems="center" justify="center" spacing={2}>
          <Grid item>{icon()}</Grid>
          <Grid item>
            <Typography>
              <FormattedMessage id={dropzoneMessage()} />
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <FileContainer>
        <ul>{files}</ul>
      </FileContainer>
    </DropzoneContainer>
  );
};

export default Dropzone;
