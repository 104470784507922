import React from "react";
import styled from "styled-components";
import { styled as muiStyled } from "@material-ui/core/styles";
import { Grid, Link, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import actions from "store/actions";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import { spacing } from "assets/styles/spacing";
import AbsoluteLoader from "components/loader/AbsoluteLoader";
import { ServerSuccesses } from "enums/server.enums";
import Dropzone from "components/dropzone/Dropzone";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import { AuthMode } from "enums/api.enums";

const LastRulesContainer = styled.div`
  align-self: flex-end;
`;

const LastRulesLink = muiStyled(Link)({
  marginTop: spacing(2),
  cursor: "pointer",
});

interface IProps {
  challengeId: string;
  rulesFilename: string;
  rulesLink?: string;
}

const RulesBlock: React.FC<IProps> = ({ challengeId, rulesFilename, rulesLink }) => {
  const dispatch = useDispatch();
  const uploadNewRules = (formData: FormData) => dispatch(actions.challenge.uploadNewRules(challengeId, formData));
  const [isLoadingUploadNewRules, uploadNewRulesWithSnackbar] = useSnackbarPromiseHandler(
    uploadNewRules,
    `server.success.${ServerSuccesses.UPLOAD_FILE_SUCCESS}`
  );
  const isAuthModeIDP = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE) === AuthMode.IDP;

  const onChangeRulesFile = async (files: any[]) => {
    const file = files?.[0];

    if (file) {
      const formData = new FormData();

      formData.append("file", file);
      uploadNewRulesWithSnackbar(formData);
    }
  };

  return (
    <>
      {isLoadingUploadNewRules && <AbsoluteLoader />}
      <Grid item container xs={12} justify="center" direction="column">
        <Typography variant="subtitle1" color="textSecondary">
          <FormattedMessage id="screen.challenge.rules.title" />
        </Typography>
        {isAuthModeIDP && <Dropzone name="fileInput" onChange={onChangeRulesFile} minHeight={200} accept=".pdf" />}
        <LastRulesContainer>
          {rulesFilename ? (
            <LastRulesLink href={rulesLink} target="_blank">
              <FormattedMessage id="screen.challenge.rules.link.download" />
            </LastRulesLink>
          ) : (
            <FormattedMessage id="screen.challenge.rules.link.none" />
          )}
        </LastRulesContainer>
      </Grid>
    </>
  );
};

export default RulesBlock;
