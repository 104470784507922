import React, { useEffect, useState } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Button, Divider, Grid, MenuItem, Select, TableContainer, Typography } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Eye, MapPin as LocationIcon, X } from "react-feather";
import styled from "styled-components";
import { styled as muiStyled } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { ChallengeTab, ContactType, QuestGoalType } from "enums/type.enums";
import { ResultsAccounts, ResultsFilterRowResponseType } from "types/form.types";
import { OrderInfoType, QuestType } from "types/common.types";
import api from "api";
import { GETOrderByContact } from "types/api.types";
import AbsoluteLoader from "components/loader/AbsoluteLoader";
import { RootState } from "store/types";
import { colors } from "assets/styles/colors";
import GamutChip from "components/chips/GamutChip";
import theme from "theme";
import actions from "store/actions";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import { ServerSuccesses } from "enums/server.enums";
import { spacing } from "assets/styles/spacing";
import AddContactModal from "screens/results/modal/AddContact.modal";
import ResultsPanelQuestItem from "./ResultsPanelQuestItem";

const White = styled.span`
  color: white;
`;

const BodyContainer = styled.div`
  overflow: auto;
  flex: 1;
`;

const TypographyOrderSubfield = muiStyled(Typography)({
  color: colors.grey,
});

const ListContainer = muiStyled(List)({
  width: "100%",
});

const TableButton = muiStyled(Box)({
  "&:hover > *": {
    cursor: "pointer",
  },
});

const NoResultTable = muiStyled(Grid)({
  marginTop: spacing(4),
  display: "flex",
});

const DeleteContactContainer = muiStyled(Grid)({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: spacing(4),
});

interface Column {
  id: "name" | "gamut" | "choice";
  label: string;
  minWidth?: number;
  align?: "right";
}

interface ContactColumn {
  id: "name" | "group";
  label: string;
  minWidth?: number;
  align?: "right";
}

interface Props {
  row: ResultsFilterRowResponseType;
  onClose: () => void;
  submitForm: () => void;
}

const ResultsPanelBody: React.FC<Props> = ({ row, onClose, submitForm }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoadingOrder, setIsLoadingOrder] = useState<boolean>();
  const [progressiveQuests, setProgressiveQuests] = useState<QuestType[]>([]);
  const [unitaryQuests, setUnitaryQuests] = useState<QuestType[]>([]);
  const [order, setOrder] = useState<OrderInfoType>();
  const [contactAccounts, setContactAccounts] = useState<ResultsAccounts[]>(row?.accounts ?? []);
  const { challenge } = useSelector((state: RootState) => state.challenge);
  const accountsList = useSelector((state: RootState) => state.accounts.accounts);
  const [contactType, setContactType] = useState<string>();
  const [openAddContactModal, setOpenAddContactModal] = useState<boolean>(false);

  const [isLoadingDelete, deleteAccountContact] = useSnackbarPromiseHandler(async (accountId: string) => {
    dispatch(actions.results.deleteResultsAccount(challenge?.id, accountId, row.id));
    dispatch(actions.results.deleteContactAccount(row.id, accountId));
    setContactAccounts(contactAccounts?.filter((c) => c.id !== accountId) ?? []);
  }, `server.success.${ServerSuccesses.DELETE_CONTACT}`);

  const [isLoadingDeleteContact, deleteContact] = useSnackbarPromiseHandler(async () => {
    onClose();
    dispatch(actions.results.deleteContact(row.id));
    dispatch(actions.accounts.deleteContact(challenge?.id, row.id));
  }, `server.success.${ServerSuccesses.DELETE_CONTACT}`);

  useEffect(() => {
    (async function fetchOrderByContact() {
      setIsLoadingOrder(true);
      setOrder(undefined);

      try {
        const { data }: GETOrderByContact = await api.storeManagement.orderByContact(challenge.id, row.id);
        setOrder(data);
      } catch (e) {
        // cant get order, just do not show block order
      } finally {
        setIsLoadingOrder(false);
      }
    })();
  }, [challenge, row]);

  useEffect(() => {
    setProgressiveQuests(row?.quests?.filter((quest: QuestType) => quest.type === QuestGoalType.PROGRESSIVE) ?? []);
    setUnitaryQuests(row?.quests?.filter((quest: QuestType) => quest.type === QuestGoalType.UNITARY) ?? []);
    setContactType(row?.contactType);
    setContactAccounts(row?.accounts);
  }, [row]);

  const getAccountDetails = async (name: string) => {
    const selectedRow = accountsList.filter((a) => a.name === name);
    dispatch(actions.accounts.setAccountDetails(selectedRow[0] ?? undefined));
  };

  const updateType = async (type: string) => {
    setContactType(type);
    dispatch(actions.results.updateContactType(row.id, challenge.id, type));
  };

  const columns: Column[] = [
    {
      id: "name",
      label: intl.formatMessage({ id: "results.panel.orders.name" }),
    },
    {
      id: "gamut",
      label: intl.formatMessage({ id: "results.panel.orders.gamut" }),
    },
    {
      id: "choice",
      label: intl.formatMessage({ id: "results.panel.orders.choice" }),
    },
  ];

  const accountColumns: ContactColumn[] = [
    {
      id: "name",
      label: intl.formatMessage({ id: "accounts.details.contacts.accountName" }),
    },
    {
      id: "group",
      label: intl.formatMessage({ id: "accounts.details.contacts.rootAccountName" }),
    },
  ];

  const loading = isLoadingDeleteContact || isLoadingDelete;

  return (
    <>
      {loading && <AbsoluteLoader />}
      <BodyContainer>
        <ListContainer>
          <ListSubheader disableSticky>
            <FormattedMessage id={`quest.type.plural.${QuestGoalType.PROGRESSIVE}`} values={{ count: progressiveQuests.length }} />
          </ListSubheader>
          {progressiveQuests.map((quest: QuestType) => (
            <ResultsPanelQuestItem
              quest={quest}
              key={quest.id}
              type={quest.type}
              challengeTab={ChallengeTab.CONTACTS}
              resultId={row?.id}
              submitForm={submitForm}
            />
          ))}
          <ListSubheader disableSticky>
            <FormattedMessage id={`quest.type.plural.${QuestGoalType.UNITARY}`} values={{ count: unitaryQuests.length }} />
          </ListSubheader>
          {unitaryQuests.map((quest: QuestType) => (
            <ResultsPanelQuestItem
              quest={quest}
              key={quest.id}
              type={quest.type}
              challengeTab={ChallengeTab.CONTACTS}
              resultId={row?.id}
              submitForm={submitForm}
            />
          ))}
        </ListContainer>
        <Box mt={4} mb={4}>
          <Divider variant="middle" />
        </Box>
        <ListContainer>
          <ListSubheader disableSticky>
            <FormattedMessage id="results.panel.accounts.phone" />
            <White>{row?.phone ? row.phone : "-"}</White>
          </ListSubheader>
          <ListSubheader disableSticky>
            <FormattedMessage id="results.panel.accounts.type" />
            <Select value={contactType ?? row?.contactType} onChange={(e: any) => updateType(e.target.value)}>
              {Object.keys(ContactType).map((key) => (
                <MenuItem value={key}>
                  <FormattedMessage id={`results.panel.accounts.type.value.${key}`} />
                </MenuItem>
              ))}
            </Select>
          </ListSubheader>
          <ListSubheader disableSticky>
            <FormattedMessage id="results.panel.accounts.title.plural" values={{ count: contactAccounts?.length }} />
          </ListSubheader>
          {contactAccounts?.map((account: ResultsAccounts) => (
            <ListItem key={account?.id}>
              <ListItemIcon>
                <LocationIcon />
              </ListItemIcon>
              <Typography variant="body2">{account?.name}</Typography>
            </ListItem>
          ))}
          <Box ml={2} mt={1}>
            <Button variant="contained" onClick={() => setOpenAddContactModal(true)}>
              <FormattedMessage id="results.panel.accounts.add" />
            </Button>
          </Box>
        </ListContainer>
        <Box mt={4} mb={4}>
          <Divider variant="middle" />
        </Box>
        {isLoadingOrder ? (
          <Box width="100%" position="relative">
            <AbsoluteLoader />
          </Box>
        ) : (
          <ListContainer>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {accountColumns.map((column) => (
                      <TableCell key={column.id} align="left" style={{ maxWidth: 200 }}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          {column.label}
                        </Box>
                      </TableCell>
                    ))}
                    <TableCell key="info" align="right" style={{ width: 30 }} />
                    <TableCell key="delete" align="right" style={{ width: 30 }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contactAccounts?.map((item) => (
                    <TableRow>
                      <TableCell key="name" align="left">
                        {item.name}
                      </TableCell>
                      <TableCell key="group" align="left">
                        {item.group}
                      </TableCell>
                      <TableCell key="info" align="right" style={{ width: 30 }}>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                          <TableButton onClick={() => getAccountDetails(item.name)}>
                            <Eye />
                          </TableButton>
                        </Box>
                      </TableCell>
                      <TableCell key="delete" align="right" style={{ width: 30 }}>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                          <TableButton onClick={() => deleteAccountContact(item.id)}>
                            <X style={{ stroke: theme.palette.error.main }} />
                          </TableButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {contactAccounts?.length === 0 && (
              <NoResultTable xs={12}>
                <Box margin="auto">
                  <FormattedMessage id="accounts.details.noAccount" />
                </Box>
              </NoResultTable>
            )}
            <ListSubheader disableSticky>
              <FormattedMessage id="results.panel.orders.title" />
            </ListSubheader>
            <ListItem>
              {order ? (
                <Box flexDirection="column">
                  <Box mb={2}>
                    <TypographyOrderSubfield variant="body2">
                      <FormattedMessage id="results.panel.orders.orderDate" />
                    </TypographyOrderSubfield>
                    <Typography gutterBottom>{moment(order.creationDate).format("DD/MM/yyyy HH:mm")}</Typography>
                    <TypographyOrderSubfield variant="body2">
                      <FormattedMessage id="results.panel.orders.address" />
                    </TypographyOrderSubfield>
                    <Typography gutterBottom>{order.address}</Typography>
                    <TypographyOrderSubfield variant="body2">
                      <FormattedMessage id="results.panel.orders.contactForDelivery" />
                    </TypographyOrderSubfield>
                    <Typography gutterBottom>{order.phone}</Typography>
                    <TypographyOrderSubfield variant="body2">
                      <FormattedMessage id="results.panel.orders.comment" />
                    </TypographyOrderSubfield>
                    <Typography gutterBottom>{order.comment}</Typography>
                  </Box>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell key={column.id} align="left" style={{ maxWidth: 200 }}>
                              <Box display="flex" flexDirection="row" alignItems="center">
                                {column.label}
                              </Box>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {order.items.map((item) => (
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell>{column.id === "gamut" ? <GamutChip gamut={item.gamut} /> : item[column.id]}</TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : (
                <Typography variant="caption">
                  <FormattedMessage id="results.panel.orders.none" />
                </Typography>
              )}
            </ListItem>
          </ListContainer>
        )}
        <DeleteContactContainer>
          <Button
            variant="contained"
            pr={2}
            onClick={() => deleteContact()}
            style={{
              backgroundColor: theme.palette.error.main,
              color: "white",
            }}
          >
            <FormattedMessage id="button.accounts.details.deleteContact" />
          </Button>
        </DeleteContactContainer>
      </BodyContainer>

      {row?.name && (
        <AddContactModal
          open={openAddContactModal}
          onClose={() => setOpenAddContactModal(false)}
          challengeId={challenge.id}
          contact={{ name: row.name, ipn: row.ipn, id: row.id }}
          setContactAccounts={setContactAccounts}
          contactAccounts={contactAccounts}
        />
      )}
    </>
  );
};

export default ResultsPanelBody;
