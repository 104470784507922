import React, { useEffect, useState } from "react";
import { styled as muiStyled } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { colors } from "assets/styles/colors";
import { QuestType } from "types/common.types";
import { QuestGoalType } from "enums/type.enums";
import ResultsFormFiltersRegisteredItem from "screens/results/form/ResultsFormFiltersRegisteredItem";

import ResultsFormFiltersQuestItem from "./ResultsFormFiltersQuestItem";
import ResultsFormFiltersResultItem from "./ResultsFormFiltersResultItem";
import ResultsFormFiltersAdjudicationItem from "./ResultsFormFiltersAdjudicationItem";

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${colors.yellowRenault};
`;

const ListContainer = muiStyled(List)({
  maxWidth: 350,
});

interface Props {
  quests: QuestType[];
  debouncedSubmitForm: () => void;
}

const ResultsFormFilters: React.FC<Props> = ({ quests, debouncedSubmitForm }) => {
  const [progressiveQuests, setProgressiveQuests] = useState<QuestType[]>([]);
  const [unitaryQuests, setUnitaryQuests] = useState<QuestType[]>([]);

  useEffect(() => {
    setProgressiveQuests(quests.filter((quest: QuestType) => quest.type === QuestGoalType.PROGRESSIVE));
    setUnitaryQuests(quests.filter((quest: QuestType) => quest.type === QuestGoalType.UNITARY));
  }, [quests]);

  return (
    <>
      <TitleContainer>
        <Typography color="primary" variant="h6">
          <FormattedMessage id="form.results.filter.title" />
        </Typography>
      </TitleContainer>
      <ListContainer>
        <ListSubheader disableSticky>
          <FormattedMessage id={`quest.type.plural.${QuestGoalType.PROGRESSIVE}`} values={{ count: progressiveQuests.length }} />
        </ListSubheader>
        {progressiveQuests.map((quest) => (
          <ResultsFormFiltersQuestItem quest={quest} key={quest.id} debouncedSubmitForm={debouncedSubmitForm} />
        ))}
        <ListSubheader disableSticky>
          <FormattedMessage id={`quest.type.plural.${QuestGoalType.UNITARY}`} values={{ count: unitaryQuests.length }} />
        </ListSubheader>
        {unitaryQuests.map((quest) => (
          <ResultsFormFiltersQuestItem quest={quest} key={quest.id} debouncedSubmitForm={debouncedSubmitForm} />
        ))}
        <ListSubheader disableSticky>
          <FormattedMessage id="results.table.filter.title.result" />
        </ListSubheader>
        <ResultsFormFiltersResultItem />
        <ListSubheader disableSticky>
          <FormattedMessage id="results.table.filter.title.adjudication" />
        </ListSubheader>
        <ResultsFormFiltersAdjudicationItem />
        <ListSubheader disableSticky>
          <FormattedMessage id="results.table.filter.title.registered" />
        </ListSubheader>
        <ResultsFormFiltersRegisteredItem />
      </ListContainer>
    </>
  );
};

export default ResultsFormFilters;
