import React from "react";
import styled from "styled-components";
import Loader from "./index";

const Absolute = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  left: 0;
  top: 0;
`;

const AbsoluteLoader = () => (
  <Absolute>
    <Loader type="ThreeDots" />
  </Absolute>
);

export default AbsoluteLoader;
