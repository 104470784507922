import { ResultsFilterResponseType } from "types/form.types";

type ActionTypes = {
  GET_RESULTS: "GET_RESULTS";
  UPDATE_REGISTRATION: "UPDATE_REGISTRATION";
  UPDATE_ADJUDICATION: "UPDATE_ADJUDICATION";
  UPDATE_TYPE: "UPDATE_TYPE";
  DELETE_RESULT_ACCOUNT: "DELETE_RESULT_ACCOUNT";
  UPDATE_COMMENT: "UPDATE_COMMENT";
  DELETE_CONTACT_ACCOUNT: "DELETE_CONTACT_ACCOUNT";
  DELETE_CONTACT: "DELETE_CONTACT";
};

export const ACTION_TYPES: ActionTypes = {
  GET_RESULTS: "GET_RESULTS",
  UPDATE_REGISTRATION: "UPDATE_REGISTRATION",
  UPDATE_ADJUDICATION: "UPDATE_ADJUDICATION",
  UPDATE_COMMENT: "UPDATE_COMMENT",
  UPDATE_TYPE: "UPDATE_TYPE",
  DELETE_RESULT_ACCOUNT: "DELETE_RESULT_ACCOUNT",
  DELETE_CONTACT_ACCOUNT: "DELETE_CONTACT_ACCOUNT",
  DELETE_CONTACT: "DELETE_CONTACT",
};

export type ResultsActionTypes =
  | {
      type: typeof ACTION_TYPES.GET_RESULTS;
      payload: ResultsFilterResponseType;
    }
  | {
      type: typeof ACTION_TYPES.UPDATE_ADJUDICATION;
      payload: {
        id: string;
        adjudication: boolean;
      };
    }
  | {
      type: typeof ACTION_TYPES.UPDATE_TYPE;
      payload: {
        id: string;
        type: string;
      };
    }
  | {
      type: typeof ACTION_TYPES.DELETE_RESULT_ACCOUNT;
      payload: {
        accountId: string;
        contactId: string;
      };
    }
  | {
      type: typeof ACTION_TYPES.UPDATE_REGISTRATION;
      payload: {
        id: string;
        registered: boolean;
      };
    }
  | {
      type: typeof ACTION_TYPES.UPDATE_COMMENT;
      payload: {
        id: string;
        comment: string;
      };
    }
  | {
      type: typeof ACTION_TYPES.UPDATE_REGISTRATION;
      payload: {
        id: string;
        registered: boolean;
      };
    }
  | {
      type: typeof ACTION_TYPES.DELETE_CONTACT_ACCOUNT;
      payload: {
        id: string;
        account: string;
      };
    }
  | {
      type: typeof ACTION_TYPES.DELETE_CONTACT;
      payload: {
        id: string;
      };
    };
