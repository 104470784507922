import React, { useEffect, useState } from "react";
import { styled as muiStyled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import MuiTableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

import actions from "store/actions";
import { RootState } from "store/types";
import Loader from "components/loader";
import { spacing } from "assets/styles/spacing";
import SearchBar from "components/header/SearchBar";

interface Column {
  id: "name" | "email" | "accounts" | "done";
  label: string;
  minWidth?: number;
  align?: "right";
  type?: string;
}

const COLUMN_TYPE = {
  GOAL: "GOAL",
  ACCOUNTS: "ACCOUNTS",
};

const TableContainer = muiStyled(MuiTableContainer)({
  maxHeight: 400,
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchBarContainer = styled.div`
  max-width: 350px;
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${spacing(2)}px;
`;

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("");
  const { challenge, contacts = [] } = useSelector((state: RootState) => state.challenge);

  const searchLowerCase = search.toLowerCase().trim();
  const filteredContacts = search
    ? contacts.filter((contact) => `${contact.name}${contact.email}${contact.accounts.join("")}`.toLowerCase().includes(searchLowerCase))
    : contacts;

  const intl = useIntl();
  const { id } = challenge || {};

  const columns: Column[] = [
    { id: "name", label: intl.formatMessage({ id: "contact.name" }), minWidth: 120 },
    { id: "email", label: intl.formatMessage({ id: "contact.email" }), minWidth: 100 },
    {
      id: "accounts",
      label: intl.formatMessage({ id: "contact.accounts" }),
      minWidth: 100,
      type: COLUMN_TYPE.ACCOUNTS,
    },
    { id: "done", label: "Avancée", type: COLUMN_TYPE.GOAL },
  ];

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const getContacts = (challengeId: string) => dispatch(actions.challenge.getChallengeContacts(challengeId));

  useEffect(() => {
    (async function auth() {
      setLoading(true);
      try {
        if (id) {
          await getContacts(id);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  };

  if (loading) {
    return <Loader width={80} height={350} type="ThreeDots" />;
  }

  return (
    <Container>
      <Title>
        <Typography variant="h4">
          <FormattedMessage id="contact.total" values={{ count: contacts ? contacts.length : 0 }} />
        </Typography>
        <SearchBarContainer>
          <SearchBar value={search} onChange={(e) => setSearch(e.target.value)} />
        </SearchBarContainer>
      </Title>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredContacts &&
              filteredContacts.length > 0 &&
              filteredContacts.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.type === COLUMN_TYPE.GOAL && `${row.done} / ${row.goal}`}
                      {column.type === COLUMN_TYPE.ACCOUNTS && Array.isArray(row.accounts) && row.accounts.join(", ")}
                      {!column.type && row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={filteredContacts.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={intl.formatMessage({ id: "common.table.rowsPerPage" })}
        labelDisplayedRows={({ from, to, count }) =>
          intl.formatMessage(
            { id: "common.table.DisplayedRows" },
            {
              from,
              to,
              count,
            }
          )
        }
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
}
