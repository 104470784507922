import React from "react";
import styled from "styled-components";
import { Box, Tooltip } from "@material-ui/core";

const Circle = styled(Box)`
  width: ${(props) => props.$radius}px;
  height: ${(props) => props.$radius}px;
  background-color: ${(props) => props.$color};
  border-radius: 50%;
`;

interface Props {
  color: string;
  tooltipText: string;
  radius?: number;
}

const ColorChip: React.FC<Props> = ({ color, tooltipText, radius = 30 }) => (
  <Tooltip title={tooltipText} arrow>
    <Circle $color={color} $radius={radius} boxShadow={2} />
  </Tooltip>
);

export default ColorChip;
