import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import * as log from "loglevel";

// import { getTokenSilently } from "utils/authentication.utils";
import * as ENV from "config/env";
import { LOCAL_STORAGE } from "utils/authentication-constants.utils";
import { getTokenSilently } from "utils/authentication.utils";

const MAX_RETRY = 2;

/* Class used to make a websocket connection */
/* Used to bid on auctions */
class Websocket {
  /* Websocket Stomp client */
  client: any = null;

  /* Current opened listeners, {[id]: {count: int, listener: Array<{id: string, unsubscribe: function}>}} */
  listeners: any = {};

  /* total of retry connect */
  retry = 0;

  /**
   * connect the websocket to the API
   * @param token, Authorization token
   * @returns {Promise<unknown>}, resolved when connected
   */
  connect = (token: string): any =>
    new Promise((resolve, reject) => {
      const client = new Client({
        connectHeaders: {
          Authorization: token,
          [LOCAL_STORAGE.AUTH_MODE]: localStorage.getItem(LOCAL_STORAGE.AUTH_MODE),
        },
        debug(str: any) {
          log.debug(str);
        },
        reconnectDelay: 5000,
        webSocketFactory() {
          /* use SockJS instead of pure webSockets */
          return new SockJS(ENV.WEBSOCKET_ENDPOINT, null, {
            transports: ["websocket"],
          });
        },
        onConnect: () => {
          log.debug("WEBSOCKET_CONNECTED");
          resolve(client);
        },
        onWebSocketClose: () => {
          log.debug(" WEBSOCKET_CLOSED");
          this.retry += 1;
          if (this.retry >= MAX_RETRY) {
            client.deactivate();
            this.client = null;
          }
          log.debug("WEBSOCKET_CLOSED", this.retry);

          reject();
        },
      });
      client.activate();
    });

  /* Disconnect websocket */
  disconnect = (client: any) => {
    if (client !== null) {
      client.disconnect();
    }
  };

  /* If not connected, create a new webSocket connexion, else return current client */
  getWebsocket = () =>
    getTokenSilently().then((token) => {
      this.retry = 0;
      if (!this.client) {
        this.client = this.connect(token);
      }
      return this.client;
    });

  /* Subscribe to a channel */
  subscribe = async (url: string, callback: any) => {
    const client = await this.getWebsocket();

    if (!this.listeners[url]) {
      this.listeners[url] = {
        count: 1,
        listener: client.subscribe(url, callback),
      };
    } else if (this.listeners[url]) {
      this.listeners[url] = {
        ...this.listeners[url],
        count: this.listeners[url].count + 1,
      };
    }
  };

  /* Remove a subscription from the list */
  unsubscribe = async (url: string) => {
    await this.getWebsocket();

    if (this.listeners[url] && this.listeners[url].count > 1) {
      this.listeners[url] = {
        ...this.listeners[url],
        count: this.listeners[url].count - 1,
      };
    } else if (this.listeners[url] && this.listeners[url].count === 1) {
      this.listeners[url].listener.unsubscribe();
      this.listeners[url] = undefined;
    }
  };
}

/* Singleton */
const singletonInstance = new Websocket();

export default singletonInstance;
