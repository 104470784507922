import React from "react";
import styled from "styled-components";
import { Icon as IIcon } from "react-feather";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { spacing } from "assets/styles/spacing";

const Container = styled(Link)`
  display: flex;
  align-items: center;
  & > * {
    padding-left: ${spacing(2)}px;
  }

  &:hover > * {
    font-weight: bold;
    stroke-width: 3px;
    cursor: pointer;
  }
`;

const Text = styled.span`
  font-size: 18px;
  color: white;
`;

interface Props {
  translationKey: string;
  url: string;
  Icon: IIcon;
}

const MenuItem: React.FunctionComponent<Props> = ({ Icon, translationKey, url }) => (
  <Container to={url}>
    <Icon color="white" />
    <Text>
      <FormattedMessage id={translationKey} />
    </Text>
  </Container>
);

export default MenuItem;
