import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import actions from "store/actions";
import { RootState } from "store/types";
import AbsoluteLoader from "components/loader/AbsoluteLoader";
import api from "api";
import { GETResultsSummary } from "types/api.types";
import { useSnackbarPromiseHandler } from "hooks/snackbar.hooks";
import { ContactsResultSummaryType, QuestContactsResultSummaryType } from "types/form.types";
import SelectYear from "components/selects/SelectYear";
import { selectYear } from "store/challenge/challenge.actions";
import DashboardContactsSummary from "screens/dashboard/DashboardContactsSummary";
import { DashboardValues } from "enums/select.enums";

import { colors } from "assets/styles/colors";
import moment, { Moment } from "moment";

import DashboardQuestList from "./DashboardQuestList";

const ContainerPage = muiStyled(Container)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingBottom: 32, // to correct grid negative margin c.f. mui-doc limitations
});

const ContainerNoChallenge = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.2rem;
`;

const DateOverline = muiStyled(Typography)({
  color: colors.lightGrey,
});

const summaryTypes: DashboardValues[] = [DashboardValues.WINNERS, DashboardValues.REGISTERED];

const Dashboard: React.FC = () => {
  const [summaryTypeIndex, setSummaryTypeIndex] = useState<number>(0);
  const [selectedSummary, setSelectedSummary] = useState<DashboardValues>(summaryTypes[summaryTypeIndex]);
  const [isLoadingChallenge, setIsLoadingChallenge] = useState<boolean>();
  const [resultsSummary, setResultsSummary] = useState<ContactsResultSummaryType>();
  const [registeredSummary, setRegisteredSummary] = useState<ContactsResultSummaryType>();
  const [questsResultsSummary, setQuestsResultsSummary] = useState<QuestContactsResultSummaryType[]>();
  const [lastUpdateDate, setLastUpdateDate] = useState<Moment>();
  const year = useSelector((state: RootState) => state.challenge.selectedYear);
  const dispatch = useDispatch();

  const handleChangeIndex = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setSummaryTypeIndex(newIndex);
  };

  const getChallenge = (y: number) => dispatch(actions.challenge.getChallenge(y));
  const [isLoadingSummary, getResultsSummaryWithSnackbar] = useSnackbarPromiseHandler(api.results.summary);

  const { challenge, years } = useSelector((state: RootState) => state.challenge);

  useEffect(() => {
    setSelectedSummary(summaryTypes[summaryTypeIndex]);
  }, [summaryTypeIndex]);

  useEffect(() => {
    (async function auth() {
      try {
        setIsLoadingChallenge(true);
        await getChallenge(year);
      } finally {
        setIsLoadingChallenge(false);
      }
    })();
  }, [year]);

  useEffect(() => {
    if (challenge?.id !== undefined) {
      (async function getResultsSummary() {
        const {
          data: { summary, quests, summaryRegistered, lastModificationDate },
        }: GETResultsSummary = await getResultsSummaryWithSnackbar(challenge.id);

        setRegisteredSummary(summaryRegistered);
        setResultsSummary(summary);
        setQuestsResultsSummary(quests);
        setLastUpdateDate(moment(lastModificationDate));
      })();
    }
  }, [challenge]);

  return isLoadingChallenge || isLoadingSummary ? (
    <AbsoluteLoader />
  ) : (
    <ContainerPage>
      <Box width="100%" display="flex" alignItems="flex-end">
        <Box flex={1}>
          <Tabs
            value={summaryTypeIndex}
            onChange={handleChangeIndex}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {summaryTypes.map((summaryType) => (
              <Tab label={<FormattedMessage id={`dashboard.select.value.${summaryType}`} />} key={summaryType} />
            ))}
          </Tabs>
        </Box>
        <Box display="flex" flexDirection="column">
          <SelectYear years={years} year={year} setYear={(y) => dispatch(selectYear(y))} />
          {lastUpdateDate && (
            <DateOverline variant="body2" gutterBottom>
              <FormattedMessage id="common.date.lastUpdate" values={{ date: lastUpdateDate?.format("DD/MM/yyyy") }} />
            </DateOverline>
          )}
        </Box>
      </Box>
      {challenge?.id && resultsSummary && questsResultsSummary ? (
        <Grid container spacing={4} direction="column" alignItems="center">
          <DashboardContactsSummary
            summary={selectedSummary === DashboardValues.WINNERS ? resultsSummary : registeredSummary}
            title={<FormattedMessage id={`dashboard.${selectedSummary}.title`} values={{ challengeName: challenge.name }} />}
            caption={<FormattedMessage id={`dashboard.${selectedSummary}.total.pieChart.caption`} />}
          />
          <DashboardQuestList challenge={challenge} quests={questsResultsSummary} />
        </Grid>
      ) : (
        <ContainerNoChallenge>
          <FormattedMessage id="screen.challenge.no.challenge" values={{ year }} />
        </ContainerNoChallenge>
      )}
    </ContainerPage>
  );
};

export default Dashboard;
