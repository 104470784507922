import { AddResultsParamsType } from "types/api.types";

export default {
  auth: {
    login: {
      credentials: "/authentication/login",
      usbToken: "???", // TODO:,
    },
    isAuthenticated: "/login/isAuthenticated", // TODO: check if exists in backend
    askResetPassword: "/authentication/ask-reset-password",
    resetPassword: `/authentication/reset-password`,
    renew: `/authentication/renew`,
  },
  challenge: {
    create: "/challenge",
    update: (challengeId: string) => `/challenge/${challengeId}`,
    byYear: "/challenge",
    contacts: (id: string) => `/challenge/${id}/contact`,
    rulesLink: (rulesFilename: string) => `/document/${rulesFilename}`,
    accounts: (id: string) => `challenge/${id}/accounts`,
    account: (id: string, challengeId: string) => `challenge/${challengeId}/account/${id}`,
    deleteAccountContact: (challengeId: string, accountId: string, contactId: string) =>
      `challenge/${challengeId}/contact/${contactId}/account/${accountId}`,
    deleteContact: (challengeId: string, contactId: string) => `challenge/${challengeId}/contact/${contactId}`,
    addContact: (challengeId: string) => `challenge/${challengeId}/contact/add`,
    uploadNewRules: (challengeId: string) => `/challenge/${challengeId}/rules`,
  },
  quest: {
    create: (id: string) => `/challenge/${id}/quest`,
    edit: (challengeId: string, questId: string) => `/challenge/${challengeId}/quest/${questId}`,
    delete: (challengeId: string, questId: string) => `/challenge/${challengeId}/quest/${questId}`,
  },
  results: {
    filter: (challengeId: string) => `/challenge/${challengeId}/result`,
    summary: (challengeId: string) => `/challenge/${challengeId}/summary`,
    adjudicate: (challengeId: string, contactId: string) => `/challenge/${challengeId}/contact/${contactId}/adjudication`,
    registration: (challengeId: string, contactId: string) => `/challenge/${challengeId}/contact/${contactId}/registration`,
    updateType: (challengeId: string, contactId: string) => `/challenge/${challengeId}/contact/${contactId}`,
    addResultsAccountChallenge: ({ challengeId, questId, accountId }: AddResultsParamsType) =>
      `challenge/${challengeId}/quest/${questId}/account/${accountId}`,
    addResultsContactChallenge: ({ challengeId, questId, contactId }: AddResultsParamsType) =>
      `challenge/${challengeId}/quest/${questId}/contact/${contactId}`,
  },
  storeManagement: {
    getItems: (challengeId: string) => `/challenge/${challengeId}/store`,
    open: (challengeId: string) => `/challenge/${challengeId}/store`,
    publishResults: (challengeId: string) => `/challenge/${challengeId}/result`,
    notifyOpen: (challengeId: string) => `/challenge/${challengeId}/store/notification`,
    update: (challengeId: string, productId: string) => `challenge/${challengeId}/store/${productId}`,
    delete: (challengeId: string, productId: string) => `challenge/${challengeId}/store/${productId}`,
    orderByContact: (challengeId: string, contactId: string) => `challenge/${challengeId}/contact/${contactId}/order`,
  },
  imports: {
    catalog: (challengeId: string) => `/import/challenge/${challengeId}/store`,
    media: (challengeId: string) => `/import/challenge/${challengeId}/photos`,
    results: (challengeId: string) => `/import/challenge/${challengeId}/result`,
    users: (challengeId: string) => `/import/challenge/${challengeId}/users`,
    analyzeCatalog: (challengeId: string) => `/import/challenge/${challengeId}/store/check`,
    analyzeMedia: (challengeId: string) => `/import/challenge/${challengeId}/photos/check`,
    analyzeResults: (challengeId: string) => `/import/challenge/${challengeId}/result/check`,
    analyzeUsers: (challengeId: string) => `import/challenge/${challengeId}/users/check`,
  },
  exports: {
    results: (challengeId: string) => `/export/challenge/${challengeId}/result`,
    orders: (challengeId: string) => `/export/challenge/${challengeId}/orders`,
    sampleCatalog: (challengeId: string) => `/export/challenge/${challengeId}/store/sample`,
    sampleResults: (challengeId: string) => `/export/challenge/${challengeId}/result/sample`,
    sampleUsers: (challengeId: string) => `/export/challenge/${challengeId}/contact/sample`,
  },
  history: {
    imports: (challengeId: number) => `/import/challenge/${challengeId}/history`,
  },
  contact: {
    filter: "/contact",
  },
  account: {
    filter: "/account",
  },
};
