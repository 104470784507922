import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import { styled as muiStyled } from "@material-ui/core/styles";
import { ButtonProps } from "@material-ui/core/Button";

const ButtonContainer = styled.div`
  position: relative;
`;

const CircularProgressAbsolute = muiStyled(CircularProgress)({
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -12,
  marginLeft: -12,
});

interface Props extends ButtonProps {
  isLoading: boolean;
}

const ButtonLoading: React.FC<Props> = ({ isLoading, ...restProps }) => (
  <ButtonContainer>
    <Button {...restProps} disabled={isLoading || restProps.disabled} />
    {isLoading && <CircularProgressAbsolute size={24} />}
  </ButtonContainer>
);

export default ButtonLoading;
