import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import Modal from "components/modal/Modal";
import { FormattedMessage } from "react-intl";
import { spacing } from "assets/styles/spacing";
import { styled as muiStyled } from "@material-ui/styles";
import AbsoluteLoader from "components/loader/AbsoluteLoader";

const TypographyWithMargin = muiStyled(Typography)({
  marginTop: spacing(4),
});

const ButtonWithMargin = muiStyled(Button)({
  marginLeft: spacing(4),
});

interface Props {
  isOpen: boolean;
  onConfirm: () => any;
  onCancel: () => any;
  isLoading?: boolean;
}

const DialogNotifyStoreOpen: React.FC<Props> = ({ isOpen, onConfirm, onCancel, isLoading }) => (
  <Modal open={isOpen} onClose={onCancel} title="storeManagement.open.dialog.notify.title">
    <TypographyWithMargin>
      <FormattedMessage id="storeManagement.open.dialog.notify.body" />
    </TypographyWithMargin>
    <Box mt={2} width="100%" display="flex" justifyContent="flex-end">
      <ButtonWithMargin color="primary" variant="outlined" onClick={onCancel}>
        Non
      </ButtonWithMargin>
      <ButtonWithMargin color="primary" variant="contained" onClick={onConfirm}>
        Oui
      </ButtonWithMargin>
    </Box>
    {isLoading && <AbsoluteLoader />}
  </Modal>
);

export default DialogNotifyStoreOpen;
