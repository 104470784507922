import React from "react";
import { styled as muiStyled } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { BarChart2, CheckCircle } from "react-feather";
import { Box, Card, CardContent, Grid, Grow, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import { ContactType, QuestGoalType } from "enums/type.enums";
import CircularProgressWithLabel from "components/progression/CircularProgressWithLabel";
import { spacing } from "assets/styles/spacing";
import { QuestContactsResultSummaryType } from "types/form.types";

const CardWide = muiStyled(Card)({
  width: "100%",
});

const TypographyQuestName = muiStyled(Typography)({
  marginLeft: spacing(3),
});

interface Props {
  questContactsResultSummaryType: QuestContactsResultSummaryType;
  type: QuestGoalType;
}

const CardQuestProgressions: React.FC<Props> = ({ questContactsResultSummaryType, type }) => {
  const isProgressive = type === QuestGoalType.PROGRESSIVE;
  const isUnitary = type === QuestGoalType.UNITARY;

  return (
    <Grow in>
      <CardWide variant="outlined">
        <CardContent>
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Box mb={1} display="flex" alignItems="center">
              {isProgressive && <BarChart2 />}
              {isUnitary && <CheckCircle />}
              <TypographyQuestName>{questContactsResultSummaryType.name}</TypographyQuestName>
            </Box>
            <Grid container spacing={2} justify="space-evenly">
              <Grid item xs={3}>
                <CircularProgressWithLabel value={questContactsResultSummaryType.percentage} thickness={9} />
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" flexDirection="column" height="100%" justifyContent="space-evenly">
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Typography>
                        <FormattedMessage id={`contact.${ContactType.CVVO}`} />
                      </Typography>
                      <Typography>
                        {questContactsResultSummaryType.contactsType.CVVO?.total
                          ? `${questContactsResultSummaryType.contactsType.CVVO?.done} / ${questContactsResultSummaryType.contactsType.CVVO?.total}`
                          : "-"}
                      </Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={Math.min(
                        (questContactsResultSummaryType.contactsType.CVVO?.done / questContactsResultSummaryType.contactsType.CVVO?.total) *
                          100,
                        100
                      )}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Typography>
                        <FormattedMessage id={`contact.${ContactType.CVVOMULTI}`} />
                      </Typography>
                      <Typography>
                        {questContactsResultSummaryType.contactsType.CVVOMULTI?.total
                          ? `${questContactsResultSummaryType.contactsType.CVVOMULTI?.done} / ${questContactsResultSummaryType.contactsType.CVVOMULTI?.total}`
                          : "-"}
                      </Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={Math.min(
                        (questContactsResultSummaryType.contactsType.CVVOMULTI?.done /
                          questContactsResultSummaryType.contactsType.CVVOMULTI?.total) *
                          100,
                        100
                      )}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Typography>
                        <FormattedMessage id={`contact.${ContactType.CG}`} />
                      </Typography>
                      <Typography>
                        {questContactsResultSummaryType.contactsType.CG?.total
                          ? `${questContactsResultSummaryType.contactsType.CG?.done} / ${questContactsResultSummaryType.contactsType.CG?.total}`
                          : "-"}
                      </Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={Math.min(
                        (questContactsResultSummaryType.contactsType.CG?.done / questContactsResultSummaryType.contactsType.CG?.total) *
                          100,
                        100
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </CardWide>
    </Grow>
  );
};

export default CardQuestProgressions;
