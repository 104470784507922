import { ChallengeTab } from "enums/type.enums";
import { ACTION_TYPES, type ChallengeState, type ChallengeActionTypes } from "store/challenge/challenge.types";

const initialState: ChallengeState = {
  years: [],
  selectedYear: new Date().getFullYear(),
  challenge: undefined,
  selectedTab: ChallengeTab.CONTACTS,
  selectedModalTab: undefined,
  contacts: [],
  rulesLink: undefined,
  resultDetails: undefined,
};

const homeReducer = (state: ChallengeState = initialState, action: ChallengeActionTypes): ChallengeState => {
  switch (action.type) {
    case ACTION_TYPES.GET_CHALLENGE: {
      const { years, startDate, endDate, ...challenge } = action.payload;
      return {
        ...state,
        challenge: { ...challenge, startDate: new Date(startDate), endDate: new Date(endDate) },
        years,
      };
    }
    case ACTION_TYPES.OPEN_STORE: {
      return {
        ...state,
        challenge: { ...state.challenge, storeOpen: action.payload },
      };
    }
    case ACTION_TYPES.PUBLISH_RESULTS: {
      return {
        ...state,
        challenge: { ...state.challenge, resultsPublished: action.payload },
      };
    }
    case ACTION_TYPES.SET_CHALLENGE_RULES_URL: {
      const link = action.payload;
      return {
        ...state,
        rulesLink: link,
      };
    }
    case ACTION_TYPES.GET_CHALLENGE_CONTACTS: {
      return {
        ...state,
        contacts: action.payload,
      };
    }
    case ACTION_TYPES.CREATE_CHALLENGE: {
      const { startDate, endDate, ...challenge } = action.payload;
      return {
        ...state,
        challenge: { ...challenge, startDate: new Date(startDate), endDate: new Date(endDate) },
      };
    }
    case ACTION_TYPES.UPDATE_CHALLENGE: {
      const { startDate, endDate, ...challenge } = action.payload;
      return {
        ...state,
        challenge: { ...challenge, startDate: new Date(startDate), endDate: new Date(endDate) },
      };
    }
    case ACTION_TYPES.CREATE_QUEST: {
      const { startDate, endDate, ...challenge } = action.payload;
      return {
        ...state,
        challenge: { ...challenge, startDate: new Date(startDate), endDate: new Date(endDate) },
      };
    }
    case ACTION_TYPES.EDIT_QUEST: {
      return {
        ...state,
        challenge: { ...action.payload },
      };
    }
    case ACTION_TYPES.DELETE_QUEST: {
      return {
        ...state,
        challenge: { ...state.challenge, quests: state.challenge.quests.filter((q) => q.id !== action.payload) },
      };
    }
    case ACTION_TYPES.SELECT_YEAR: {
      return {
        ...state,
        selectedYear: action.payload,
      };
    }
    case ACTION_TYPES.UPDATE_CHALLENGE_TAB: {
      return {
        ...state,
        selectedTab: action.payload,
      };
    }
    case ACTION_TYPES.UPDATE_CHALLENGE_MODAL_TAB: {
      return {
        ...state,
        selectedModalTab: action.payload,
      };
    }
    case ACTION_TYPES.RESULT_DETAILS: {
      return {
        ...state,
        resultDetails: action.payload,
      };
    }
    default:
      return state;
  }
};

export default homeReducer;
