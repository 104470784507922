import React from "react";
import { Field, Formik } from "formik";
import styled from "styled-components";
import { Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, Select as MuiSelect } from "@material-ui/core";
import { CheckboxWithLabel, RadioGroup, TextField } from "formik-material-ui";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";

import { spacing } from "assets/styles/spacing";
import type { QuestFormType } from "types/form.types";
import { QuestAggregationTypeProgressive, QuestAggregationTypeUnitary, QuestGoalType } from "enums/type.enums";

const Input = styled(Field)`
  flex: 1;
  margin: ${spacing(4)}px 0;
`;

const Select = styled(MuiSelect)`
  min-width: 210px;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 550px;
  width: 100%;
  & > div {
    margin: ${spacing(4)}px 0;
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  & > button {
    margin-left: ${spacing(2)}px;
  }
`;

const getValidationSchema = (intl: any) => {
  const required = intl.formatMessage({ id: "form.error.required" });

  return {
    name: Yup.string().required(required),
    account: Yup.boolean(),
    aggregation: Yup.string()
      .nullable()
      .when("account", {
        is: true,
        then: Yup.string().required(required),
      }),
  };
};

const defaultInitialValues: QuestFormType = {
  name: "",
  description: "",
  account: false,
  aggregation: null,
  reverse: false,
  type: QuestGoalType.PROGRESSIVE,
};

interface Props {
  onSubmit: (form: QuestFormType) => Promise<any>;
  onClose?: () => any;
  initialValues?: QuestFormType;
}

const QuestForm: React.FunctionComponent<Props> = ({ onSubmit, onClose, initialValues = defaultInitialValues }) => {
  const intl = useIntl();

  const validationSchema = getValidationSchema(intl);

  const submit = async (values: any) => {
    await onSubmit(values);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submit} validationSchema={Yup.object(validationSchema)}>
      {({ handleSubmit, values, setFieldValue, errors, touched }: any) => (
        <Form onSubmit={handleSubmit}>
          <Grid item container xs={12} direction="column">
            <Input
              type="text"
              id="name"
              name="name"
              component={TextField}
              variant="filled"
              label={intl.formatMessage({ id: "form.quest.name.label" })}
            />
          </Grid>
          <Grid item container xs={12}>
            <Input
              type="text"
              id="description"
              name="description"
              component={TextField}
              variant="filled"
              label={intl.formatMessage({ id: "form.quest.description.label" })}
            />
          </Grid>
          <Grid item container xs={12}>
            <Field
              name="type"
              component={RadioGroup}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue("type", event.target.value);
                setFieldValue("aggregation", null);
              }}
            >
              <FormControlLabel
                value={QuestGoalType.PROGRESSIVE}
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: `quest.type.${QuestGoalType.PROGRESSIVE}` })}
              />
              <FormControlLabel
                value={QuestGoalType.UNITARY}
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: `quest.type.${QuestGoalType.UNITARY}` })}
              />
            </Field>
          </Grid>
          <Grid item container xs={12} style={{ minHeight: 60 }} alignItems="flex-start">
            <Field
              type="checkbox"
              component={CheckboxWithLabel}
              name="reverse"
              color="primary"
              Label={{ label: intl.formatMessage({ id: "form.quest.reverse.checkbox" }) }}
            />
          </Grid>
          <Grid item container xs={6} style={{ minHeight: 60 }} alignItems="flex-start">
            <Field
              type="checkbox"
              component={CheckboxWithLabel}
              name="account"
              color="primary"
              Label={{ label: intl.formatMessage({ id: "form.quest.account.checkbox" }) }}
            />
          </Grid>
          {values.account && (
            <Grid item container xs={6} alignItems="flex-start">
              <FormControl variant="outlined" error={touched.aggregation && errors.aggregation}>
                <InputLabel id="label-select-aggregation">
                  <FormattedMessage id="form.quest.aggregation" />
                </InputLabel>
                <Select
                  variant="outlined"
                  label={<FormattedMessage id="form.quest.aggregation" />}
                  labelId="label-select-aggregation"
                  value={values.aggregation}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue("aggregation", event.target.value)}
                >
                  {Object.values(values.type === QuestGoalType.UNITARY ? QuestAggregationTypeUnitary : QuestAggregationTypeProgressive).map(
                    (val) => (
                      <MenuItem value={val} key={val}>
                        {intl.formatMessage({ id: `quest.aggregation.${val}` })}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Buttons>
            <Button variant="outlined" onClick={onClose}>
              <FormattedMessage id="form.cancel" />
            </Button>
            <Button variant="contained" color="primary" type="submit">
              <FormattedMessage id="form.submit" />
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  );
};

export default QuestForm;
